import React, { forwardRef } from 'react'
import { OutlinedInput, Theme } from '@mui/material'
import { styled } from '@mui/system'
import {
  themeColor,
  themeSpacing,
  themeTypography,
  themeBorderRadius,
} from '../../../helpers/themeHelpers'
import PasswordInput from '../PasswordInput'
import _ClearIcon from 'ez-styles/assets/icons/x/x-big.svg'
import { InputProps } from './types'

export const ClearIcon = styled(_ClearIcon)`
  flex-shrink: 0;
  width: ${themeSpacing(2)};
  height: ${themeSpacing(2.5)};
  padding: 5px 0;
  margin-right: ${themeSpacing(1)};
  cursor: pointer;
  fill: ${themeColor('secondary', 'light')};

  &:hover {
    fill: ${themeColor('secondary', 'main')};
  }
`

const InputComponent = forwardRef<HTMLElement, InputProps & { type: string }>(
  function InputComponent(props, ref) {
    const InputComponent =
      props.type === 'password' ? PasswordInput : OutlinedInput

    return <InputComponent ref={ref} {...props} />
  }
)

export const StyledInput = styled(InputComponent)<{
  theme: Theme
  $valid: boolean
}>`
  border-color: ${props =>
    props.$valid ? null : themeColor('error', 'main')} !important;

  &.Mui-disabled {
    color: ${themeColor('secondary', 'light')};
    background-color: ${themeColor('secondary', 'pale')};
  }
`

export const ErrorMessage = styled('div')`
  color: ${themeColor('error')};
  font-size: ${themeTypography('caption', 'fontSize')};
  height: ${themeSpacing(1)};
  margin-top: ${themeSpacing(0.25)};
`

export const FakeInput = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: ${themeTypography('body1', 'fontSize')};
  border: 1px solid ${themeColor('secondary', 'lighter')};
  border-radius: ${themeBorderRadius('small')};
  padding: ${themeSpacing(1.5)} ${themeSpacing(1.75)};
  color: ${themeColor('grey', '400')};
  background-color: ${themeColor('secondary', 'pale')};
  height: ${themeSpacing(5.525)};

  &:hover {
    cursor: not-allowed;
  }
`

export const CharacterCount = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: ${themeTypography('body1', 'fontSize')};
  color: ${themeColor('grey', '400')};
  margin-top: ${themeSpacing(0.5)};
`
