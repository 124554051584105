import {
  ProgramType,
  ResourceStatus,
  resourceStatusLabels,
  ReviewStatus,
} from 'constants/resources'
import { filenameFromUrl } from './files'
import { hasSpecialChars, SpecialCharsRegex, stripHtml } from './strings'

import { SelectOption } from 'eezy-components'
import {
  ExtendedResource,
  ResourceState,
  ReviewedResource,
  ReviewFilter,
} from 'types'
import {
  ProhibitedTerm,
  RejectionReason,
  Resource,
  SubmissionQuota,
} from 'types/api'

type License = 'Pro' | 'Free' | 'Editorial'

const words = (str: unknown): string[] => {
  if (typeof str !== 'string') {
    return []
  }

  return str.trim().split(' ')
}

export const removeProhibitedTermsFromTitle = (
  title: string,
  prohibitedTerms: ProhibitedTerm[]
) => {
  let cleanedTitle = title
  prohibitedTerms.forEach(term => {
    const regex = new RegExp(`(^|\\s)${term.term}(\\s|$)`, 'gi')

    cleanedTitle = cleanedTitle.replace(regex, (match, start, end) => {
      return start === ' ' && end === ' ' ? ' ' : ''
    })
  })
  return cleanedTitle
}

export const removeProhibitedKeywords = (
  keywords: string[],
  prohibitedTerms: ProhibitedTerm[] | null | undefined
) => {
  if (!prohibitedTerms) return keywords

  return keywords.filter(keyword => {
    return !prohibitedTerms.some(prohibitedTerm => {
      const regex = new RegExp(
        '\\b' + escapeRegExp(prohibitedTerm.term) + '\\b$',
        'i'
      )
      return regex.test(keyword)
    })
  })
}

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const doesResourceExceedQuota = (
  resource: Resource,
  selectedResourceIds: string[],
  allResources: Resource[],
  submissionQuotas: SubmissionQuota[] | null
): boolean => {
  if (!submissionQuotas || submissionQuotas.length === 0) {
    return false
  }

  const categoryId = Number(resource.category_id)

  if (!categoryId) return false

  const quota = submissionQuotas.find(
    quota => Number(quota.category_id) === categoryId
  )

  if (!quota) {
    return false
  }

  const currentCategoryCount = selectedResourceIds
    .map(id => allResources.find(res => res.id === id))
    .filter(res => res && Number(res.category_id) === categoryId).length

  const currentCount = quota.resource_count || 0

  return currentCount + currentCategoryCount + 1 > (quota.quota || 0)
}

const validateSubmissionQuota = (
  resource: Resource,
  submissionQuotas?: SubmissionQuota[] | null
) => {
  if (!submissionQuotas || submissionQuotas.length === 0) {
    return true
  }

  const resourceCategoryId = Number(resource.category_id)
  if (!resourceCategoryId) {
    return false
  }

  const quota = submissionQuotas.find(
    quota => Number(quota.category_id) === resourceCategoryId
  )

  if (!quota) {
    return true
  }

  if (!quota.resource_count || !quota.quota) {
    return true
  }

  return quota.resource_count < quota.quota
}

export const isSubmittable = (
  resource: ExtendedResource,
  prohibitedTerms?: ProhibitedTerm[] | null,
  submissionQuotas?: SubmissionQuota[] | null,
  titleIsUnique = true
): boolean => {
  const hasMagicMetadata = Boolean(resource.magic_metadata)
  let keywords = resource.keywords
  let title = resource.title

  if (
    prohibitedTerms &&
    prohibitedTerms.length &&
    title &&
    keywords &&
    keywords.length
  ) {
    keywords = removeProhibitedKeywords(keywords, prohibitedTerms)
    title = removeProhibitedTermsFromTitle(title, prohibitedTerms)
  }

  if (hasMagicMetadata) {
    return (
      (resource.program_type === ProgramType.WO ||
        Boolean(resource.license_set)) &&
      Boolean(resource.category) &&
      validateSubmissionQuota(resource, submissionQuotas)
    )
  }

  return (
    (resource.program_type === ProgramType.WO ||
      Boolean(resource.license_set)) &&
    Boolean(resource.category) &&
    keywords.length >= 5 &&
    keywords.length <= 50 &&
    typeof title === 'string' &&
    title.trim().split(' ').length >= 3 &&
    title.length <= 200 &&
    Boolean(resource?.preview_url) &&
    !resource?.preview_url?.includes('/images/not-available.png') &&
    !hasSpecialChars([title], SpecialCharsRegex.Loose) &&
    !hasSpecialChars(keywords) &&
    validateSubmissionQuota(resource, submissionQuotas) &&
    Boolean(titleIsUnique)
  )
}

export const isBundleSubmittable = (resource: Resource): boolean =>
  typeof resource.description === 'string' &&
  stripHtml(resource.description).trim().length >= 300 &&
  !hasSpecialChars(
    [stripHtml(resource.description)],
    SpecialCharsRegex.Loose
  ) &&
  resource.previews.length >= 3

export const isMotionGraphicSubmittable = (resource: Resource): boolean =>
  Boolean(
    resource &&
      resource.source_files &&
      resource?.source_files.length > 0 &&
      resource?.preview_video &&
      resource?.preview_video?.url &&
      getMatchingExtensionsFromSourceFiles(resource?.source_files).length > 0
  )

const isAcceptable = ({
  acceptable_as_free,
  acceptable_as_editorial,
}: Resource): boolean => acceptable_as_free || acceptable_as_editorial

const actionNeeded = (resource: Resource): boolean => {
  if (resource.state === 'needs_corrections') return true
  if (resource.state === 'started' && resource.comments_count > 0) return true

  return false
}

export const resourceTitle = (resource: Resource): string => {
  if (words(resource.title).length < 3) {
    return filenameFromUrl(resource.source_file?.url)
  }

  return resource.title || ''
}

export const resourceStatus = (
  resource: ExtendedResource,
  isTitleUnique = true
): ResourceStatus => {
  if (actionNeeded(resource))
    return isAcceptable(resource)
      ? ResourceStatus.AcceptChanges
      : ResourceStatus.ActionNeeded
  if (resource.state === 'rejected') return ResourceStatus.Rejected
  if (resource.state === 'inactive') return ResourceStatus.Inactive
  if (!isSubmittable(resource, undefined, undefined, isTitleUnique))
    return ResourceStatus.Processing
  if (resource.state === 'started') return ResourceStatus.Successful
  return ResourceStatus.Processing
}

export const isWO = (resource: Resource): boolean =>
  resource.program_type === ProgramType.WO

export const isEditorial = (resource: Resource): boolean =>
  resource.license === 'editorial'

export const isRejectAsEditorial = (resource?: ReviewedResource): boolean => {
  if (!resource) {
    return false
  }

  if (resource.status === ReviewStatus.Rejected) {
    return resource.rejectionReason.rejection_type === 'accept_as_editorial'
  }

  return false
}

export const getLicense = (resource: Resource): License => {
  if (resource.pro) {
    return 'Pro'
  }

  if (resource.license === 'editorial') {
    return 'Editorial'
  }

  return 'Free'
}

export const getStatusLabel = (
  state: ResourceState,
  reason?: string
): string =>
  state === 'active' && reason === 'publishing'
    ? resourceStatusLabels['exporting']
    : resourceStatusLabels[state]

export const isUploadable = (contentType: string): boolean => {
  const hiddenContentTypes = [
    'video_template',
    'brush',
    'background',
    'pattern',
    'texture',
    'icons',
  ]

  return !hiddenContentTypes.includes(contentType)
}

export const filterByRating =
  (filters: ReviewFilter[]) =>
  ({ status, rating }: ReviewedResource): boolean => {
    let result = false

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i]

      if (!result && typeof filter === 'number') {
        result = status === ReviewStatus.Approved && rating === filter
      }

      if (!result && filter === 'flagged') {
        result = status === ReviewStatus.Flagged
      }

      if (!result && filter === 'rejected') {
        result = status === ReviewStatus.Rejected
      }
    }

    return result
  }

export const filterRejectionReasonsFromCategory = (
  reasons: RejectionReason[],
  categoryIds: string[]
): { [key: string]: RejectionReason[] } => {
  const result: { [key: string]: RejectionReason[] } = {}

  if (reasons && categoryIds) {
    categoryIds.forEach(categoryId => {
      result[categoryId] = reasons.filter(({ categories }) =>
        Boolean(categories?.some(cat => cat.id === categoryId))
      )
    })
  }

  return result
}

export const filterRejectionReasonsFromResources = (
  reasons: RejectionReason[] | null,
  resources: Resource[]
): RejectionReason[] => {
  if (!reasons || !resources) {
    return []
  }

  const result: RejectionReason[] = resources.reduce((acc, resource) => {
    let filtered: RejectionReason[]

    if (resource.category?.id) {
      filtered = reasons.filter(({ categories }) =>
        Boolean(categories?.some(cat => cat.id === resource.category?.id))
      )
    } else {
      filtered = reasons.filter(
        ({ content_type }) => resource.content_type === content_type
      )
    }

    filtered.forEach(reason => {
      const idx = acc.findIndex(x => x.id === reason.id)

      if (idx === -1) {
        acc.push(reason)
      }
    })

    return acc
  }, [] as RejectionReason[])

  return result.sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0))
}

const FILE_PROGRAM_MAPPINGS = {
  '.aep': 'after_effects',
  '.aepx': 'after_effects',
  '.aet': 'after_effects',
  '.ffx': 'after_effects',
  '.prproj': 'premiere_pro',
  '.mogrt': 'premiere_pro',
  '.prfpset': 'premiere_pro',
  '.dra': 'davinci_resolve',
  '.settings': 'davinci_resolve',
  '.drfx': 'davinci_resolve',
  '.drp': 'davinci_resolve',
  '.motn': 'final_cut',
  '.moef': 'final_cut',
  '.moti': 'final_cut',
}

const PROGRAM_NICE_NAMES = {
  after_effects: 'After Effects',
  premiere_pro: 'Premiere Pro',
  davinci_resolve: 'DaVinci Resolve',
  final_cut: 'Final Cut',
}

export const getProgramNamesFromSourceFiles = (
  sourceFiles: Resource['source_files']
) => {
  const programsSet = new Set()

  if (sourceFiles) {
    sourceFiles.forEach(file => {
      const filename = filenameFromUrl(file.url)
      for (const extension in FILE_PROGRAM_MAPPINGS) {
        if (filename.includes(extension)) {
          const programKey =
            FILE_PROGRAM_MAPPINGS[
              extension as keyof typeof FILE_PROGRAM_MAPPINGS
            ]
          const programName =
            PROGRAM_NICE_NAMES[programKey as keyof typeof PROGRAM_NICE_NAMES] ||
            programKey
          programsSet.add(programName)
          break
        }
      }
    })
  }

  return Array.from(programsSet)
}

export const getMatchingExtensionsFromSourceFiles = (
  sourceFiles: Resource['source_files']
): string[] => {
  const matchingExtensions: string[] = []

  if (sourceFiles) {
    sourceFiles.forEach(file => {
      const filename = filenameFromUrl(file.url).toLowerCase()

      for (const extension in FILE_PROGRAM_MAPPINGS) {
        const extensionLower = extension.toLowerCase()
        if (filename.includes(extensionLower)) {
          matchingExtensions.push(extension)
          break
        }
      }
    })
  }

  return matchingExtensions
}

export const cleanZipFilename = (filename: string) => {
  if (filename.endsWith('.zip')) {
    const baseName = filename.substring(0, filename.indexOf('.')) + '.zip'
    return baseName
  }
  return filename
}

export const generatorOptions: SelectOption<string>[] = [
  { value: 'midjourney', label: 'Midjourney' },
  { value: 'stable_diffusion', label: 'Stable Diffusion' },
  { value: 'dall_e', label: 'DALL•E' },
]

export const getGeneratorValue = <T>(generator: T): T | undefined | 'other' => {
  if (!generator) {
    return undefined
  }

  if (generatorOptions.map(opt => opt.value).includes(generator as string)) {
    return generator
  }

  return 'other'
}

export const getOtherValue = <T>(generator: T): T | undefined => {
  if (generator === 'other') {
    return undefined
  }

  return generator
}
