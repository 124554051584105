/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableResourceState } from 'components/ResourceDetails/General/StatusEditModal'
import { captureError } from 'helpers/error'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import { FileUpload, ResourceLicense } from 'types'
import { Release, Resource, ResourceChangeRequest } from 'types/api'
import Api from 'util/api'
import { DIContainer, DIInstances } from 'util/di'

export default class RequestQueueStore {
  api: Api
  AuthStore: AuthStore

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
    this.AuthStore = container.find('AuthStore')
  }

  @observable isRequestChangesActive = false
  @observable currentRequestChange: ResourceChangeRequest | undefined =
    undefined
  @observable requestStatus: EditableResourceState | undefined = undefined
  @observable requestLicense: ResourceLicense | undefined = undefined
  @observable requestAIGenerated: string | undefined = undefined
  @observable requestAIGenerator: string | undefined = undefined
  @observable requestTitle: string | undefined = undefined
  @observable requestKeywords: string[] | undefined = undefined
  @observable requestPreview: number | undefined = undefined
  @observable requestReleases: Release[] | undefined = undefined
  @observable requestProofOfSale: FileUpload | undefined = undefined
  @observable requestExplanation = ''
  @observable creatingRequest = false
  @observable fetchingRequest = false
  @observable deletingRequest = false

  @computed get changeData(): object {
    const data: any = {}

    if (this.requestStatus !== undefined) data.status = this.requestStatus
    if (this.requestLicense !== undefined) data.license = this.requestLicense
    if (this.requestAIGenerated !== undefined)
      data.ai_generated = this.requestAIGenerated
    if (this.requestAIGenerator !== undefined)
      data.ai_generator = this.requestAIGenerator
    if (this.requestTitle !== undefined) data.title = this.requestTitle
    if (this.requestKeywords !== undefined) data.keywords = this.requestKeywords
    if (this.requestPreview !== undefined) data.preview = this.requestPreview
    if (this.requestReleases !== undefined) data.releases = this.requestReleases

    return data
  }

  @computed get requestTypes(): string[] {
    const activeTypes: string[] = []
    if (this.requestStatus !== undefined && this.requestStatus === 'inactive') {
      activeTypes.push('deactivation')
    }
    if (this.requestLicense !== undefined) {
      activeTypes.push('license')
    }
    if (
      this.requestAIGenerated !== undefined &&
      this.requestAIGenerator !== undefined
    ) {
      activeTypes.push('ai_generated')
    }
    if (this.requestTitle !== undefined) {
      activeTypes.push('title')
    }
    if (this.requestKeywords !== undefined) {
      activeTypes.push('keywords')
    }
    if (this.requestPreview !== undefined) {
      activeTypes.push('preview')
    }
    if (this.requestReleases !== undefined) {
      activeTypes.push('releases')
    }
    return activeTypes
  }
  @computed get hasRequestChanges(): boolean {
    return (
      this.requestStatus !== undefined ||
      this.requestLicense !== undefined ||
      this.requestAIGenerated !== undefined ||
      this.requestAIGenerator !== undefined ||
      this.requestTitle !== undefined ||
      this.requestKeywords !== undefined ||
      this.requestPreview !== undefined ||
      this.requestReleases !== undefined
    )
  }

  @action setIsRequestChangesActive = (state: boolean): void => {
    this.isRequestChangesActive = state
  }

  @action setRequestStatus = (status?: EditableResourceState): void => {
    this.requestStatus = status
  }

  @action setRequestLicense = (license?: ResourceLicense): void => {
    this.requestLicense = license
  }

  @action setRequestAIGenerated = (state?: boolean): void => {
    this.requestAIGenerated = String(state)
  }

  @action setRequestAIGenerator = (generator?: string): void => {
    this.requestAIGenerator = generator
  }

  @action setRequestTitle = (title?: string): void => {
    this.requestTitle = title
  }

  @action setRequestKeywords = (keywords?: string[]): void => {
    this.requestKeywords = keywords
  }

  @action setRequestPreview = (value?: number): void => {
    this.requestPreview = value
  }

  @action setRequestReleases = (releases?: Release[]): void => {
    this.requestReleases = releases
  }

  @action setRequestProofOfSale = (file?: FileUpload): void => {
    this.requestProofOfSale = file
  }

  @action setRequestExplanation = (value: string): void => {
    this.requestExplanation = value
  }

  @action resetRequestChanges = (
    shipResetCurrentRequestChange?: boolean
  ): void => {
    this.isRequestChangesActive = false
    this.requestStatus = undefined
    this.requestLicense = undefined
    this.requestAIGenerated = undefined
    this.requestAIGenerator = undefined
    this.requestTitle = undefined
    this.requestKeywords = undefined
    this.requestPreview = undefined
    this.requestReleases = undefined
    this.requestProofOfSale = undefined
    this.requestExplanation = ''

    if (!shipResetCurrentRequestChange) {
      this.currentRequestChange = undefined
    }
  }

  @action createRequestChange = async (resource: Resource): Promise<void> => {
    const session = this.AuthStore.session
    if (!session) return

    try {
      this.creatingRequest = true

      const formData = new FormData()

      if (this.requestProofOfSale) {
        formData.set(
          'resource_change_request[attachment]',
          this.requestProofOfSale.file as Blob,
          this.requestProofOfSale.name
        )
      }

      this.requestTypes.forEach(type => {
        formData.append('resource_change_request[request_types][]', type)
      })

      formData.set('resource_change_request[resource_id]', resource.import_guid)
      formData.set(
        'resource_change_request[change_data]',
        JSON.stringify(this.changeData)
      )
      formData.set(
        'resource_change_request[explanation]',
        this.requestExplanation
      )

      const changeRequest = await this.api.request(
        'POST',
        `/api/v1/resource_change_requests`,
        {
          body: formData,
        }
      )

      this.currentRequestChange = changeRequest
    } catch (error) {
      captureError(
        error,
        'Error while creating new request - stores/RequestQueueStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.creatingRequest = false
      })
    }
  }

  @action getRequestChange = async (resource: Resource): Promise<any> => {
    const session = this.AuthStore.session
    if (!session) return

    try {
      this.fetchingRequest = true
      const response = await this.api.request(
        'GET',
        `/api/v1/resource_change_requests/${resource.import_guid}`
      )
      this.currentRequestChange = response
      return response
    } catch (error) {
      captureError(
        error,
        'Error fetching request change - stores/RequestQueueStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.fetchingRequest = false
      })
    }
  }

  @action deleteRequestChange = async (id: string): Promise<void> => {
    const session = this.AuthStore.session
    if (!session) return

    try {
      this.deletingRequest = true
      await this.api.request('DELETE', `/api/v1/resource_change_requests/${id}`)
      this.currentRequestChange = undefined
    } catch (error) {
      captureError(
        error,
        'Error deleting request change - stores/RequestQueueStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.deletingRequest = false
      })
    }
  }
}
