/* regenerate with yarn generate-api NO MANUAL EDITY PLZ*/
/* Generated by restful-react */

import React from "react";
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from "restful-react";
export const SPEC_VERSION = "0.0.1"; 
/**
 * Article model
 */
export interface Article {
  id: string;
  headline: string;
  url: string;
  blurb: string;
  image_url: string;
}

/**
 * BulkOperation model
 */
export interface BulkOperation {
  id: string;
  user_id: string;
  state: string;
  success_count: number;
  failure_count: number;
  job_count: number;
  message_key: number;
  progress: number;
}

/**
 * ContentGap model
 */
export interface ContentGap {
  /**
   * vector, photo, or video
   */
  content_type?: string;
  /**
   * search term
   */
  term?: string;
  /**
   * number of searches
   */
  search_volume?: number;
  /**
   * number of results
   */
  search_result_count?: number;
}

/**
 * Responses_SingleItemCount model
 */
export interface ResponsesSingleItemCount {
  /**
   * Count of the items
   */
  count: number;
  /**
   * Optional item type
   */
  item_type?: string;
}

/**
 * DownloadsAndEarnings model
 */
export interface DownloadsAndEarnings {
  date?: string;
  resource_content_type?: string;
  free_us_count?: number;
  free_intl_count?: number;
  free_count?: number;
  free_us_amount?: string;
  free_intl_amount?: string;
  free_amount?: string;
  pro_us_count?: number;
  pro_intl_count?: number;
  pro_count?: number;
  pro_us_amount?: string;
  pro_intl_amount?: string;
  pro_amount?: string;
  total_us_count?: number;
  total_intl_count?: number;
  total_count?: number;
  total_us_amount?: string;
  total_intl_amount?: string;
  total_amount?: string;
  flagged_free_us_count?: number;
  flagged_free_intl_count?: number;
  flagged_free_count?: number;
  flagged_free_us_amount?: string;
  flagged_free_intl_amount?: string;
  flagged_free_amount?: string;
  flagged_pro_us_count?: number;
  flagged_pro_intl_count?: number;
  flagged_pro_count?: number;
  flagged_pro_us_amount?: string;
  flagged_pro_intl_amount?: string;
  flagged_pro_amount?: string;
  flagged_total_us_count?: number;
  flagged_total_intl_count?: number;
  flagged_total_count?: number;
  flagged_total_us_amount?: string;
  flagged_total_intl_amount?: string;
  flagged_total_amount?: string;
}

/**
 * ContributorEarningsByContentType model
 */
export interface ContributorEarningsByContentType {
  content_type?: string;
  total_earnings?: Money;
  /**
   * How many resources of this content type the user has published
   */
  published_resources?: number;
  avg_total_earnings_per_file?: Money;
}

export interface Money {
  /**
   * not necessarily pennies, 1_000_000 = $1 for USD6 currency
   */
  cents: number;
  currency_iso: string;
}

/**
 * ContributorEarningsByLicenseType model
 */
export interface ContributorEarningsByLicenseType {
  license_type?: string;
  total_earnings?: Money;
  /**
   * How many resources of this license type the user has published
   */
  published_resources?: number;
  avg_total_earnings_per_file?: Money;
}

/**
 * ContributorTopKeywords model
 */
export interface ContributorTopKeywords {
  term?: string;
}

/**
 * ContributorTopResource model
 */
export interface ContributorTopResource {
  metric_type?: string;
  metric_value?: number;
  resource?: Resource;
}

/**
 * Resource model
 */
export interface Resource {
  acceptable_as_free: boolean;
  acceptable_as_editorial: boolean;
  magic_ai_rejection?: boolean;
  import_guid: string;
  comments_count: number;
  content_type: "vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic";
  created_at?: string;
  submitted_at?: string;
  description?: string;
  id: string;
  /**
   * Tags associated with the resource
   */
  keywords: string[];
  last_error?: string;
  /**
   * License for this resource
   */
  license?: "free" | "pro" | "editorial";
  /**
   * Checks if license is explicitly set by user
   */
  license_set?: boolean;
  /**
   * Resource lifetime downloads from ETL::ResourceLifetimeDownloads
   */
  lifetime_downloads?: number;
  /**
   * Set to use magic_metadata
   */
  magic_metadata?: boolean;
  locked_reason?: string;
  preview_url?: string;
  preview: Preview;
  preview_video?: File;
  previews: Preview[];
  release_ids: string[];
  source_file: File;
  source_files?: File[];
  state: "started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "active" | "inactive";
  source_file_fingerprint?: string;
  state_changed_at?: string;
  title?: string;
  meta_data?: Metadata;
  updated_at?: string;
  rating?: number;
  pro: boolean;
  program_type?: "ppd_free" | "ppd_pro" | "wo";
  file_type?: "png" | "psd" | "eps" | "jpg" | "mp4" | "mov" | "r3d" | "zip";
  category?: Category;
  category_id?: string;
  /**
   * Id of duplicate record. If present it means there are resources with the same source file.
   */
  duplicate_id?: number;
  review_type?: string;
  svg_url?: string;
  contributor_import_guid?: string;
  assignment_keyword?: string;
  export_failure_error?: string;
  export_failed_at?: string;
  svg_state?: string;
  /**
   * Checks if all releases are verified
   */
  all_releases_verified?: boolean;
  verified_release_ids?: string[];
  auto_submitted?: boolean;
  ai_generated?: boolean;
  approved_at?: string;
  marketplace_account_ids?: string[];
  /**
   * Count of resource admin comments
   */
  admin_comments_counts?: number;
  /**
   * Count of resource state changes
   */
  history_count?: number;
  reviewed_at?: string;
}

export interface Preview {
  preview?: string;
  mp4?: string;
  webm?: string;
}

export interface File {
  url?: string;
  filename?: string;
  content_type?: string;
}

export interface Metadata {
  size?: string;
  width?: string;
  height?: string;
  bitrate?: string;
  duration?: string;
  file_type?: string;
  colorspace?: string;
  frame_rate?: string;
  resolution?: string;
  audio_codec?: string;
  video_codec?: string;
  ai_generator?: string;
  video_thumbnail_time?: string;
  ai_generated?: boolean;
}

/**
 * Category model
 */
export interface Category {
  id: string;
  title: string;
  file_types: ("png" | "psd" | "eps" | "jpg" | "mp4" | "mov" | "r3d" | "zip")[];
  content_type?: string;
}

/**
 * CuratedKeyword model
 */
export interface CuratedKeyword {
  /**
   * Keyword text
   */
  text?: string;
  /**
   * URL for keyword link
   */
  url?: string;
}

/**
 * Accept all resources as free and/or editorial
 */
export interface PatchApiV1ResourcesAcceptAllConditions {
  accept_as_free?: boolean;
  accept_as_editorial?: boolean;
}

/**
 * Responses_BulkUpdateSuccess model
 */
export interface ResponsesBulkUpdateSuccess {
  /**
   * Count of resources accepted for bulk update
   */
  success: number;
  /**
   * Count of resources not accepted for bulk update
   */
  fail: number;
  /**
   * IDs of resources not accepted for bulk update
   */
  failed_ids: number[];
}

/**
 * Responses_NotApprovedCounts model
 */
export interface ResponsesNotApprovedCounts {
  acceptable_as_editorial: number;
  acceptable_as_free: number;
  make_all_changes: number;
}

/**
 * Responses_ResourceCounts model
 */
export interface ResponsesResourceCounts {
  /**
   * Count of all resources
   */
  all: number;
  /**
   * Count of resources in the unapproved state
   */
  unapproved?: number;
  /**
   * Count of resources in the edit_requested state
   */
  edit_requested?: number;
  /**
   * Count of resources in the approved state
   */
  approved?: number;
  /**
   * Count of resources in the published state
   */
  published?: number;
  /**
   * Count of resources in the exporting state
   */
  exporting?: number;
  /**
   * Count of resources in the export_failed state
   */
  export_failed?: number;
  /**
   * Count of resources in the rejected state
   */
  rejected?: number;
  /**
   * Count of resources in the pending_rating state
   */
  pending_rating?: number;
}

/**
 * Check if resource titles are unique for the current user
 */
export interface PostApiV1ResourcesCheckUniqueTitles {
  resources: {
  import_guid: string;
  /**
   * Titles to check for uniqueness
   */
  title: string;
}[];
}

/**
 * Responses_TitleUniqueness model
 */
export interface ResponsesTitleUniqueness {
  /**
   * Title of the resource
   */
  title?: string;
  /**
   * Import guid of the resource
   */
  import_guid?: string;
  /**
   * Boolean to indicate if title is unique
   */
  is_unique?: boolean;
}

/**
 * Bulk Update resources
 */
export interface PatchApiV1Resources {
  resources: {
  id: string;
  pro?: boolean;
  title?: string;
  description?: string;
  rating?: number;
  /**
   * Array of release ids
   */
  release_ids?: string[];
  state?: "started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "active" | "inactive";
  /**
   * Array of keywords
   */
  keywords?: string[];
  license_set?: boolean;
  license?: "free" | "pro" | "editorial";
  category_id?: string;
  ai_generated?: boolean;
  ai_generator?: string;
  magic_metadata?: boolean;
  marketplace_account_ids?: number[];
  content_type?: string;
  video_thumbnail_time?: string;
  /**
   * Review session id
   */
  review_session_id?: number;
}[];
  /**
   * Only return resources where the review is assigned to the current user
   */
  review_assigned_to_me?: boolean;
}

/**
 * Transfer a set of given resources to other marketplaces
 */
export interface PostApiV1ResourcesTransfer {
  transfer: {
  /**
   * Array of import_guids
   */
  resource_ids: string[];
  /**
   * Array of marketplace account ids
   */
  marketplace_account_ids: number[];
  /**
   * Do not transfer if resource has already been transferred
   */
  avoid_duplicates?: boolean;
};
}

/**
 * Resources_SearchResults model
 */
export interface ResourcesSearchResults {
  total_count?: number;
  resources?: Resource[];
  facets?: ResourcesSearchFacets[];
}

export interface ResourcesSearchFacets {
  field?: string;
  counts?: ResourcesSearchFacetCounts[];
}

export interface ResourcesSearchFacetCounts {
  key?: string;
  count?: number;
}

/**
 * Update a single resource
 */
export interface PatchApiV1ResourcesId {
  resource: {
  /**
   * Array of keywords
   */
  keywords?: string[];
  title?: string;
  description?: string;
  preview_video?: any;
  /**
   * Array of preview files (bundles only)
   */
  previews?: {
  file: any;
}[];
};
}

/**
 * APIError model
 */
export interface APIError {
  code: string;
  message: string;
}

/**
 * Comment model
 */
export interface Comment {
  id?: string;
  user_id?: string;
  comment?: string;
  updated_at?: string;
}

/**
 * ResourceHistory model
 */
export interface ResourceHistory {
  action?: string;
  state_changed_at?: string;
  changed_by?: string;
  reason?: string;
  comment?: string;
}

/**
 * Resources_SuggestedMetadata model
 */
export interface ResourcesSuggestedMetadata {
  /**
   * Job status. When job is done completed is the status.
   */
  status?: string;
  /**
   * Suggested title
   */
  caption?: string;
  keywords?: string[];
  /**
   * BLIP API error messages
   */
  message?: string;
}

/**
 * WebUploadImport model
 */
export interface WebUploadImport {
  id?: string;
  user_id?: string;
  keyword_assignment_id?: string;
  bid?: string;
  state?: string;
  failure_count?: number;
  status?: BatchStatus;
  import_failures?: ResourceImportFailure[];
}

export interface BatchStatus {
  percent_complete?: number;
  state?: string;
  message?: string;
}

export interface ResourceImportFailure {
  file?: File;
  filename?: string;
  error_keys?: string[];
}

/**
 * Create a new SEO Metadata record belonging to a resource
 */
export interface PostApiV1ResourcesResourceIdSeoMetadata {
  seo_metadata: {
  title_tag?: string;
  caption?: string;
  alt_text?: string;
  source_file_slug?: string;
  h1?: string;
};
}

/**
 * SeoMetadata model
 */
export interface SeoMetadata {
  title_tag?: string;
  caption?: string;
  alt_text?: string;
  source_file_slug?: string;
  h1?: string;
}

/**
 * Update an SEO Metadata record belonging to a resource
 */
export interface PatchApiV1ResourcesResourceIdSeoMetadata {
  seo_metadata: {
  title_tag?: string;
  caption?: string;
  alt_text?: string;
  source_file_slug?: string;
  h1?: string;
};
}

/**
 * Updates the given duplicate
 */
export interface PatchApiV1DuplicatesFingerprint {
  /**
   * Import guid of the original resource
   */
  original_id?: string;
  /**
   * Note about ownership if owner not in the CM
   */
  ownership_note?: string;
}

/**
 * DuplicateOwner model
 */
export interface DuplicateOwner {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  id_verification_status?: string;
  resource_counts?: UsersResourceCounts;
  assigned_admin?: User;
  /**
   * User's resource rating average
   */
  average_rating?: string;
  has_notes?: boolean;
  /**
   * User's overall trustworthiness score
   */
  contributor_score?: string;
  /**
   * Import guid of the duplicate resource
   */
  duplicate_resource_id?: string;
}

export interface UsersResourceCounts {
  started?: number;
  submitting?: number;
  submitted?: number;
  needs_corrections?: number;
  flagged?: number;
  rejected?: number;
  approving?: number;
  approved?: number;
  active?: number;
  inactive?: number;
}

/**
 * User model
 */
export interface User {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  /**
   * User avatar url
   */
  avatar_url?: string;
  /**
   * User.eezy_portfolio
   */
  portfolio_url?: string;
  id_verification_status?: string;
  id_inquiry_status?: string;
  program_type?: string;
  resource_counts?: UsersResourceCounts;
  assigned_admin?: User;
  /**
   * User's resource rating average
   */
  average_rating?: string;
  has_notes?: boolean;
  /**
   * User's overall trustworthiness score
   */
  contributor_score?: string;
}

/**
 * Create a new Filestack import record and launch the import process
 */
export interface PostApiV1FilestackImports {
  uploads: {
  url: string;
  mimetype: string;
}[];
  assignment_id?: number;
}

/**
 * FilestackImport model
 */
export interface FilestackImport {
  id?: string;
  user_id?: string;
  keyword_assignment_id?: string;
  bid?: string;
  state?: string;
  failure_count?: number;
  status?: BatchStatus;
  import_failures?: ResourceImportFailure[];
}

/**
 * FtpJob model
 */
export interface FtpJob {
  remote_file_key?: string;
  state?: string;
  error_keys?: string[];
}

/**
 * Responses_Labels_Countries model
 */
export interface ResponsesLabelsCountries {
  /**
   * Country ID
   */
  id?: string;
  /**
   * Country name
   */
  name?: string;
}

/**
 * Responses_Labels_ReleaseMetadata model
 */
export interface ResponsesLabelsReleaseMetadata {
  /**
   * key-value pairs for use in dropdown
   */
  age: {[key: string]: any};
  /**
   * key-value pairs for use in dropdown
   */
  gender: {[key: string]: any};
  /**
   * key-value pairs for use in dropdown
   */
  ethnicity: {[key: string]: any};
}

/**
 * Marketplace model
 */
export interface Marketplace {
  id?: string;
  name?: string;
  server_domain?: string;
  protocol?: string;
  creator_id?: string;
}

/**
 * MarketplaceAccount model
 */
export interface MarketplaceAccount {
  id?: string;
  portfolio?: string;
  connection_state?: string;
  connection_state_updated_at?: string;
  marketplace?: Marketplace;
  marketplace_metadata_operation?: MarketplaceMetadataOperation;
  created_at?: string;
}

/**
 * MarketplaceMetadataOperation model
 */
export interface MarketplaceMetadataOperation {
  id?: string;
  max_title_characters?: number;
  max_keywords?: number;
  marketplace_account_id?: number;
  title_as_description?: boolean;
  no_embedding?: boolean;
}

/**
 * MarketplaceAccountResourceRollup model
 */
export interface MarketplaceAccountResourceRollup {
  marketplace_id?: string;
  /**
   * Name of the marketplace
   */
  marketplace_name?: string;
  /**
   * List of resources with their transfer states
   */
  resources?: string[];
}

/**
 * Create a marketplace metadata operation
 */
export interface PostApiV1MarketplaceMetadataOperations {
  marketplace_metadata_operation: {
  marketplace_account_id: number;
  no_embedding?: boolean;
  max_title_characters?: number;
  max_keywords?: number;
  title_as_description?: boolean;
};
}

/**
 * Update marketplace metadata operation
 */
export interface PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationId {
  marketplace_metadata_operation: {
  marketplace_account_id: number;
  no_embedding?: boolean;
  max_title_characters?: number;
  max_keywords?: number;
  title_as_description?: boolean;
};
}

/**
 * Create a note
 */
export interface PostApiV1Notes {
  /**
   * User ID
   */
  contributor_id: string;
  /**
   * Note text
   */
  body: string;
}

/**
 * Note model
 */
export interface Note {
  id: string;
  body?: string;
  user?: User;
  created_at?: string;
  updated_at?: string;
}

/**
 * Update a note
 */
export interface PatchApiV1NotesId {
  /**
   * Note text
   */
  body?: string;
  active?: boolean;
}

/**
 * Opportunity model
 */
export interface Opportunity {
  /**
   * vector, photo, or video
   */
  content_type?: string;
  /**
   * search term
   */
  term?: string;
  /**
   * calculated value between 0 and 1 inclusive
   */
  score?: number;
}

/**
 * PayoneerRegistrationLink model
 */
export interface PayoneerRegistrationLink {
  /**
   * A link to register a Payoneer account with the CM
   */
  link?: string;
}

/**
 * Release model
 */
export interface Release {
  id?: string;
  document?: File;
  kind?: string;
  user_id?: string;
  hidden?: string;
  name?: string;
  age?: string;
  ethnicity?: string;
  gender?: string;
  created_at?: string;
}

/**
 * ResourceChangeRequest model
 */
export interface ResourceChangeRequest {
  id?: number;
  /**
   * Resource import_guid
   */
  resource_id?: string;
  /**
   * User import_guid
   */
  user_id?: string;
  request_types?: string;
}

/**
 * Update metadata for many resources with a csv
 */
export interface PostApiV1ResourceMetadata {
  csv_file: any;
}

/**
 * Responses_JobStatus model
 */
export interface ResponsesJobStatus {
  processing: boolean;
}

/**
 * ResourceMetadataTemplate model
 */
export interface ResourceMetadataTemplate {
  id?: string;
  name?: string;
  title?: string;
  keywords?: string[];
  license?: string;
  release_ids?: string[];
  ai_generated?: boolean;
  ai_generator?: string;
}

/**
 * Create a new resource metadata template
 */
export interface PostApiV1ResourceMetadataTemplates {
  title?: string;
  keywords?: string[];
  license?: "free" | "pro" | "editorial";
  /**
   * Array of release ids to associate with template
   */
  release_ids?: string[];
  /**
   * Indicates if resource was generated with AI
   */
  ai_generated?: boolean;
  /**
   * Name of the AI used to generate the image
   */
  ai_generator?: string;
  name: string;
}

/**
 * Update a resource metadata template
 */
export interface PatchApiV1ResourceMetadataTemplatesId {
  title?: string;
  keywords?: string[];
  license?: "free" | "pro" | "editorial";
  /**
   * Array of release ids to associate with template
   */
  release_ids?: string[];
  /**
   * Indicates if resource was generated with AI
   */
  ai_generated?: boolean;
  /**
   * Name of the AI used to generate the image
   */
  ai_generator?: string;
  name?: string;
}

/**
 * ResourceMetric model
 */
export interface ResourceMetric {
  all_time_earnings?: number;
  all_time_downloads?: number;
  title?: string;
  /**
   * Tags associated with the resource
   */
  keywords: string[];
  category?: string;
  license_type?: string;
  created_at?: string;
  resource_preview_url?: string;
}

/**
 * Session model
 */
export interface Session {
  id: string;
  user_id: any;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string;
  remember_created_at: string;
  /**
   * Array of enabled features for this user
   */
  enabled_features: string[];
  /**
   * Array of roles for this user
   */
  roles: string[];
  /**
   * Token for CM Api auth
   */
  api_token: string;
  /**
   * API endpoint for user interaction
   */
  api_endpoint: string;
  /**
   * JWT token authenticating user to referrals service
   */
  referrals_token: string;
  referrals_url: string;
  /**
   * Goal Id for referrals goal
   */
  referrals_goal_id: string;
  upload_config: SessionDataUploadConfig;
  content_types?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  review_content_types?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  ftp: SessionDataFtpCredentials;
  wo: boolean;
  entity_verification_url: string;
  tax_questionnaire_state: "incomplete" | "pending_review" | "complete" | "denied" | "expired";
  tax_rates: SessionDataTaxRates;
  reviewer_permissions?: SessionDataReviewerPermission;
  unpaid_account_balance: Money;
  unpaid_flagged_balance: Money;
  unpaid_us_balance: Money;
  unpaid_us_video_balance: Money;
  current_on_terms: boolean;
  directory_confirmation: boolean;
  needs_verification: boolean;
  /**
   * Array of disabled categories for this user
   */
  disabled_categories: string[];
}

export interface SessionDataUploadConfig {
  bucket: string;
  prefix: string;
  api_key: string;
  api_secret: string;
  region: string;
}

export interface SessionDataFtpCredentials {
  username?: string;
  password?: string;
  host: string;
}

export interface SessionDataTaxRates {
  country?: string;
  default?: number;
  video?: number;
  article?: string;
}

export interface SessionDataReviewerPermission {
  content_type: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic" | "all")[];
  program_type: ("all" | "ppd" | "wo")[];
  license: ("all" | "pro" | "free" | "editorial")[];
  category_id: string[];
  states: ("started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "active" | "inactive")[];
  auto_assign_reviews: boolean;
}

/**
 * Login with 2FA
 */
export interface PostApiV1SessionTwoFactor {
  otp_attempt: string;
  fingerprint?: string;
  timezone?: string;
  screen_resolution?: string;
}

/**
 * Login as another user
 */
export interface PostApiV1SessionSwitchUser {
  user_id: string;
}

/**
 * Create a new user session
 */
export interface PostApiV1Session {
  user: {
  email: string;
  password: string;
  fingerprint?: string;
  remember_me?: boolean;
  bypass_remember_me?: boolean;
  timezone?: string;
  screen_resolution?: string;
};
}

/**
 * Updates or creates a new tax form submission for the current user
 */
export interface PutApiV1TaxFormSubmissions {
  form_data: any;
  withholding_rates: {
  default: number;
  video: number;
};
  submit: boolean;
}

/**
 * TaxFormSubmission model
 */
export interface TaxFormSubmission {
  id: number;
  form_data?: {[key: string]: any};
  withholding_rates?: {[key: string]: any};
  expiration_date: string;
  tax_questionnaire_id: number;
  created_at: string;
  updated_at: string;
  submitted_at: string;
}

/**
 * Sign Terms specified by id
 */
export interface PostApiV1TermsSign {
  /**
   * Id of the terms you are signing
   */
  terms_id: number;
}

/**
 * Terms model
 */
export interface Terms {
  id?: string;
  active: boolean;
  agreement: string;
  type: string;
  version: string;
}

/**
 * TopContributor model
 */
export interface TopContributor {
  metric_value?: number;
  resource_preview?: string;
  user: User;
}

/**
 * TrendingTerm model
 */
export interface TrendingTerm {
  /**
   * Search term
   */
  term?: string;
  /**
   * Array of objects with date and count
   */
  trend_data?: TrendData[];
}

export interface TrendData {
  /**
   * Data point date
   */
  date?: string;
  /**
   * Value for data point
   */
  count?: number;
}

/**
 * UiMessage model
 */
export interface UiMessage {
  /**
   * Human friendly unique identifier
   */
  key?: string;
  /**
   * Message copy
   */
  copy?: string;
}

/**
 * UploadSetting model
 */
export interface UploadSetting {
  category?: Category;
  license?: "free" | "pro" | "editorial";
  ai_generated?: boolean;
  magic_metadata?: boolean;
  auto_submit?: boolean;
  marketplace_account_ids?: string[];
}

/**
 * Upsert an upload setting
 */
export interface PutApiV1UploadSettings {
  upload_setting: {
  category_id: string;
  license?: "free" | "pro" | "editorial";
  ai_generated?: boolean;
  magic_metadata?: boolean;
  auto_submit?: boolean;
  marketplace_account_ids?: number[];
};
}

/**
 * Updates the password and unlocks the account of the user found with the provided token
 */
export interface PatchApiV1UsersPasswordUnlock {
  unlock_token?: string;
  password?: string;
  password_confirmation?: string;
}

/**
 * Updates the password of the user found with the provided token
 */
export interface PatchApiV1UsersPasswordUpdate {
  reset_password_token?: string;
  password?: string;
  password_confirmation?: string;
}

/**
 * Sends reset password instructions. The resulting email will have a link with a reset token.
 */
export interface PostApiV1UsersPassword {
  email: string;
}

/**
 * SubmissionQuota model
 */
export interface SubmissionQuota {
  category?: string;
  category_id?: string;
  resource_count?: number;
  quota?: number;
}

/**
 * KeywordAssignment model
 */
export interface KeywordAssignment {
  id?: string;
  user_id?: string;
  name?: string;
  content_type?: string;
  created_at?: string;
  updated_at?: string;
  resource_limit?: number;
  taken?: string;
  hidden?: boolean;
}

/**
 * UserRecentKeywords model
 */
export interface UserRecentKeywords {
  keyword?: string;
}

/**
 * Reset proposed payment account and token
 */
export interface PatchApiV1UserProfileResetPaymentAccountInfo {
  id: string;
}

/**
 * Updates the payment account email of the user found with the provided token
 */
export interface PatchApiV1UserProfileUpdatePaymentAccount {
  id: string;
  payment_account_change_token: string;
  payment_account_proposed: string;
}

/**
 * Sends payment account email change confirmation. The resulting email will have a link with a token.
 */
export interface PatchApiV1UserProfileNewPaymentAccount {
  id: string;
  payment_account_proposed: string;
}

/**
 * UserProfile model
 */
export interface UserProfile {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  /**
   * User avatar url
   */
  avatar_url?: string;
  /**
   * User.eezy_portfolio
   */
  portfolio_url?: string;
  id_verification_status?: string;
  id_inquiry_status?: string;
  /**
   * A 'formatted_address' from 'google.maps.places.PlaceResult' interface'
   */
  location?: string;
  /**
   * Latitude and Longitude formatted like Google's 'LatLngLiteral'
   */
  location_coordinates?: {[key: string]: any};
  payment_method?: string;
  payment_account?: string;
  /**
   * Payment email
   */
  paypal_account?: string;
  payment_threshold_cents?: number;
  western_union_account?: string;
  eligible_to_complete_tax_info?: boolean;
  tax_questionnaire?: TaxQuestionnaire;
  vecteezy_user_info?: VecteezyUserInfo;
  specialties?: Specialty[];
  /**
   * specialties a contributor has that are not listed in the database
   */
  other_specialties?: string;
  /**
   * API key used in external auth
   */
  external_api_key_id?: string;
  /**
   * API secret used in external auth
   */
  external_api_secret?: string;
  /**
   * Payoneer registration status
   */
  payoneer_status?: string;
  portfolio_ai_training_opt_out?: boolean;
  available_for_hire?: boolean;
  meta_data?: {[key: string]: any};
  approved_resources_count?: number;
  address?: {[key: string]: any};
  /**
   * Proposed Payment email
   */
  payment_account_proposed?: string;
}

/**
 * TaxQuestionnaire model
 */
export interface TaxQuestionnaire {
  us_person?: boolean;
  country?: string;
  withholding_rate?: number;
  entity_type?: string;
  applicable_tax_form?: "W9" | "W-8BEN" | "W-8BEN-E" | "W-8ECI" | "W-8EXP" | "W-8IMY";
  signature_request_id?: string;
  signature_id?: string;
  template_id?: string;
  signed_at?: string;
  state?: string;
  denial_reason?: string;
  expiring_soon?: boolean;
}

export interface VecteezyUserInfo {
  first_name?: string;
  last_name?: string;
  username?: string;
  email?: string;
  bio?: string;
  country?: string;
  slug?: string;
  social_media?: {[key: string]: any};
}

/**
 * Specialty model
 */
export interface Specialty {
  id: string;
  name?: string;
}

/**
 * update user profile
 */
export interface PatchApiV1UserProfileId {
  user?: {
  first_name?: string;
  last_name?: string;
  avatar?: any;
  payment_method?: string;
  payment_account?: string;
  payment_threshold_cents?: number;
  /**
   * A 'formatted_address' from 'google.maps.places.PlaceResult' interface'
   */
  location?: string;
  /**
   * Latitude and Longitude formatted like Google's 'LatLngLiteral'
   */
  location_coordinates?: {[key: string]: any};
  /**
   * Array of ids of specialties to be associated with this user
   */
  specialty_ids?: string[];
  other_specialties?: string;
  portfolio_ai_training_opt_out?: boolean;
  available_for_hire?: boolean;
  meta_data?: {[key: string]: any};
};
  vecteezy_user?: {
  username?: string;
  country?: string;
  bio?: string;
  website?: string;
  facebook_url?: string;
  twitter_url?: string;
  instagram_url?: string;
  linkedin_url?: string;
};
}

/**
 * Get pre-signed url for web upload
 */
export interface PostApiV1WebImportsPresignedUrl {
  import: {
  filename: string;
};
}

/**
 * WebImportPresignedUrl model
 */
export interface WebImportPresignedUrl {
  url?: string;
}

/**
 * Create a new Web Upload Import record and launch the import process
 */
export interface PostApiV1WebUploadImports {
  uploads: {
  url: string;
  mimetype: string;
}[];
  assignment_id?: number;
}

/**
 * Admin_PendingUser model
 */
export interface AdminPendingUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  application_completed_at: string;
  portfolio_links_count: number;
  custom_rejection_reason?: string;
  rejection_reason?: string;
  application_status?: string;
  fingerprints?: string;
  content_type?: string;
  ppd?: string;
  dob_day?: number;
  dob_month?: string;
  dob_year?: number;
  address?: string;
  city_state?: string;
  country?: string;
  portfolio_links?: string[];
  rejection_reasons?: string[];
  login_ip_addresses?: AdminLoginDevice[];
}

export interface AdminLoginDevice {
  /**
   * The device fingerprint
   */
  fingerprint?: string;
  /**
   * The user agent
   */
  user_agent?: string;
  /**
   * The ip
   */
  ip?: string;
  timezone?: string;
  screen_resolution?: string;
}

/**
 * Admin_ConnectedAccount model
 */
export interface AdminConnectedAccount {
  email?: string;
  remember_created_at?: string;
  state?: string;
  locked_reason?: string;
  locked_at?: string;
  total_logins?: number;
  most_recent_login?: string;
  fingerprints?: string;
}

/**
 * reject a pending user
 */
export interface PatchApiV1AdminPendingUsersIdReject {
  /**
   * From rejection_reasons
   */
  rejection_reason: string;
  /**
   * Explanation for Other rejection reason set
   */
  custom_rejection_reason?: string;
}

/**
 * RejectionReason model
 */
export interface RejectionReason {
  id: string;
  name: string;
  label?: string;
  content_type: "vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic";
  comment?: string;
  sort_order?: number;
  comment_editable?: boolean;
  rejection_type: "needs_corrections" | "flagged" | "accept_as_free" | "accept_as_editorial" | "permanent";
  categories?: Category[];
}

/**
 * Update resources returned with the provided search filters
 */
export interface PatchApiV1AdminResourcesUpdateFiltered {
  search_filters: {
  search_field?: "user_email" | "reviewer_email" | "keywords" | "assignment" | "import_guid" | "source_file_fingerprint";
  query?: string;
};
  update: {
  /**
   * Resource title
   */
  title?: string;
  /**
   * Resource Description
   */
  description?: string;
  /**
   * Resource keywords in array of strings
   */
  keywords?: string[];
  /**
   * Resource star rating
   */
  rating?: number;
  /**
   * Resource license
   */
  license?: "free" | "pro" | "editorial";
  /**
   * Resource comment
   */
  comment_text?: string;
  /**
   * Resource rejection reason id
   */
  rejection_reason_id?: number;
  /**
   * Resource rejecter id
   */
  rejecter_id?: number;
  /**
   * Resource state
   */
  state?: "started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "inactive";
  /**
   * Action to take on SVG in apollo
   */
  svg_state?: "approved" | "rejected";
  video_thumbnail_time?: string;
};
}

/**
 * Admin Bulk Update Resources
 */
export interface PatchApiV1AdminResourcesUpdate {
  resources: {
  id: string;
  /**
   * Resource title
   */
  title?: string;
  /**
   * Resource Description
   */
  description?: string;
  /**
   * Resource keywords in array of strings
   */
  keywords?: string[];
  /**
   * Resource star rating
   */
  rating?: number;
  /**
   * Resource license
   */
  license?: "free" | "pro" | "editorial";
  /**
   * Resource comment
   */
  comment_text?: string;
  /**
   * Resource rejection reason id
   */
  rejection_reason_id?: number;
  /**
   * Resource rejecter id
   */
  rejecter_id?: number;
  /**
   * Resource state
   */
  state?: "started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "inactive";
  /**
   * Action to take on SVG in apollo
   */
  svg_state?: "approved" | "rejected";
  video_thumbnail_time?: string;
}[];
}

/**
 * Starts the job to reject all of a contributor's submitted resources
 */
export interface PatchApiV1AdminResourcesRejectAllUserId {
  rejections: {
  /**
   * Id of rejection reason that will be used
   */
  rejection_reason_id: string;
  /**
   * Id of category for rejection reason to be applied to
   */
  category_id: string;
  /**
   * Rating to apply to resources in the category
   */
  rating?: number;
}[];
}

/**
 * Starts the job to approve all of a contributor's resources in category at a given rating
 */
export interface PatchApiV1AdminResourcesApproveAllUserId {
  approvals: {
  /**
   * Category being bulk approved with the given rating
   */
  category_id: string;
  /**
   * Rating to apply to resources in the category
   */
  rating: number;
}[];
}

/**
 * ReviewDetails model
 */
export interface ReviewDetails {
  date_last_rejected?: string;
  rejection_reason?: RejectionReason;
  reviewer_email?: string;
  contributor_import_guid?: string;
  original_with_duplicates?: string;
  duplicate_owner?: DuplicateOwner;
  ownership_note?: string;
  marketplace_data?: ResourcesMarketplaceData[];
}

export interface ResourcesMarketplaceData {
  marketplace_id?: number;
  status?: string;
}

/**
 * Updates a resource
 */
export interface PatchApiV1AdminResourcesImportGuidUpdate {
  /**
   * Resource title
   */
  title?: string;
  /**
   * Resource Description
   */
  description?: string;
  /**
   * Resource keywords in array of strings
   */
  keywords?: string[];
  /**
   * Resource star rating
   */
  rating?: number;
  /**
   * Resource license
   */
  license?: "free" | "pro" | "editorial";
  /**
   * Resource comment
   */
  comment_text?: string;
  /**
   * Resource rejection reason id
   */
  rejection_reason_id?: number;
  /**
   * Resource rejecter id
   */
  rejecter_id?: number;
  /**
   * Resource state
   */
  state?: "started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "inactive";
  /**
   * Action to take on SVG in apollo
   */
  svg_state?: "approved" | "rejected";
  video_thumbnail_time?: string;
}

/**
 * ReviewCounts model
 */
export interface ReviewCounts {
  hourly_counts?: any;
  daily_counts?: any;
}

/**
 * ReviewSession model
 */
export interface ReviewSession {
  id?: string;
  created_at?: string;
  ended_at?: string;
  end_type?: string;
}

/**
 * ReviewSessionMetrics model
 */
export interface ReviewSessionMetrics {
  resource_count?: number;
  duration?: string;
}

/**
 * Admin_SecureValue model
 */
export interface AdminSecureValue {
  value: string;
}

/**
 * SubmissionSummaryReview model
 */
export interface SubmissionSummaryReview {
  user: User;
  content_types: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  program_types: ("free" | "pro" | "editorial")[];
  average_rating?: string;
  active: number;
  current_user_is_reviewer: boolean;
  category_ids: string[];
}

/**
 * UserSubmissionSummaryRollup model
 */
export interface UserSubmissionSummaryRollup {
  user: User;
  content_types: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  program_types: ("free" | "pro" | "editorial")[];
  average_rating?: string;
  pending: number;
  current_user_is_reviewer: boolean;
  oldest_submission_at: string;
  newest_submission_at: string;
  /**
   * when verified status was created
   */
  verified_created_at?: string;
  unassigned: number;
  reviewer_ids: string[];
  /**
   * Array of objects with content_type, license and count
   */
  unassigned_filtered_counts?: UnassignedResourceCounts[];
  category_ids: string[];
  duplicates: boolean;
  auto_submissions: boolean;
  magic_metadata: boolean;
}

export interface UnassignedResourceCounts {
  content_type?: string;
  category_id?: string;
  license?: string;
  count?: number;
}

/**
 * Create a user, like an admin or WO contributor
 */
export interface PostApiV1AdminUsers {
  /**
   * Wrapper for all user information
   */
  user: {
  /**
   * The new user's email address
   */
  email: string;
  /**
   * The new user's first name
   */
  first_name: string;
  /**
   * The new user's last name
   */
  last_name: string;
  /**
   * Account type for new user, which affects payment
   */
  account_type: "standard" | "ppd_free" | "ppd_pro";
  /**
   * external sites permitted for new user
   */
  external_sites: ("brusheezy" | "vecteezy" | "videezy" | "dryicons" | "freevector" | "vector4free.com" | "FreeVectors.net")[];
  /**
   * ID (guid) of reviewer to which this user should be assigned.
   */
  assigned_admin_id: string;
};
}

/**
 * Admin_User model
 */
export interface AdminUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  /**
   * User avatar url
   */
  avatar_url?: string;
  /**
   * User.eezy_portfolio
   */
  portfolio_url?: string;
  id_verification_status?: string;
  id_inquiry_status?: string;
  program_type?: string;
  resource_counts?: UsersResourceCounts;
  assigned_admin?: User;
  /**
   * User's resource rating average
   */
  average_rating?: string;
  has_notes?: boolean;
  /**
   * User's overall trustworthiness score
   */
  contributor_score?: string;
  /**
   * Admin-only field
   */
  id_inquiry_status_override?: boolean;
  /**
   * Admin-only field
   */
  tax_questionnaire_state?: "incomplete" | "pending_review" | "complete" | "denied" | "expired";
  /**
   * Admin-only field
   */
  tax_questionnaire_submitted_at?: string;
  /**
   * Admin-only field
   */
  tax_questionnaire_reviewed_at?: string;
}

/**
 * Admin_TaxFormSubmission model
 */
export interface AdminTaxFormSubmission {
  id: number;
  form_data?: {[key: string]: any};
  withholding_rates?: {[key: string]: any};
  expiration_date: string;
  tax_questionnaire_id: number;
  created_at: string;
  updated_at: string;
  submitted_at: string;
  ssn_svid?: string;
  itin_svid?: string;
  ftin_svid?: string;
}

/**
 * Admin_TaxQuestionnaire model
 */
export interface AdminTaxQuestionnaire {
  us_person?: boolean;
  country?: string;
  withholding_rate?: number;
  entity_type?: string;
  applicable_tax_form?: "W9" | "W-8BEN" | "W-8BEN-E" | "W-8ECI" | "W-8EXP" | "W-8IMY";
  signature_request_id?: string;
  signature_id?: string;
  template_id?: string;
  signed_at?: string;
  state?: string;
  denial_reason?: string;
  expiring_soon?: boolean;
  id: string;
  tax_form_submission?: AdminTaxFormSubmission;
  withholding_rates?: AdminWithholdingRates;
  submitted_at?: string;
  reviewed_at?: string;
}

export interface AdminWithholdingRates {
  video?: string;
  article?: string;
  country?: string;
  default?: string;
}

/**
 * Finance_Invoice model
 */
export interface FinanceInvoice {
  id: string;
  /**
   * email of user associated with invoice
   */
  user_email: string;
  /**
   * User locked?
   */
  user_locked?: boolean;
  /**
   * guid of user
   */
  user_id: string;
  /**
   * Code for invoice (secondary ID)
   */
  code: string;
  state: "pending" | "in_progress" | "complete" | "approved" | "payment_processing" | "failed" | "canceled";
  created_at: string;
  updated_at: string;
  started_at?: string;
  completed_at?: string;
  approved_at?: string;
  payment_account_id?: string;
  payment_method?: string;
  payoneer_payee_id?: string;
  payout_batch_id?: string;
  approver?: User;
  transaction_id?: string;
  deposit: Money;
  earning: Money;
  tax_withheld: Money;
  has_notes: boolean;
  has_charges: boolean;
  failure_count?: number;
  failures?: FinanceInvoicePayoutFailure[];
  /**
   * Whether or not the invoice is void
   */
  void?: boolean;
}

export interface FinanceInvoicePayoutFailure {
  id: string;
  invoice_id?: string;
  error?: string;
  created_at: string;
  updated_at: string;
}

/**
 * Finance_Charge model
 */
export interface FinanceCharge {
  id: string;
  /**
   * email of user associated with charge
   */
  user_email: string;
  /**
   * guid of user
   */
  user_id: string;
  /**
   * Code for charge (secondary ID)
   */
  code: string;
  content_type?: "vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic";
  /**
   * User locked?
   */
  user_locked?: boolean;
  month?: string;
  created_at: string;
  updated_at: string;
  source?: string;
  source_id?: string;
  source_category?: string;
  earning?: Money;
  tax_withheld?: Money;
  paid?: Money;
  tax_thousands?: number;
  invoice_id?: string;
  invoice_code?: string;
  paid_at?: string;
  state: "unpaid" | "pending" | "paid";
  region?: "US" | "Intl";
  has_notes: boolean;
  manual: boolean;
  tax_country_id?: string;
  edited?: boolean;
}

/**
 * Finance_AccountSummary model
 */
export interface FinanceAccountSummary {
  id: string;
  user_id?: string;
  email?: string;
  program?: string;
  account_status?: string;
  image_tax_thousands?: number;
  video_tax_thousands?: number;
  tax_country_id?: string;
  generated_at?: string;
  payment_account?: string;
  payment_account_state?: string;
  payment_method?: string;
  payment_threshold_cents?: number;
  payoneer_status?: string;
  payoneer_payee_id?: string;
  unpaid?: Money;
  pending?: Money;
  paid?: Money;
  total?: Money;
  last_payment_at?: string;
  payment_eligible?: boolean;
  payout_blocked_by_admin?: boolean;
  user?: User;
  account_closed?: boolean;
  tax_form_link?: string;
  form_submission_count?: number;
  tax_form_updated_at?: string;
  tax_form_status?: string;
}

/**
 * Finance_Payout model
 */
export interface FinancePayout {
  id: string;
  batch_id: string;
  payment_method: string;
  state: string;
  total_invoice_count: number;
  paid_invoice_count: number;
  unpaid_invoice_count: number;
  amount: Money;
  fees: Money;
  created_at: string;
  error_count: number;
  failures?: FinanceInvoicePayoutFailure[];
}

/**
 * Update a note associated to a charge or invoice record
 */
export interface PatchApiV1FinanceNotableNotableIdNotesId {
  /**
   * Note text
   */
  body?: string;
  active?: boolean;
}

/**
 * Finance_Note model
 */
export interface FinanceNote {
  id: string;
  user?: User;
  body?: string;
  created_at?: string;
  updated_at?: string;
}

/**
 * Create a new note. Format: charge/:charge_id/notes or invoice/:invoice_id/notes
 */
export interface PostApiV1FinanceNotableNotableIdNotes {
  /**
   * User Import GUID
   */
  user_id: string;
  /**
   * Note text
   */
  body: string;
}

/**
 * Check invoice payments via csv upload prior to calling /confirm_payments
 */
export interface PostApiV1FinanceInvoicesInvoicePayments {
  csv_file: any;
}

/**
 * Finance_BulkOperation model
 */
export interface FinanceBulkOperation {
  id: string;
  user_id?: string;
  state?: "pending" | "queueing" | "working" | "done";
  message?: string;
  failed?: boolean;
  result?: string;
}

/**
 * Finance_InvoiceStateTotals model
 */
export interface FinanceInvoiceStateTotals {
  pending?: FinanceInvoiceTotals;
  in_progress?: FinanceInvoiceTotals;
  complete?: FinanceInvoiceTotals;
  approved?: FinanceInvoiceTotals;
  payment_processing?: FinanceInvoiceTotals;
  failed?: FinanceInvoiceTotals;
  canceled?: FinanceInvoiceTotals;
  total?: FinanceInvoiceTotals;
}

export interface FinanceInvoiceTotals {
  earning: Money;
  tax_withheld: Money;
  deposit: Money;
  carryover: Money;
}

/**
 * update a charge
 */
export interface PatchApiV1FinanceChargesId {
  /**
   * User Import GUID
   */
  user_id: string;
  content_type?: "vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic";
  /**
   * Billing period month
   */
  month?: string;
  /**
   * Charge source
   */
  source?: string;
  /**
   * Source ID (e.g. referral payout id)
   */
  source_id?: string;
  /**
   * Source category
   */
  source_category?: string;
  earning?: {
  cents?: number;
  currency_iso?: string;
};
  /**
   * Region
   */
  region?: "US" | "Intl";
}

/**
 * create a new charge
 */
export interface PostApiV1FinanceCharges {
  /**
   * User Import GUID
   */
  user_id: string;
  content_type?: "vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic";
  /**
   * Billing period month
   */
  month?: string;
  /**
   * Charge source
   */
  source?: string;
  /**
   * Source ID (e.g. referral payout id)
   */
  source_id?: string;
  /**
   * Source category
   */
  source_category?: string;
  earning?: {
  cents?: number;
  currency_iso?: string;
};
  /**
   * Region
   */
  region?: "US" | "Intl";
}

/**
 * Finance_ChargeStateTotals model
 */
export interface FinanceChargeStateTotals {
  unpaid?: FinanceChargeTotals;
  pending?: FinanceChargeTotals;
  paid?: FinanceChargeTotals;
  total?: FinanceChargeTotals;
}

export interface FinanceChargeTotals {
  earning: Money;
  tax_withheld: Money;
  paid: Money;
}

/**
 * Finance_AccountEarningsSummary model
 */
export interface FinanceAccountEarningsSummary {
  /**
   * Array of objects with earning key and amount
   */
  summary_amounts?: FinanceEarningsSummaryItem[];
}

export interface FinanceEarningsSummaryItem {
  key?: string;
  amount?: Money;
}

/**
 * Test_Response model
 */
export interface TestResponse {
  clear_cache?: boolean;
}

/**
 * Test_SessionResponse model
 */
export interface TestSessionResponse {
  clear_cache: boolean;
  user: Session;
}

/**
 * Challenge model
 */
export interface Challenge {
  id: string;
  keyword: string;
  name: string;
  details_url?: string;
  start_date?: string;
  expiry_date?: string;
  earnings: string;
  published_state: string;
  minimum_approved_files: number;
  complete_badge?: string;
  incomplete_badge?: string;
}

/**
 * ContributorNotification model
 */
export interface ContributorNotification {
  id: string;
  title?: string;
  button_text?: string;
  /**
   * more information here
   */
  url?: string;
  image_url: string;
  /**
   * whether the url should open in a new tab
   */
  new_tab?: boolean;
}

/**
 * ContributorStats model
 */
export interface ContributorStats {
  approved?: ContributorApprovedResources;
  downloads?: ContributorTotalDownloads;
  views?: ContributorTotalViews;
}

export interface ContributorApprovedResources {
  approved?: number;
  prev_approved?: number;
  approved_variance?: number;
}

export interface ContributorTotalDownloads {
  downloads?: number;
  prev_downloads?: number;
  download_variance?: number;
}

export interface ContributorTotalViews {
  views?: number;
  prev_views?: number;
  view_variance?: number;
}

/**
 * ProhibitedTerm model
 */
export interface ProhibitedTerm {
  term: string;
  id: string;
}

/**
 * Bulk search and replace
 */
export interface PostApiV1ReplaceAll {
  terms: {
  original_term: string;
  replacement_term: string;
};
}

/**
 * Upserts a tax questionnaire for the authenticated user
 */
export interface PutApiV1TaxQuestionnaire {
  us_person?: boolean;
  country?: string;
  entity_type?: string;
  applicable_tax_form?: "W9" | "W-8BEN" | "W-8BEN-E" | "W-8ECI" | "W-8EXP" | "W-8IMY";
  finished?: boolean;
}

export type PostApiV1TestUsersReindexResourcesRequestBody = void;

export type GetApiV1ArticlesProps = Omit<GetProps<Article[], unknown, void, void>, "path">;

/**
 * The first 4 articles
 */
export const GetApiV1Articles = (props: GetApiV1ArticlesProps) => (
  <Get<Article[], unknown, void, void>
    path={`/api/v1/articles`}
    
    {...props}
  />
);

export type UseGetApiV1ArticlesProps = Omit<UseGetProps<Article[], unknown, void, void>, "path">;

/**
 * The first 4 articles
 */
export const useGetApiV1Articles = (props: UseGetApiV1ArticlesProps) => useGet<Article[], unknown, void, void>(`/api/v1/articles`, props);


export type PostApiV1BulkOperationsSubmitProps = Omit<MutateProps<BulkOperation, unknown, void, void, void>, "path" | "verb">;

/**
 * submit all of a user's valid resources
 */
export const PostApiV1BulkOperationsSubmit = (props: PostApiV1BulkOperationsSubmitProps) => (
  <Mutate<BulkOperation, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/bulk_operations/submit`}
    
    {...props}
  />
);

export type UsePostApiV1BulkOperationsSubmitProps = Omit<UseMutateProps<BulkOperation, unknown, void, void, void>, "path" | "verb">;

/**
 * submit all of a user's valid resources
 */
export const usePostApiV1BulkOperationsSubmit = (props: UsePostApiV1BulkOperationsSubmitProps) => useMutate<BulkOperation, unknown, void, void, void>("POST", `/api/v1/bulk_operations/submit`, props);


export type GetApiV1BulkOperationsProps = Omit<GetProps<BulkOperation[], unknown, void, void>, "path">;

/**
 * get user's active bulk operations
 */
export const GetApiV1BulkOperations = (props: GetApiV1BulkOperationsProps) => (
  <Get<BulkOperation[], unknown, void, void>
    path={`/api/v1/bulk_operations`}
    
    {...props}
  />
);

export type UseGetApiV1BulkOperationsProps = Omit<UseGetProps<BulkOperation[], unknown, void, void>, "path">;

/**
 * get user's active bulk operations
 */
export const useGetApiV1BulkOperations = (props: UseGetApiV1BulkOperationsProps) => useGet<BulkOperation[], unknown, void, void>(`/api/v1/bulk_operations`, props);


export interface GetApiV1BulkOperationsIdPathParams {
  id: string
}

export type GetApiV1BulkOperationsIdProps = Omit<GetProps<BulkOperation, unknown, void, GetApiV1BulkOperationsIdPathParams>, "path"> & GetApiV1BulkOperationsIdPathParams;

/**
 * get an individual bulk operation
 */
export const GetApiV1BulkOperationsId = ({id, ...props}: GetApiV1BulkOperationsIdProps) => (
  <Get<BulkOperation, unknown, void, GetApiV1BulkOperationsIdPathParams>
    path={`/api/v1/bulk_operations/${id}`}
    
    {...props}
  />
);

export type UseGetApiV1BulkOperationsIdProps = Omit<UseGetProps<BulkOperation, unknown, void, GetApiV1BulkOperationsIdPathParams>, "path"> & GetApiV1BulkOperationsIdPathParams;

/**
 * get an individual bulk operation
 */
export const useGetApiV1BulkOperationsId = ({id, ...props}: UseGetApiV1BulkOperationsIdProps) => useGet<BulkOperation, unknown, void, GetApiV1BulkOperationsIdPathParams>((paramsInPath: GetApiV1BulkOperationsIdPathParams) => `/api/v1/bulk_operations/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1ContentGapsByContentTypeContentTypeQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export interface GetApiV1ContentGapsByContentTypeContentTypePathParams {
  content_type: string
}

export type GetApiV1ContentGapsByContentTypeContentTypeProps = Omit<GetProps<ContentGap[], unknown, GetApiV1ContentGapsByContentTypeContentTypeQueryParams, GetApiV1ContentGapsByContentTypeContentTypePathParams>, "path"> & GetApiV1ContentGapsByContentTypeContentTypePathParams;

/**
 * content_gaps by content_type
 */
export const GetApiV1ContentGapsByContentTypeContentType = ({content_type, ...props}: GetApiV1ContentGapsByContentTypeContentTypeProps) => (
  <Get<ContentGap[], unknown, GetApiV1ContentGapsByContentTypeContentTypeQueryParams, GetApiV1ContentGapsByContentTypeContentTypePathParams>
    path={`/api/v1/content_gaps/by_content_type/${content_type}`}
    
    {...props}
  />
);

export type UseGetApiV1ContentGapsByContentTypeContentTypeProps = Omit<UseGetProps<ContentGap[], unknown, GetApiV1ContentGapsByContentTypeContentTypeQueryParams, GetApiV1ContentGapsByContentTypeContentTypePathParams>, "path"> & GetApiV1ContentGapsByContentTypeContentTypePathParams;

/**
 * content_gaps by content_type
 */
export const useGetApiV1ContentGapsByContentTypeContentType = ({content_type, ...props}: UseGetApiV1ContentGapsByContentTypeContentTypeProps) => useGet<ContentGap[], unknown, GetApiV1ContentGapsByContentTypeContentTypeQueryParams, GetApiV1ContentGapsByContentTypeContentTypePathParams>((paramsInPath: GetApiV1ContentGapsByContentTypeContentTypePathParams) => `/api/v1/content_gaps/by_content_type/${paramsInPath.content_type}`, {  pathParams: { content_type }, ...props });


export interface GetApiV1ContributorsUniqueResourceDownloadCountQueryParams {
  user_import_guid: string;
  start_date: string;
  end_date: string;
  content_types?: string[];
}

export type GetApiV1ContributorsUniqueResourceDownloadCountProps = Omit<GetProps<ResponsesSingleItemCount[], unknown, GetApiV1ContributorsUniqueResourceDownloadCountQueryParams, void>, "path">;

/**
 * Count of unique resource downloads
 */
export const GetApiV1ContributorsUniqueResourceDownloadCount = (props: GetApiV1ContributorsUniqueResourceDownloadCountProps) => (
  <Get<ResponsesSingleItemCount[], unknown, GetApiV1ContributorsUniqueResourceDownloadCountQueryParams, void>
    path={`/api/v1/contributors/unique_resource_download_count`}
    
    {...props}
  />
);

export type UseGetApiV1ContributorsUniqueResourceDownloadCountProps = Omit<UseGetProps<ResponsesSingleItemCount[], unknown, GetApiV1ContributorsUniqueResourceDownloadCountQueryParams, void>, "path">;

/**
 * Count of unique resource downloads
 */
export const useGetApiV1ContributorsUniqueResourceDownloadCount = (props: UseGetApiV1ContributorsUniqueResourceDownloadCountProps) => useGet<ResponsesSingleItemCount[], unknown, GetApiV1ContributorsUniqueResourceDownloadCountQueryParams, void>(`/api/v1/contributors/unique_resource_download_count`, props);


export interface GetApiV1ContributorsDownloadsAndEarningsQueryParams {
  user_import_guid: string;
  period: "mtd" | "month" | "quarter" | "half_year" | "ytd" | "lifetime";
  start_date?: string;
  end_date?: string;
  content_types?: string[];
}

export type GetApiV1ContributorsDownloadsAndEarningsProps = Omit<GetProps<DownloadsAndEarnings[], unknown, GetApiV1ContributorsDownloadsAndEarningsQueryParams, void>, "path">;

/**
 * Contributor daily downloads and earnings data
 */
export const GetApiV1ContributorsDownloadsAndEarnings = (props: GetApiV1ContributorsDownloadsAndEarningsProps) => (
  <Get<DownloadsAndEarnings[], unknown, GetApiV1ContributorsDownloadsAndEarningsQueryParams, void>
    path={`/api/v1/contributors/downloads_and_earnings`}
    
    {...props}
  />
);

export type UseGetApiV1ContributorsDownloadsAndEarningsProps = Omit<UseGetProps<DownloadsAndEarnings[], unknown, GetApiV1ContributorsDownloadsAndEarningsQueryParams, void>, "path">;

/**
 * Contributor daily downloads and earnings data
 */
export const useGetApiV1ContributorsDownloadsAndEarnings = (props: UseGetApiV1ContributorsDownloadsAndEarningsProps) => useGet<DownloadsAndEarnings[], unknown, GetApiV1ContributorsDownloadsAndEarningsQueryParams, void>(`/api/v1/contributors/downloads_and_earnings`, props);


export type GetApiV1ContributorEarningsByContentTypeProps = Omit<GetProps<ContributorEarningsByContentType[], unknown, void, void>, "path">;

/**
 * Show earnings for this user, broken down by content type
 */
export const GetApiV1ContributorEarningsByContentType = (props: GetApiV1ContributorEarningsByContentTypeProps) => (
  <Get<ContributorEarningsByContentType[], unknown, void, void>
    path={`/api/v1/contributor_earnings_by_content_type`}
    
    {...props}
  />
);

export type UseGetApiV1ContributorEarningsByContentTypeProps = Omit<UseGetProps<ContributorEarningsByContentType[], unknown, void, void>, "path">;

/**
 * Show earnings for this user, broken down by content type
 */
export const useGetApiV1ContributorEarningsByContentType = (props: UseGetApiV1ContributorEarningsByContentTypeProps) => useGet<ContributorEarningsByContentType[], unknown, void, void>(`/api/v1/contributor_earnings_by_content_type`, props);


export type GetApiV1ContributorEarningsByLicenseTypeProps = Omit<GetProps<ContributorEarningsByLicenseType[], unknown, void, void>, "path">;

/**
 * Show earnings for this user, broken down by license type
 */
export const GetApiV1ContributorEarningsByLicenseType = (props: GetApiV1ContributorEarningsByLicenseTypeProps) => (
  <Get<ContributorEarningsByLicenseType[], unknown, void, void>
    path={`/api/v1/contributor_earnings_by_license_type`}
    
    {...props}
  />
);

export type UseGetApiV1ContributorEarningsByLicenseTypeProps = Omit<UseGetProps<ContributorEarningsByLicenseType[], unknown, void, void>, "path">;

/**
 * Show earnings for this user, broken down by license type
 */
export const useGetApiV1ContributorEarningsByLicenseType = (props: UseGetApiV1ContributorEarningsByLicenseTypeProps) => useGet<ContributorEarningsByLicenseType[], unknown, void, void>(`/api/v1/contributor_earnings_by_license_type`, props);


export type GetApiV1ContributorTopKeywordsProps = Omit<GetProps<ContributorTopKeywords[], unknown, void, void>, "path">;

/**
 * list of the top keywords for a contributor
 */
export const GetApiV1ContributorTopKeywords = (props: GetApiV1ContributorTopKeywordsProps) => (
  <Get<ContributorTopKeywords[], unknown, void, void>
    path={`/api/v1/contributor_top_keywords`}
    
    {...props}
  />
);

export type UseGetApiV1ContributorTopKeywordsProps = Omit<UseGetProps<ContributorTopKeywords[], unknown, void, void>, "path">;

/**
 * list of the top keywords for a contributor
 */
export const useGetApiV1ContributorTopKeywords = (props: UseGetApiV1ContributorTopKeywordsProps) => useGet<ContributorTopKeywords[], unknown, void, void>(`/api/v1/contributor_top_keywords`, props);


export interface GetApiV1ContributorTopResourcesQueryParams {
  content_type?: string;
  program_type?: string;
  period?: "month" | "week" | "current_year" | "all_time" | "prev_six_months" | "prev_three_months";
  metric_type?: "downloads" | "earnings" | "views";
  license_type?: "all" | "editorial" | "pro" | "free";
}

export type GetApiV1ContributorTopResourcesProps = Omit<GetProps<ContributorTopResource[], unknown, GetApiV1ContributorTopResourcesQueryParams, void>, "path">;

/**
 * list the top resources for the current user
 */
export const GetApiV1ContributorTopResources = (props: GetApiV1ContributorTopResourcesProps) => (
  <Get<ContributorTopResource[], unknown, GetApiV1ContributorTopResourcesQueryParams, void>
    path={`/api/v1/contributor_top_resources`}
    
    {...props}
  />
);

export type UseGetApiV1ContributorTopResourcesProps = Omit<UseGetProps<ContributorTopResource[], unknown, GetApiV1ContributorTopResourcesQueryParams, void>, "path">;

/**
 * list the top resources for the current user
 */
export const useGetApiV1ContributorTopResources = (props: UseGetApiV1ContributorTopResourcesProps) => useGet<ContributorTopResource[], unknown, GetApiV1ContributorTopResourcesQueryParams, void>(`/api/v1/contributor_top_resources`, props);


export type GetApiV1CuratedKeywordsProps = Omit<GetProps<CuratedKeyword[], unknown, void, void>, "path">;

/**
 * Get curated keywords
 */
export const GetApiV1CuratedKeywords = (props: GetApiV1CuratedKeywordsProps) => (
  <Get<CuratedKeyword[], unknown, void, void>
    path={`/api/v1/curated_keywords`}
    
    {...props}
  />
);

export type UseGetApiV1CuratedKeywordsProps = Omit<UseGetProps<CuratedKeyword[], unknown, void, void>, "path">;

/**
 * Get curated keywords
 */
export const useGetApiV1CuratedKeywords = (props: UseGetApiV1CuratedKeywordsProps) => useGet<CuratedKeyword[], unknown, void, void>(`/api/v1/curated_keywords`, props);


export interface GetApiV1CuratedResourcesQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export type GetApiV1CuratedResourcesProps = Omit<GetProps<Resource[], unknown, GetApiV1CuratedResourcesQueryParams, void>, "path">;

/**
 * list curated resources in order
 */
export const GetApiV1CuratedResources = (props: GetApiV1CuratedResourcesProps) => (
  <Get<Resource[], unknown, GetApiV1CuratedResourcesQueryParams, void>
    path={`/api/v1/curated_resources`}
    
    {...props}
  />
);

export type UseGetApiV1CuratedResourcesProps = Omit<UseGetProps<Resource[], unknown, GetApiV1CuratedResourcesQueryParams, void>, "path">;

/**
 * list curated resources in order
 */
export const useGetApiV1CuratedResources = (props: UseGetApiV1CuratedResourcesProps) => useGet<Resource[], unknown, GetApiV1CuratedResourcesQueryParams, void>(`/api/v1/curated_resources`, props);


export type PostApiV1ResourcesExportApprovedProps = Omit<MutateProps<void, unknown, void, void, void>, "path" | "verb">;

/**
 * Export resources that were published over 90 days ago to a CSV
 */
export const PostApiV1ResourcesExportApproved = (props: PostApiV1ResourcesExportApprovedProps) => (
  <Mutate<void, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/resources/export_approved`}
    
    {...props}
  />
);

export type UsePostApiV1ResourcesExportApprovedProps = Omit<UseMutateProps<void, unknown, void, void, void>, "path" | "verb">;

/**
 * Export resources that were published over 90 days ago to a CSV
 */
export const usePostApiV1ResourcesExportApproved = (props: UsePostApiV1ResourcesExportApprovedProps) => useMutate<void, unknown, void, void, void>("POST", `/api/v1/resources/export_approved`, props);


export type PatchApiV1ResourcesVerifiedReleasesProps = Omit<MutateProps<BulkOperation, unknown, void, void, void>, "path" | "verb">;

/**
 * Update verified releases on reviewer resources batch
 */
export const PatchApiV1ResourcesVerifiedReleases = (props: PatchApiV1ResourcesVerifiedReleasesProps) => (
  <Mutate<BulkOperation, unknown, void, void, void>
    verb="PATCH"
    path={`/api/v1/resources/verified_releases`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourcesVerifiedReleasesProps = Omit<UseMutateProps<BulkOperation, unknown, void, void, void>, "path" | "verb">;

/**
 * Update verified releases on reviewer resources batch
 */
export const usePatchApiV1ResourcesVerifiedReleases = (props: UsePatchApiV1ResourcesVerifiedReleasesProps) => useMutate<BulkOperation, unknown, void, void, void>("PATCH", `/api/v1/resources/verified_releases`, props);


export type PostApiV1ResourcesMakeAllChangesProps = Omit<MutateProps<BulkOperation, unknown, void, void, void>, "path" | "verb">;

/**
 * Restart all resources that need corrections
 */
export const PostApiV1ResourcesMakeAllChanges = (props: PostApiV1ResourcesMakeAllChangesProps) => (
  <Mutate<BulkOperation, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/resources/make_all_changes`}
    
    {...props}
  />
);

export type UsePostApiV1ResourcesMakeAllChangesProps = Omit<UseMutateProps<BulkOperation, unknown, void, void, void>, "path" | "verb">;

/**
 * Restart all resources that need corrections
 */
export const usePostApiV1ResourcesMakeAllChanges = (props: UsePostApiV1ResourcesMakeAllChangesProps) => useMutate<BulkOperation, unknown, void, void, void>("POST", `/api/v1/resources/make_all_changes`, props);


export type PatchApiV1ResourcesAcceptAllConditionsProps = Omit<MutateProps<ResponsesBulkUpdateSuccess, unknown, void, PatchApiV1ResourcesAcceptAllConditions, void>, "path" | "verb">;

/**
 * Accept all resources as free and/or editorial
 */
export const PatchApiV1ResourcesAcceptAllConditions = (props: PatchApiV1ResourcesAcceptAllConditionsProps) => (
  <Mutate<ResponsesBulkUpdateSuccess, unknown, void, PatchApiV1ResourcesAcceptAllConditions, void>
    verb="PATCH"
    path={`/api/v1/resources/accept_all_conditions`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourcesAcceptAllConditionsProps = Omit<UseMutateProps<ResponsesBulkUpdateSuccess, unknown, void, PatchApiV1ResourcesAcceptAllConditions, void>, "path" | "verb">;

/**
 * Accept all resources as free and/or editorial
 */
export const usePatchApiV1ResourcesAcceptAllConditions = (props: UsePatchApiV1ResourcesAcceptAllConditionsProps) => useMutate<ResponsesBulkUpdateSuccess, unknown, void, PatchApiV1ResourcesAcceptAllConditions, void>("PATCH", `/api/v1/resources/accept_all_conditions`, props);


export type GetApiV1ResourcesNotApprovedCountsProps = Omit<GetProps<ResponsesNotApprovedCounts, unknown, void, void>, "path">;

/**
 * Counts for resources in not approved that are correctable
 */
export const GetApiV1ResourcesNotApprovedCounts = (props: GetApiV1ResourcesNotApprovedCountsProps) => (
  <Get<ResponsesNotApprovedCounts, unknown, void, void>
    path={`/api/v1/resources/not_approved_counts`}
    
    {...props}
  />
);

export type UseGetApiV1ResourcesNotApprovedCountsProps = Omit<UseGetProps<ResponsesNotApprovedCounts, unknown, void, void>, "path">;

/**
 * Counts for resources in not approved that are correctable
 */
export const useGetApiV1ResourcesNotApprovedCounts = (props: UseGetApiV1ResourcesNotApprovedCountsProps) => useGet<ResponsesNotApprovedCounts, unknown, void, void>(`/api/v1/resources/not_approved_counts`, props);


export interface GetApiV1ResourcesCountsQueryParams {
  /**
   * Array of resource states
   */
  states: string[];
}

export type GetApiV1ResourcesCountsProps = Omit<GetProps<ResponsesResourceCounts, unknown, GetApiV1ResourcesCountsQueryParams, void>, "path">;

/**
 * Get resource counts for a given list of states. These states are not real AASM states, they are from Resources::Searchable
 */
export const GetApiV1ResourcesCounts = (props: GetApiV1ResourcesCountsProps) => (
  <Get<ResponsesResourceCounts, unknown, GetApiV1ResourcesCountsQueryParams, void>
    path={`/api/v1/resources/counts`}
    
    {...props}
  />
);

export type UseGetApiV1ResourcesCountsProps = Omit<UseGetProps<ResponsesResourceCounts, unknown, GetApiV1ResourcesCountsQueryParams, void>, "path">;

/**
 * Get resource counts for a given list of states. These states are not real AASM states, they are from Resources::Searchable
 */
export const useGetApiV1ResourcesCounts = (props: UseGetApiV1ResourcesCountsProps) => useGet<ResponsesResourceCounts, unknown, GetApiV1ResourcesCountsQueryParams, void>(`/api/v1/resources/counts`, props);


export type PostApiV1ResourcesCheckUniqueTitlesProps = Omit<MutateProps<ResponsesTitleUniqueness, unknown, void, PostApiV1ResourcesCheckUniqueTitles, void>, "path" | "verb">;

/**
 * Check if resource titles are unique for the current user
 */
export const PostApiV1ResourcesCheckUniqueTitles = (props: PostApiV1ResourcesCheckUniqueTitlesProps) => (
  <Mutate<ResponsesTitleUniqueness, unknown, void, PostApiV1ResourcesCheckUniqueTitles, void>
    verb="POST"
    path={`/api/v1/resources/check_unique_titles`}
    
    {...props}
  />
);

export type UsePostApiV1ResourcesCheckUniqueTitlesProps = Omit<UseMutateProps<ResponsesTitleUniqueness, unknown, void, PostApiV1ResourcesCheckUniqueTitles, void>, "path" | "verb">;

/**
 * Check if resource titles are unique for the current user
 */
export const usePostApiV1ResourcesCheckUniqueTitles = (props: UsePostApiV1ResourcesCheckUniqueTitlesProps) => useMutate<ResponsesTitleUniqueness, unknown, void, PostApiV1ResourcesCheckUniqueTitles, void>("POST", `/api/v1/resources/check_unique_titles`, props);


export type DeleteApiV1ResourcesProps = Omit<MutateProps<ResponsesBulkUpdateSuccess, unknown, void, void, void>, "path" | "verb">;

/**
 * Bulk Delete resources
 */
export const DeleteApiV1Resources = (props: DeleteApiV1ResourcesProps) => (
  <Mutate<ResponsesBulkUpdateSuccess, unknown, void, void, void>
    verb="DELETE"
    path={`/api/v1/resources`}
    
    {...props}
  />
);

export type UseDeleteApiV1ResourcesProps = Omit<UseMutateProps<ResponsesBulkUpdateSuccess, unknown, void, void, void>, "path" | "verb">;

/**
 * Bulk Delete resources
 */
export const useDeleteApiV1Resources = (props: UseDeleteApiV1ResourcesProps) => useMutate<ResponsesBulkUpdateSuccess, unknown, void, void, void>("DELETE", `/api/v1/resources`, {   ...props });


export type PatchApiV1ResourcesProps = Omit<MutateProps<ResponsesBulkUpdateSuccess, unknown, void, PatchApiV1Resources, void>, "path" | "verb">;

/**
 * Bulk Update resources
 */
export const PatchApiV1Resources = (props: PatchApiV1ResourcesProps) => (
  <Mutate<ResponsesBulkUpdateSuccess, unknown, void, PatchApiV1Resources, void>
    verb="PATCH"
    path={`/api/v1/resources`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourcesProps = Omit<UseMutateProps<ResponsesBulkUpdateSuccess, unknown, void, PatchApiV1Resources, void>, "path" | "verb">;

/**
 * Bulk Update resources
 */
export const usePatchApiV1Resources = (props: UsePatchApiV1ResourcesProps) => useMutate<ResponsesBulkUpdateSuccess, unknown, void, PatchApiV1Resources, void>("PATCH", `/api/v1/resources`, props);


export interface GetApiV1ResourcesQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Filter resources by resource ids
   */
  ids?: string[];
  /**
   * Filter resources by state
   */
  state?: ("started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "active" | "inactive")[];
  /**
   * Filter resources by content type
   */
  content_type?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  /**
   * Filter resources by program type
   */
  program_type?: "ppd" | "wo" | "ppd_pro" | "ppd_free";
  /**
   * Filter resources by license
   */
  license?: ("free" | "pro" | "editorial")[];
  /**
   * Sort resources by this field
   */
  sort_field?: "created_at" | "lifetime_downloads" | "updated_at" | "title";
  /**
   * Filter resources having releases or not
   */
  has_release?: boolean;
  /**
   * Filter resources having comments or not
   */
  has_comments?: boolean;
  /**
   * Sort resources in this direction
   */
  sort_direction?: "asc" | "desc";
  /**
   * Array of user ids (guid)
   */
  user_ids?: string[];
  /**
   * Only return unlocked resources
   */
  unlocked?: boolean;
  /**
   * only return resources where any corrections needed can/cannot be accepted automatically
   */
  accept_changes?: boolean;
  /**
   * only return resources where the review is assigned to the current user
   */
  review_assigned_to_me?: boolean;
  /**
   * Filter resources by file types
   */
  file_type?: ("png" | "psd" | "eps" | "jpg" | "mp4" | "mov" | "r3d" | "zip")[];
  /**
   * Filter resources by category_ids
   */
  category_ids?: string[];
  /**
   * Show the keyword for the assignment if the resource has one
   */
  with_assignment_keyword?: boolean;
  /**
   * Show the boolean if all releases have been verfied
   */
  with_release_verification?: boolean;
  /**
   * Show the count of admin comments
   */
  with_admin_comments_count?: boolean;
  /**
   * Show the count of state changes
   */
  with_history_count?: boolean;
  /**
   * Filter resources by whether ai generated
   */
  ai_generated?: boolean;
  /**
   * Filter resources by whether they have an SVG source file
   */
  has_svg?: boolean;
  /**
   * Filter by auto_submitted
   */
  auto_submitted?: boolean;
  /**
   * Filter by magic_metadata
   */
  magic_metadata?: boolean;
  /**
   * Filter by auto distribution
   */
  auto_distribute?: boolean;
  /**
   * Filter by created_at: [start, end]
   */
  created_at?: string[];
  /**
   * Filter by approved_at: [start, end]
   */
  approved_at?: string[];
  /**
   * Filter by reviewed_at: [start, end]
   */
  reviewed_at?: string[];
  /**
   * Filter resources by whether there are duplicate source files
   */
  duplicate?: string[];
}

export type GetApiV1ResourcesProps = Omit<GetProps<Resource[], unknown, GetApiV1ResourcesQueryParams, void>, "path">;

/**
 * Lists resources accessible by the current user
 */
export const GetApiV1Resources = (props: GetApiV1ResourcesProps) => (
  <Get<Resource[], unknown, GetApiV1ResourcesQueryParams, void>
    path={`/api/v1/resources`}
    
    {...props}
  />
);

export type UseGetApiV1ResourcesProps = Omit<UseGetProps<Resource[], unknown, GetApiV1ResourcesQueryParams, void>, "path">;

/**
 * Lists resources accessible by the current user
 */
export const useGetApiV1Resources = (props: UseGetApiV1ResourcesProps) => useGet<Resource[], unknown, GetApiV1ResourcesQueryParams, void>(`/api/v1/resources`, props);


export type PostApiV1ResourcesTransferProps = Omit<MutateProps<void, unknown, void, PostApiV1ResourcesTransfer, void>, "path" | "verb">;

/**
 * Transfer a set of given resources to other marketplaces
 */
export const PostApiV1ResourcesTransfer = (props: PostApiV1ResourcesTransferProps) => (
  <Mutate<void, unknown, void, PostApiV1ResourcesTransfer, void>
    verb="POST"
    path={`/api/v1/resources/transfer`}
    
    {...props}
  />
);

export type UsePostApiV1ResourcesTransferProps = Omit<UseMutateProps<void, unknown, void, PostApiV1ResourcesTransfer, void>, "path" | "verb">;

/**
 * Transfer a set of given resources to other marketplaces
 */
export const usePostApiV1ResourcesTransfer = (props: UsePostApiV1ResourcesTransferProps) => useMutate<void, unknown, void, PostApiV1ResourcesTransfer, void>("POST", `/api/v1/resources/transfer`, props);


export interface GetApiV1ResourcesSearchQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  search_field?: "keywords" | "title" | "filename";
  query?: string;
  /**
   * Filter resources by license
   */
  license?: ("free" | "pro" | "editorial")[];
  /**
   * Filter resources by category_id
   */
  category_id?: number[];
  /**
   * Filter by marketplaces and transfer statuses, e.g [{ 'marketplace_<status>': [<marketplace_id>] }, ...]
   */
  marketplace_statuses?: any;
  /**
   * Fetch resources that were uploaded after this date (YYYY-MM-DD)
   */
  created_at_start?: string;
  /**
   * Fetch resources that were uploaded before this date (YYYY-MM-DD)
   */
  created_at_end?: string;
  /**
   * Fetch resources that were approved before this date (YYYY-MM-DD)
   */
  approved_at_start?: string;
  /**
   * Fetch resources that were approved before this date (YYYY-MM-DD)
   */
  approved_at_end?: string;
  /**
   * Fetch resources that were reviewed before this date (YYYY-MM-DD)
   */
  reviewed_at_start?: string;
  /**
   * Fetch resources that were reviewed before this date (YYYY-MM-DD)
   */
  reviewed_at_end?: string;
  /**
   * Filter by auto_submitted
   */
  auto_submitted?: boolean;
  /**
   * Filter by magic_metadata
   */
  magic_metadata?: boolean;
  /**
   * Filter resources by whether they have release models
   */
  release_status?: boolean;
  /**
   * Filter resources by state
   */
  state?: ("started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "active" | "inactive")[];
  /**
   * Filter resources by file type
   */
  file_type?: ("png" | "psd" | "eps" | "jpg" | "mp4" | "mov" | "r3d" | "zip")[];
  /**
   * Filter by auto_distribute
   */
  auto_distribute?: boolean;
}

export type GetApiV1ResourcesSearchProps = Omit<GetProps<ResourcesSearchResults, unknown, GetApiV1ResourcesSearchQueryParams, void>, "path">;

/**
 * Search contributor resources
 */
export const GetApiV1ResourcesSearch = (props: GetApiV1ResourcesSearchProps) => (
  <Get<ResourcesSearchResults, unknown, GetApiV1ResourcesSearchQueryParams, void>
    path={`/api/v1/resources/search`}
    
    {...props}
  />
);

export type UseGetApiV1ResourcesSearchProps = Omit<UseGetProps<ResourcesSearchResults, unknown, GetApiV1ResourcesSearchQueryParams, void>, "path">;

/**
 * Search contributor resources
 */
export const useGetApiV1ResourcesSearch = (props: UseGetApiV1ResourcesSearchProps) => useGet<ResourcesSearchResults, unknown, GetApiV1ResourcesSearchQueryParams, void>(`/api/v1/resources/search`, props);


export interface GetApiV1ResourcesIdValidateReleasesQueryParams {
  /**
   * Array of resource states
   */
  release_ids: string[];
}

export interface GetApiV1ResourcesIdValidateReleasesPathParams {
  /**
   * Resource import_guid
   */
  id: string
}

export type GetApiV1ResourcesIdValidateReleasesProps = Omit<GetProps<void, unknown, GetApiV1ResourcesIdValidateReleasesQueryParams, GetApiV1ResourcesIdValidateReleasesPathParams>, "path"> & GetApiV1ResourcesIdValidateReleasesPathParams;

/**
 * Validate Releases on Resources
 */
export const GetApiV1ResourcesIdValidateReleases = ({id, ...props}: GetApiV1ResourcesIdValidateReleasesProps) => (
  <Get<void, unknown, GetApiV1ResourcesIdValidateReleasesQueryParams, GetApiV1ResourcesIdValidateReleasesPathParams>
    path={`/api/v1/resources/${id}/validate_releases`}
    
    {...props}
  />
);

export type UseGetApiV1ResourcesIdValidateReleasesProps = Omit<UseGetProps<void, unknown, GetApiV1ResourcesIdValidateReleasesQueryParams, GetApiV1ResourcesIdValidateReleasesPathParams>, "path"> & GetApiV1ResourcesIdValidateReleasesPathParams;

/**
 * Validate Releases on Resources
 */
export const useGetApiV1ResourcesIdValidateReleases = ({id, ...props}: UseGetApiV1ResourcesIdValidateReleasesProps) => useGet<void, unknown, GetApiV1ResourcesIdValidateReleasesQueryParams, GetApiV1ResourcesIdValidateReleasesPathParams>((paramsInPath: GetApiV1ResourcesIdValidateReleasesPathParams) => `/api/v1/resources/${paramsInPath.id}/validate_releases`, {  pathParams: { id }, ...props });


export interface PatchApiV1ResourcesIdRetryExportPathParams {
  /**
   * Resource import_guid
   */
  id: string
}

export type PatchApiV1ResourcesIdRetryExportProps = Omit<MutateProps<Resource, unknown, void, void, PatchApiV1ResourcesIdRetryExportPathParams>, "path" | "verb"> & PatchApiV1ResourcesIdRetryExportPathParams;

/**
 * Retry export for a single resource
 */
export const PatchApiV1ResourcesIdRetryExport = ({id, ...props}: PatchApiV1ResourcesIdRetryExportProps) => (
  <Mutate<Resource, unknown, void, void, PatchApiV1ResourcesIdRetryExportPathParams>
    verb="PATCH"
    path={`/api/v1/resources/${id}/retry_export`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourcesIdRetryExportProps = Omit<UseMutateProps<Resource, unknown, void, void, PatchApiV1ResourcesIdRetryExportPathParams>, "path" | "verb"> & PatchApiV1ResourcesIdRetryExportPathParams;

/**
 * Retry export for a single resource
 */
export const usePatchApiV1ResourcesIdRetryExport = ({id, ...props}: UsePatchApiV1ResourcesIdRetryExportProps) => useMutate<Resource, unknown, void, void, PatchApiV1ResourcesIdRetryExportPathParams>("PATCH", (paramsInPath: PatchApiV1ResourcesIdRetryExportPathParams) => `/api/v1/resources/${paramsInPath.id}/retry_export`, {  pathParams: { id }, ...props });


export interface PatchApiV1ResourcesIdPathParams {
  /**
   * Resource import_guid
   */
  id: string
}

export type PatchApiV1ResourcesIdProps = Omit<MutateProps<Resource, unknown, void, PatchApiV1ResourcesId, PatchApiV1ResourcesIdPathParams>, "path" | "verb"> & PatchApiV1ResourcesIdPathParams;

/**
 * Update a single resource
 */
export const PatchApiV1ResourcesId = ({id, ...props}: PatchApiV1ResourcesIdProps) => (
  <Mutate<Resource, unknown, void, PatchApiV1ResourcesId, PatchApiV1ResourcesIdPathParams>
    verb="PATCH"
    path={`/api/v1/resources/${id}`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourcesIdProps = Omit<UseMutateProps<Resource, unknown, void, PatchApiV1ResourcesId, PatchApiV1ResourcesIdPathParams>, "path" | "verb"> & PatchApiV1ResourcesIdPathParams;

/**
 * Update a single resource
 */
export const usePatchApiV1ResourcesId = ({id, ...props}: UsePatchApiV1ResourcesIdProps) => useMutate<Resource, unknown, void, PatchApiV1ResourcesId, PatchApiV1ResourcesIdPathParams>("PATCH", (paramsInPath: PatchApiV1ResourcesIdPathParams) => `/api/v1/resources/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface PatchApiV1ResourcesIdRestartPathParams {
  id: string
}

export type PatchApiV1ResourcesIdRestartProps = Omit<MutateProps<Resource, unknown, void, void, PatchApiV1ResourcesIdRestartPathParams>, "path" | "verb"> & PatchApiV1ResourcesIdRestartPathParams;

/**
 * Change resources state from request_correction to started
 */
export const PatchApiV1ResourcesIdRestart = ({id, ...props}: PatchApiV1ResourcesIdRestartProps) => (
  <Mutate<Resource, unknown, void, void, PatchApiV1ResourcesIdRestartPathParams>
    verb="PATCH"
    path={`/api/v1/resources/${id}/restart`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourcesIdRestartProps = Omit<UseMutateProps<Resource, unknown, void, void, PatchApiV1ResourcesIdRestartPathParams>, "path" | "verb"> & PatchApiV1ResourcesIdRestartPathParams;

/**
 * Change resources state from request_correction to started
 */
export const usePatchApiV1ResourcesIdRestart = ({id, ...props}: UsePatchApiV1ResourcesIdRestartProps) => useMutate<Resource, unknown, void, void, PatchApiV1ResourcesIdRestartPathParams>("PATCH", (paramsInPath: PatchApiV1ResourcesIdRestartPathParams) => `/api/v1/resources/${paramsInPath.id}/restart`, {  pathParams: { id }, ...props });


export interface PatchApiV1ResourcesIdAcceptAsEditorialPathParams {
  id: string
}

export type PatchApiV1ResourcesIdAcceptAsEditorialProps = Omit<MutateProps<Resource, APIError, void, void, PatchApiV1ResourcesIdAcceptAsEditorialPathParams>, "path" | "verb"> & PatchApiV1ResourcesIdAcceptAsEditorialPathParams;

/**
 * Accept a resource as editorial (when rejected as pro), and submit/approve/export
 */
export const PatchApiV1ResourcesIdAcceptAsEditorial = ({id, ...props}: PatchApiV1ResourcesIdAcceptAsEditorialProps) => (
  <Mutate<Resource, APIError, void, void, PatchApiV1ResourcesIdAcceptAsEditorialPathParams>
    verb="PATCH"
    path={`/api/v1/resources/${id}/accept_as_editorial`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourcesIdAcceptAsEditorialProps = Omit<UseMutateProps<Resource, APIError, void, void, PatchApiV1ResourcesIdAcceptAsEditorialPathParams>, "path" | "verb"> & PatchApiV1ResourcesIdAcceptAsEditorialPathParams;

/**
 * Accept a resource as editorial (when rejected as pro), and submit/approve/export
 */
export const usePatchApiV1ResourcesIdAcceptAsEditorial = ({id, ...props}: UsePatchApiV1ResourcesIdAcceptAsEditorialProps) => useMutate<Resource, APIError, void, void, PatchApiV1ResourcesIdAcceptAsEditorialPathParams>("PATCH", (paramsInPath: PatchApiV1ResourcesIdAcceptAsEditorialPathParams) => `/api/v1/resources/${paramsInPath.id}/accept_as_editorial`, {  pathParams: { id }, ...props });


export interface PatchApiV1ResourcesIdAcceptAsFreePathParams {
  id: string
}

export type PatchApiV1ResourcesIdAcceptAsFreeProps = Omit<MutateProps<Resource, APIError, void, void, PatchApiV1ResourcesIdAcceptAsFreePathParams>, "path" | "verb"> & PatchApiV1ResourcesIdAcceptAsFreePathParams;

/**
 * Accept a resource as free (when rejected as pro), and submit/approve/export
 */
export const PatchApiV1ResourcesIdAcceptAsFree = ({id, ...props}: PatchApiV1ResourcesIdAcceptAsFreeProps) => (
  <Mutate<Resource, APIError, void, void, PatchApiV1ResourcesIdAcceptAsFreePathParams>
    verb="PATCH"
    path={`/api/v1/resources/${id}/accept_as_free`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourcesIdAcceptAsFreeProps = Omit<UseMutateProps<Resource, APIError, void, void, PatchApiV1ResourcesIdAcceptAsFreePathParams>, "path" | "verb"> & PatchApiV1ResourcesIdAcceptAsFreePathParams;

/**
 * Accept a resource as free (when rejected as pro), and submit/approve/export
 */
export const usePatchApiV1ResourcesIdAcceptAsFree = ({id, ...props}: UsePatchApiV1ResourcesIdAcceptAsFreeProps) => useMutate<Resource, APIError, void, void, PatchApiV1ResourcesIdAcceptAsFreePathParams>("PATCH", (paramsInPath: PatchApiV1ResourcesIdAcceptAsFreePathParams) => `/api/v1/resources/${paramsInPath.id}/accept_as_free`, {  pathParams: { id }, ...props });


export interface GetApiV1ResourcesResourceIdCommentsQueryParams {
  allow_internal_comments?: boolean;
}

export interface GetApiV1ResourcesResourceIdCommentsPathParams {
  resource_id: string
}

export type GetApiV1ResourcesResourceIdCommentsProps = Omit<GetProps<Comment[], unknown, GetApiV1ResourcesResourceIdCommentsQueryParams, GetApiV1ResourcesResourceIdCommentsPathParams>, "path"> & GetApiV1ResourcesResourceIdCommentsPathParams;

/**
 * Get comments for a resource
 */
export const GetApiV1ResourcesResourceIdComments = ({resource_id, ...props}: GetApiV1ResourcesResourceIdCommentsProps) => (
  <Get<Comment[], unknown, GetApiV1ResourcesResourceIdCommentsQueryParams, GetApiV1ResourcesResourceIdCommentsPathParams>
    path={`/api/v1/resources/${resource_id}/comments`}
    
    {...props}
  />
);

export type UseGetApiV1ResourcesResourceIdCommentsProps = Omit<UseGetProps<Comment[], unknown, GetApiV1ResourcesResourceIdCommentsQueryParams, GetApiV1ResourcesResourceIdCommentsPathParams>, "path"> & GetApiV1ResourcesResourceIdCommentsPathParams;

/**
 * Get comments for a resource
 */
export const useGetApiV1ResourcesResourceIdComments = ({resource_id, ...props}: UseGetApiV1ResourcesResourceIdCommentsProps) => useGet<Comment[], unknown, GetApiV1ResourcesResourceIdCommentsQueryParams, GetApiV1ResourcesResourceIdCommentsPathParams>((paramsInPath: GetApiV1ResourcesResourceIdCommentsPathParams) => `/api/v1/resources/${paramsInPath.resource_id}/comments`, {  pathParams: { resource_id }, ...props });


export interface GetApiV1ResourcesResourceIdHistoryPathParams {
  resource_id: string
}

export type GetApiV1ResourcesResourceIdHistoryProps = Omit<GetProps<ResourceHistory[], APIError, void, GetApiV1ResourcesResourceIdHistoryPathParams>, "path"> & GetApiV1ResourcesResourceIdHistoryPathParams;

/**
 * Get history for a resource
 */
export const GetApiV1ResourcesResourceIdHistory = ({resource_id, ...props}: GetApiV1ResourcesResourceIdHistoryProps) => (
  <Get<ResourceHistory[], APIError, void, GetApiV1ResourcesResourceIdHistoryPathParams>
    path={`/api/v1/resources/${resource_id}/history`}
    
    {...props}
  />
);

export type UseGetApiV1ResourcesResourceIdHistoryProps = Omit<UseGetProps<ResourceHistory[], APIError, void, GetApiV1ResourcesResourceIdHistoryPathParams>, "path"> & GetApiV1ResourcesResourceIdHistoryPathParams;

/**
 * Get history for a resource
 */
export const useGetApiV1ResourcesResourceIdHistory = ({resource_id, ...props}: UseGetApiV1ResourcesResourceIdHistoryProps) => useGet<ResourceHistory[], APIError, void, GetApiV1ResourcesResourceIdHistoryPathParams>((paramsInPath: GetApiV1ResourcesResourceIdHistoryPathParams) => `/api/v1/resources/${paramsInPath.resource_id}/history`, {  pathParams: { resource_id }, ...props });


export interface GetApiV1ResourcesResourceIdMetadataSuggestionsStatusPathParams {
  resource_id: string
}

export type GetApiV1ResourcesResourceIdMetadataSuggestionsStatusProps = Omit<GetProps<ResourcesSuggestedMetadata, unknown, void, GetApiV1ResourcesResourceIdMetadataSuggestionsStatusPathParams>, "path"> & GetApiV1ResourcesResourceIdMetadataSuggestionsStatusPathParams;

/**
 * Returns the status of the job for a given resource
 */
export const GetApiV1ResourcesResourceIdMetadataSuggestionsStatus = ({resource_id, ...props}: GetApiV1ResourcesResourceIdMetadataSuggestionsStatusProps) => (
  <Get<ResourcesSuggestedMetadata, unknown, void, GetApiV1ResourcesResourceIdMetadataSuggestionsStatusPathParams>
    path={`/api/v1/resources/${resource_id}/metadata_suggestions/status`}
    
    {...props}
  />
);

export type UseGetApiV1ResourcesResourceIdMetadataSuggestionsStatusProps = Omit<UseGetProps<ResourcesSuggestedMetadata, unknown, void, GetApiV1ResourcesResourceIdMetadataSuggestionsStatusPathParams>, "path"> & GetApiV1ResourcesResourceIdMetadataSuggestionsStatusPathParams;

/**
 * Returns the status of the job for a given resource
 */
export const useGetApiV1ResourcesResourceIdMetadataSuggestionsStatus = ({resource_id, ...props}: UseGetApiV1ResourcesResourceIdMetadataSuggestionsStatusProps) => useGet<ResourcesSuggestedMetadata, unknown, void, GetApiV1ResourcesResourceIdMetadataSuggestionsStatusPathParams>((paramsInPath: GetApiV1ResourcesResourceIdMetadataSuggestionsStatusPathParams) => `/api/v1/resources/${paramsInPath.resource_id}/metadata_suggestions/status`, {  pathParams: { resource_id }, ...props });


export interface PostApiV1ResourcesResourceIdMetadataSuggestionsPathParams {
  resource_id: string
}

export type PostApiV1ResourcesResourceIdMetadataSuggestionsProps = Omit<MutateProps<ResourcesSuggestedMetadata, unknown, void, void, PostApiV1ResourcesResourceIdMetadataSuggestionsPathParams>, "path" | "verb"> & PostApiV1ResourcesResourceIdMetadataSuggestionsPathParams;

/**
 * Starts process to generate suggested metadata
 */
export const PostApiV1ResourcesResourceIdMetadataSuggestions = ({resource_id, ...props}: PostApiV1ResourcesResourceIdMetadataSuggestionsProps) => (
  <Mutate<ResourcesSuggestedMetadata, unknown, void, void, PostApiV1ResourcesResourceIdMetadataSuggestionsPathParams>
    verb="POST"
    path={`/api/v1/resources/${resource_id}/metadata_suggestions`}
    
    {...props}
  />
);

export type UsePostApiV1ResourcesResourceIdMetadataSuggestionsProps = Omit<UseMutateProps<ResourcesSuggestedMetadata, unknown, void, void, PostApiV1ResourcesResourceIdMetadataSuggestionsPathParams>, "path" | "verb"> & PostApiV1ResourcesResourceIdMetadataSuggestionsPathParams;

/**
 * Starts process to generate suggested metadata
 */
export const usePostApiV1ResourcesResourceIdMetadataSuggestions = ({resource_id, ...props}: UsePostApiV1ResourcesResourceIdMetadataSuggestionsProps) => useMutate<ResourcesSuggestedMetadata, unknown, void, void, PostApiV1ResourcesResourceIdMetadataSuggestionsPathParams>("POST", (paramsInPath: PostApiV1ResourcesResourceIdMetadataSuggestionsPathParams) => `/api/v1/resources/${paramsInPath.resource_id}/metadata_suggestions`, {  pathParams: { resource_id }, ...props });


export interface PatchApiV1ResourcesResourceIdReplacePathParams {
  resource_id: string
}

export type PatchApiV1ResourcesResourceIdReplaceProps = Omit<MutateProps<WebUploadImport, APIError, void, void, PatchApiV1ResourcesResourceIdReplacePathParams>, "path" | "verb"> & PatchApiV1ResourcesResourceIdReplacePathParams;

/**
 * Replace source file for a resource
 */
export const PatchApiV1ResourcesResourceIdReplace = ({resource_id, ...props}: PatchApiV1ResourcesResourceIdReplaceProps) => (
  <Mutate<WebUploadImport, APIError, void, void, PatchApiV1ResourcesResourceIdReplacePathParams>
    verb="PATCH"
    path={`/api/v1/resources/${resource_id}/replace`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourcesResourceIdReplaceProps = Omit<UseMutateProps<WebUploadImport, APIError, void, void, PatchApiV1ResourcesResourceIdReplacePathParams>, "path" | "verb"> & PatchApiV1ResourcesResourceIdReplacePathParams;

/**
 * Replace source file for a resource
 */
export const usePatchApiV1ResourcesResourceIdReplace = ({resource_id, ...props}: UsePatchApiV1ResourcesResourceIdReplaceProps) => useMutate<WebUploadImport, APIError, void, void, PatchApiV1ResourcesResourceIdReplacePathParams>("PATCH", (paramsInPath: PatchApiV1ResourcesResourceIdReplacePathParams) => `/api/v1/resources/${paramsInPath.resource_id}/replace`, {  pathParams: { resource_id }, ...props });


export interface PostApiV1ResourcesResourceIdSeoMetadataPathParams {
  resource_id: string
}

export type PostApiV1ResourcesResourceIdSeoMetadataProps = Omit<MutateProps<SeoMetadata, unknown, void, PostApiV1ResourcesResourceIdSeoMetadata, PostApiV1ResourcesResourceIdSeoMetadataPathParams>, "path" | "verb"> & PostApiV1ResourcesResourceIdSeoMetadataPathParams;

/**
 * Create a new SEO Metadata record belonging to a resource
 */
export const PostApiV1ResourcesResourceIdSeoMetadata = ({resource_id, ...props}: PostApiV1ResourcesResourceIdSeoMetadataProps) => (
  <Mutate<SeoMetadata, unknown, void, PostApiV1ResourcesResourceIdSeoMetadata, PostApiV1ResourcesResourceIdSeoMetadataPathParams>
    verb="POST"
    path={`/api/v1/resources/${resource_id}/seo_metadata`}
    
    {...props}
  />
);

export type UsePostApiV1ResourcesResourceIdSeoMetadataProps = Omit<UseMutateProps<SeoMetadata, unknown, void, PostApiV1ResourcesResourceIdSeoMetadata, PostApiV1ResourcesResourceIdSeoMetadataPathParams>, "path" | "verb"> & PostApiV1ResourcesResourceIdSeoMetadataPathParams;

/**
 * Create a new SEO Metadata record belonging to a resource
 */
export const usePostApiV1ResourcesResourceIdSeoMetadata = ({resource_id, ...props}: UsePostApiV1ResourcesResourceIdSeoMetadataProps) => useMutate<SeoMetadata, unknown, void, PostApiV1ResourcesResourceIdSeoMetadata, PostApiV1ResourcesResourceIdSeoMetadataPathParams>("POST", (paramsInPath: PostApiV1ResourcesResourceIdSeoMetadataPathParams) => `/api/v1/resources/${paramsInPath.resource_id}/seo_metadata`, {  pathParams: { resource_id }, ...props });


export interface PatchApiV1ResourcesResourceIdSeoMetadataPathParams {
  resource_id: string
}

export type PatchApiV1ResourcesResourceIdSeoMetadataProps = Omit<MutateProps<SeoMetadata, unknown, void, PatchApiV1ResourcesResourceIdSeoMetadata, PatchApiV1ResourcesResourceIdSeoMetadataPathParams>, "path" | "verb"> & PatchApiV1ResourcesResourceIdSeoMetadataPathParams;

/**
 * Update an SEO Metadata record belonging to a resource
 */
export const PatchApiV1ResourcesResourceIdSeoMetadata = ({resource_id, ...props}: PatchApiV1ResourcesResourceIdSeoMetadataProps) => (
  <Mutate<SeoMetadata, unknown, void, PatchApiV1ResourcesResourceIdSeoMetadata, PatchApiV1ResourcesResourceIdSeoMetadataPathParams>
    verb="PATCH"
    path={`/api/v1/resources/${resource_id}/seo_metadata`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourcesResourceIdSeoMetadataProps = Omit<UseMutateProps<SeoMetadata, unknown, void, PatchApiV1ResourcesResourceIdSeoMetadata, PatchApiV1ResourcesResourceIdSeoMetadataPathParams>, "path" | "verb"> & PatchApiV1ResourcesResourceIdSeoMetadataPathParams;

/**
 * Update an SEO Metadata record belonging to a resource
 */
export const usePatchApiV1ResourcesResourceIdSeoMetadata = ({resource_id, ...props}: UsePatchApiV1ResourcesResourceIdSeoMetadataProps) => useMutate<SeoMetadata, unknown, void, PatchApiV1ResourcesResourceIdSeoMetadata, PatchApiV1ResourcesResourceIdSeoMetadataPathParams>("PATCH", (paramsInPath: PatchApiV1ResourcesResourceIdSeoMetadataPathParams) => `/api/v1/resources/${paramsInPath.resource_id}/seo_metadata`, {  pathParams: { resource_id }, ...props });


export interface GetApiV1ResourcesResourceIdSeoMetadataPathParams {
  resource_id: string
}

export type GetApiV1ResourcesResourceIdSeoMetadataProps = Omit<GetProps<SeoMetadata, unknown, void, GetApiV1ResourcesResourceIdSeoMetadataPathParams>, "path"> & GetApiV1ResourcesResourceIdSeoMetadataPathParams;

/**
 * Fetch an SEO Metadata record belonging to a resource
 */
export const GetApiV1ResourcesResourceIdSeoMetadata = ({resource_id, ...props}: GetApiV1ResourcesResourceIdSeoMetadataProps) => (
  <Get<SeoMetadata, unknown, void, GetApiV1ResourcesResourceIdSeoMetadataPathParams>
    path={`/api/v1/resources/${resource_id}/seo_metadata`}
    
    {...props}
  />
);

export type UseGetApiV1ResourcesResourceIdSeoMetadataProps = Omit<UseGetProps<SeoMetadata, unknown, void, GetApiV1ResourcesResourceIdSeoMetadataPathParams>, "path"> & GetApiV1ResourcesResourceIdSeoMetadataPathParams;

/**
 * Fetch an SEO Metadata record belonging to a resource
 */
export const useGetApiV1ResourcesResourceIdSeoMetadata = ({resource_id, ...props}: UseGetApiV1ResourcesResourceIdSeoMetadataProps) => useGet<SeoMetadata, unknown, void, GetApiV1ResourcesResourceIdSeoMetadataPathParams>((paramsInPath: GetApiV1ResourcesResourceIdSeoMetadataPathParams) => `/api/v1/resources/${paramsInPath.resource_id}/seo_metadata`, {  pathParams: { resource_id }, ...props });


export interface PatchApiV1DuplicatesFingerprintPathParams {
  fingerprint: string
}

export type PatchApiV1DuplicatesFingerprintProps = Omit<MutateProps<void, unknown, void, PatchApiV1DuplicatesFingerprint, PatchApiV1DuplicatesFingerprintPathParams>, "path" | "verb"> & PatchApiV1DuplicatesFingerprintPathParams;

/**
 * Updates the given duplicate
 */
export const PatchApiV1DuplicatesFingerprint = ({fingerprint, ...props}: PatchApiV1DuplicatesFingerprintProps) => (
  <Mutate<void, unknown, void, PatchApiV1DuplicatesFingerprint, PatchApiV1DuplicatesFingerprintPathParams>
    verb="PATCH"
    path={`/api/v1/duplicates/${fingerprint}`}
    
    {...props}
  />
);

export type UsePatchApiV1DuplicatesFingerprintProps = Omit<UseMutateProps<void, unknown, void, PatchApiV1DuplicatesFingerprint, PatchApiV1DuplicatesFingerprintPathParams>, "path" | "verb"> & PatchApiV1DuplicatesFingerprintPathParams;

/**
 * Updates the given duplicate
 */
export const usePatchApiV1DuplicatesFingerprint = ({fingerprint, ...props}: UsePatchApiV1DuplicatesFingerprintProps) => useMutate<void, unknown, void, PatchApiV1DuplicatesFingerprint, PatchApiV1DuplicatesFingerprintPathParams>("PATCH", (paramsInPath: PatchApiV1DuplicatesFingerprintPathParams) => `/api/v1/duplicates/${paramsInPath.fingerprint}`, {  pathParams: { fingerprint }, ...props });


export interface GetApiV1DuplicatesFingerprintUsersPathParams {
  fingerprint: string
}

export type GetApiV1DuplicatesFingerprintUsersProps = Omit<GetProps<DuplicateOwner[], unknown, void, GetApiV1DuplicatesFingerprintUsersPathParams>, "path"> & GetApiV1DuplicatesFingerprintUsersPathParams;

/**
 * Lists users that have a resource with the given fingerprint
 */
export const GetApiV1DuplicatesFingerprintUsers = ({fingerprint, ...props}: GetApiV1DuplicatesFingerprintUsersProps) => (
  <Get<DuplicateOwner[], unknown, void, GetApiV1DuplicatesFingerprintUsersPathParams>
    path={`/api/v1/duplicates/${fingerprint}/users`}
    
    {...props}
  />
);

export type UseGetApiV1DuplicatesFingerprintUsersProps = Omit<UseGetProps<DuplicateOwner[], unknown, void, GetApiV1DuplicatesFingerprintUsersPathParams>, "path"> & GetApiV1DuplicatesFingerprintUsersPathParams;

/**
 * Lists users that have a resource with the given fingerprint
 */
export const useGetApiV1DuplicatesFingerprintUsers = ({fingerprint, ...props}: UseGetApiV1DuplicatesFingerprintUsersProps) => useGet<DuplicateOwner[], unknown, void, GetApiV1DuplicatesFingerprintUsersPathParams>((paramsInPath: GetApiV1DuplicatesFingerprintUsersPathParams) => `/api/v1/duplicates/${paramsInPath.fingerprint}/users`, {  pathParams: { fingerprint }, ...props });


export type PostApiV1FilestackImportsProps = Omit<MutateProps<FilestackImport, APIError, void, PostApiV1FilestackImports, void>, "path" | "verb">;

/**
 * Create a new Filestack import record and launch the import process
 */
export const PostApiV1FilestackImports = (props: PostApiV1FilestackImportsProps) => (
  <Mutate<FilestackImport, APIError, void, PostApiV1FilestackImports, void>
    verb="POST"
    path={`/api/v1/filestack_imports`}
    
    {...props}
  />
);

export type UsePostApiV1FilestackImportsProps = Omit<UseMutateProps<FilestackImport, APIError, void, PostApiV1FilestackImports, void>, "path" | "verb">;

/**
 * Create a new Filestack import record and launch the import process
 */
export const usePostApiV1FilestackImports = (props: UsePostApiV1FilestackImportsProps) => useMutate<FilestackImport, APIError, void, PostApiV1FilestackImports, void>("POST", `/api/v1/filestack_imports`, props);


export interface GetApiV1FilestackImportsQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Array of batch ids
   */
  ids?: string[];
  /**
   * Filter import batches by state
   */
  state?: ("pending" | "running" | "complete" | "failed")[];
}

export type GetApiV1FilestackImportsProps = Omit<GetProps<FilestackImport[], unknown, GetApiV1FilestackImportsQueryParams, void>, "path">;

/**
 * List Filestack imports
 */
export const GetApiV1FilestackImports = (props: GetApiV1FilestackImportsProps) => (
  <Get<FilestackImport[], unknown, GetApiV1FilestackImportsQueryParams, void>
    path={`/api/v1/filestack_imports`}
    
    {...props}
  />
);

export type UseGetApiV1FilestackImportsProps = Omit<UseGetProps<FilestackImport[], unknown, GetApiV1FilestackImportsQueryParams, void>, "path">;

/**
 * List Filestack imports
 */
export const useGetApiV1FilestackImports = (props: UseGetApiV1FilestackImportsProps) => useGet<FilestackImport[], unknown, GetApiV1FilestackImportsQueryParams, void>(`/api/v1/filestack_imports`, props);


export type DeleteApiV1FilestackImportsIdProps = Omit<MutateProps<FilestackImport, APIError, void, string, void>, "path" | "verb">;

/**
 * Cancel a Filestack import
 */
export const DeleteApiV1FilestackImportsId = (props: DeleteApiV1FilestackImportsIdProps) => (
  <Mutate<FilestackImport, APIError, void, string, void>
    verb="DELETE"
    path={`/api/v1/filestack_imports`}
    
    {...props}
  />
);

export type UseDeleteApiV1FilestackImportsIdProps = Omit<UseMutateProps<FilestackImport, APIError, void, string, void>, "path" | "verb">;

/**
 * Cancel a Filestack import
 */
export const useDeleteApiV1FilestackImportsId = (props: UseDeleteApiV1FilestackImportsIdProps) => useMutate<FilestackImport, APIError, void, string, void>("DELETE", `/api/v1/filestack_imports`, {   ...props });


export interface GetApiV1FilestackImportsIdPathParams {
  id: string
}

export type GetApiV1FilestackImportsIdProps = Omit<GetProps<FilestackImport, APIError, void, GetApiV1FilestackImportsIdPathParams>, "path"> & GetApiV1FilestackImportsIdPathParams;

/**
 * Get a Filestack import
 */
export const GetApiV1FilestackImportsId = ({id, ...props}: GetApiV1FilestackImportsIdProps) => (
  <Get<FilestackImport, APIError, void, GetApiV1FilestackImportsIdPathParams>
    path={`/api/v1/filestack_imports/${id}`}
    
    {...props}
  />
);

export type UseGetApiV1FilestackImportsIdProps = Omit<UseGetProps<FilestackImport, APIError, void, GetApiV1FilestackImportsIdPathParams>, "path"> & GetApiV1FilestackImportsIdPathParams;

/**
 * Get a Filestack import
 */
export const useGetApiV1FilestackImportsId = ({id, ...props}: UseGetApiV1FilestackImportsIdProps) => useGet<FilestackImport, APIError, void, GetApiV1FilestackImportsIdPathParams>((paramsInPath: GetApiV1FilestackImportsIdPathParams) => `/api/v1/filestack_imports/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1FtpJobsQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  state: "running" | "failed";
}

export type GetApiV1FtpJobsProps = Omit<GetProps<FtpJob[], unknown, GetApiV1FtpJobsQueryParams, void>, "path">;

/**
 * List ftp jobs
 */
export const GetApiV1FtpJobs = (props: GetApiV1FtpJobsProps) => (
  <Get<FtpJob[], unknown, GetApiV1FtpJobsQueryParams, void>
    path={`/api/v1/ftp_jobs`}
    
    {...props}
  />
);

export type UseGetApiV1FtpJobsProps = Omit<UseGetProps<FtpJob[], unknown, GetApiV1FtpJobsQueryParams, void>, "path">;

/**
 * List ftp jobs
 */
export const useGetApiV1FtpJobs = (props: UseGetApiV1FtpJobsProps) => useGet<FtpJob[], unknown, GetApiV1FtpJobsQueryParams, void>(`/api/v1/ftp_jobs`, props);


export type GetApiV1LabelsCountriesProps = Omit<GetProps<ResponsesLabelsCountries[], unknown, void, void>, "path">;

/**
 * List of countries
 */
export const GetApiV1LabelsCountries = (props: GetApiV1LabelsCountriesProps) => (
  <Get<ResponsesLabelsCountries[], unknown, void, void>
    path={`/api/v1/labels/countries`}
    
    {...props}
  />
);

export type UseGetApiV1LabelsCountriesProps = Omit<UseGetProps<ResponsesLabelsCountries[], unknown, void, void>, "path">;

/**
 * List of countries
 */
export const useGetApiV1LabelsCountries = (props: UseGetApiV1LabelsCountriesProps) => useGet<ResponsesLabelsCountries[], unknown, void, void>(`/api/v1/labels/countries`, props);


export type GetApiV1LabelsReleaseMetadataProps = Omit<GetProps<ResponsesLabelsReleaseMetadata[], unknown, void, void>, "path">;

/**
 * Release metadata labels
 */
export const GetApiV1LabelsReleaseMetadata = (props: GetApiV1LabelsReleaseMetadataProps) => (
  <Get<ResponsesLabelsReleaseMetadata[], unknown, void, void>
    path={`/api/v1/labels/release_metadata`}
    
    {...props}
  />
);

export type UseGetApiV1LabelsReleaseMetadataProps = Omit<UseGetProps<ResponsesLabelsReleaseMetadata[], unknown, void, void>, "path">;

/**
 * Release metadata labels
 */
export const useGetApiV1LabelsReleaseMetadata = (props: UseGetApiV1LabelsReleaseMetadataProps) => useGet<ResponsesLabelsReleaseMetadata[], unknown, void, void>(`/api/v1/labels/release_metadata`, props);


export type PostApiV1MarketplacesProps = Omit<MutateProps<Marketplace, unknown, void, void, void>, "path" | "verb">;

/**
 * Create a new marketplace
 */
export const PostApiV1Marketplaces = (props: PostApiV1MarketplacesProps) => (
  <Mutate<Marketplace, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/marketplaces`}
    
    {...props}
  />
);

export type UsePostApiV1MarketplacesProps = Omit<UseMutateProps<Marketplace, unknown, void, void, void>, "path" | "verb">;

/**
 * Create a new marketplace
 */
export const usePostApiV1Marketplaces = (props: UsePostApiV1MarketplacesProps) => useMutate<Marketplace, unknown, void, void, void>("POST", `/api/v1/marketplaces`, props);


export interface GetApiV1MarketplacesQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export type GetApiV1MarketplacesProps = Omit<GetProps<Marketplace[], unknown, GetApiV1MarketplacesQueryParams, void>, "path">;

/**
 * List marketplaces
 */
export const GetApiV1Marketplaces = (props: GetApiV1MarketplacesProps) => (
  <Get<Marketplace[], unknown, GetApiV1MarketplacesQueryParams, void>
    path={`/api/v1/marketplaces`}
    
    {...props}
  />
);

export type UseGetApiV1MarketplacesProps = Omit<UseGetProps<Marketplace[], unknown, GetApiV1MarketplacesQueryParams, void>, "path">;

/**
 * List marketplaces
 */
export const useGetApiV1Marketplaces = (props: UseGetApiV1MarketplacesProps) => useGet<Marketplace[], unknown, GetApiV1MarketplacesQueryParams, void>(`/api/v1/marketplaces`, props);


export type DeleteApiV1MarketplacesMarketplaceIdProps = Omit<MutateProps<Marketplace, unknown, void, number, void>, "path" | "verb">;

/**
 * Delete a marketplace
 */
export const DeleteApiV1MarketplacesMarketplaceId = (props: DeleteApiV1MarketplacesMarketplaceIdProps) => (
  <Mutate<Marketplace, unknown, void, number, void>
    verb="DELETE"
    path={`/api/v1/marketplaces`}
    
    {...props}
  />
);

export type UseDeleteApiV1MarketplacesMarketplaceIdProps = Omit<UseMutateProps<Marketplace, unknown, void, number, void>, "path" | "verb">;

/**
 * Delete a marketplace
 */
export const useDeleteApiV1MarketplacesMarketplaceId = (props: UseDeleteApiV1MarketplacesMarketplaceIdProps) => useMutate<Marketplace, unknown, void, number, void>("DELETE", `/api/v1/marketplaces`, {   ...props });


export interface PatchApiV1MarketplacesMarketplaceIdPathParams {
  marketplace_id: number
}

export type PatchApiV1MarketplacesMarketplaceIdProps = Omit<MutateProps<Marketplace, unknown, void, void, PatchApiV1MarketplacesMarketplaceIdPathParams>, "path" | "verb"> & PatchApiV1MarketplacesMarketplaceIdPathParams;

/**
 * Update a marketplace
 */
export const PatchApiV1MarketplacesMarketplaceId = ({marketplace_id, ...props}: PatchApiV1MarketplacesMarketplaceIdProps) => (
  <Mutate<Marketplace, unknown, void, void, PatchApiV1MarketplacesMarketplaceIdPathParams>
    verb="PATCH"
    path={`/api/v1/marketplaces/${marketplace_id}`}
    
    {...props}
  />
);

export type UsePatchApiV1MarketplacesMarketplaceIdProps = Omit<UseMutateProps<Marketplace, unknown, void, void, PatchApiV1MarketplacesMarketplaceIdPathParams>, "path" | "verb"> & PatchApiV1MarketplacesMarketplaceIdPathParams;

/**
 * Update a marketplace
 */
export const usePatchApiV1MarketplacesMarketplaceId = ({marketplace_id, ...props}: UsePatchApiV1MarketplacesMarketplaceIdProps) => useMutate<Marketplace, unknown, void, void, PatchApiV1MarketplacesMarketplaceIdPathParams>("PATCH", (paramsInPath: PatchApiV1MarketplacesMarketplaceIdPathParams) => `/api/v1/marketplaces/${paramsInPath.marketplace_id}`, {  pathParams: { marketplace_id }, ...props });


export type PostApiV1MarketplaceAccountsProps = Omit<MutateProps<MarketplaceAccount, unknown, void, void, void>, "path" | "verb">;

/**
 * Create a new marketplace account for the current user
 */
export const PostApiV1MarketplaceAccounts = (props: PostApiV1MarketplaceAccountsProps) => (
  <Mutate<MarketplaceAccount, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/marketplace_accounts`}
    
    {...props}
  />
);

export type UsePostApiV1MarketplaceAccountsProps = Omit<UseMutateProps<MarketplaceAccount, unknown, void, void, void>, "path" | "verb">;

/**
 * Create a new marketplace account for the current user
 */
export const usePostApiV1MarketplaceAccounts = (props: UsePostApiV1MarketplaceAccountsProps) => useMutate<MarketplaceAccount, unknown, void, void, void>("POST", `/api/v1/marketplace_accounts`, props);


export interface GetApiV1MarketplaceAccountsQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export type GetApiV1MarketplaceAccountsProps = Omit<GetProps<MarketplaceAccount[], unknown, GetApiV1MarketplaceAccountsQueryParams, void>, "path">;

/**
 * List marketplace acounts for the current user
 */
export const GetApiV1MarketplaceAccounts = (props: GetApiV1MarketplaceAccountsProps) => (
  <Get<MarketplaceAccount[], unknown, GetApiV1MarketplaceAccountsQueryParams, void>
    path={`/api/v1/marketplace_accounts`}
    
    {...props}
  />
);

export type UseGetApiV1MarketplaceAccountsProps = Omit<UseGetProps<MarketplaceAccount[], unknown, GetApiV1MarketplaceAccountsQueryParams, void>, "path">;

/**
 * List marketplace acounts for the current user
 */
export const useGetApiV1MarketplaceAccounts = (props: UseGetApiV1MarketplaceAccountsProps) => useGet<MarketplaceAccount[], unknown, GetApiV1MarketplaceAccountsQueryParams, void>(`/api/v1/marketplace_accounts`, props);


export type DeleteApiV1MarketplaceAccountsMarketplaceAccountIdProps = Omit<MutateProps<MarketplaceAccount, unknown, void, number, void>, "path" | "verb">;

/**
 * Delete/disconnect a marketplace account
 */
export const DeleteApiV1MarketplaceAccountsMarketplaceAccountId = (props: DeleteApiV1MarketplaceAccountsMarketplaceAccountIdProps) => (
  <Mutate<MarketplaceAccount, unknown, void, number, void>
    verb="DELETE"
    path={`/api/v1/marketplace_accounts`}
    
    {...props}
  />
);

export type UseDeleteApiV1MarketplaceAccountsMarketplaceAccountIdProps = Omit<UseMutateProps<MarketplaceAccount, unknown, void, number, void>, "path" | "verb">;

/**
 * Delete/disconnect a marketplace account
 */
export const useDeleteApiV1MarketplaceAccountsMarketplaceAccountId = (props: UseDeleteApiV1MarketplaceAccountsMarketplaceAccountIdProps) => useMutate<MarketplaceAccount, unknown, void, number, void>("DELETE", `/api/v1/marketplace_accounts`, {   ...props });


export interface PatchApiV1MarketplaceAccountsMarketplaceAccountIdPathParams {
  marketplace_account_id: number
}

export type PatchApiV1MarketplaceAccountsMarketplaceAccountIdProps = Omit<MutateProps<MarketplaceAccount, unknown, void, void, PatchApiV1MarketplaceAccountsMarketplaceAccountIdPathParams>, "path" | "verb"> & PatchApiV1MarketplaceAccountsMarketplaceAccountIdPathParams;

/**
 * Update a marketplace account
 */
export const PatchApiV1MarketplaceAccountsMarketplaceAccountId = ({marketplace_account_id, ...props}: PatchApiV1MarketplaceAccountsMarketplaceAccountIdProps) => (
  <Mutate<MarketplaceAccount, unknown, void, void, PatchApiV1MarketplaceAccountsMarketplaceAccountIdPathParams>
    verb="PATCH"
    path={`/api/v1/marketplace_accounts/${marketplace_account_id}`}
    
    {...props}
  />
);

export type UsePatchApiV1MarketplaceAccountsMarketplaceAccountIdProps = Omit<UseMutateProps<MarketplaceAccount, unknown, void, void, PatchApiV1MarketplaceAccountsMarketplaceAccountIdPathParams>, "path" | "verb"> & PatchApiV1MarketplaceAccountsMarketplaceAccountIdPathParams;

/**
 * Update a marketplace account
 */
export const usePatchApiV1MarketplaceAccountsMarketplaceAccountId = ({marketplace_account_id, ...props}: UsePatchApiV1MarketplaceAccountsMarketplaceAccountIdProps) => useMutate<MarketplaceAccount, unknown, void, void, PatchApiV1MarketplaceAccountsMarketplaceAccountIdPathParams>("PATCH", (paramsInPath: PatchApiV1MarketplaceAccountsMarketplaceAccountIdPathParams) => `/api/v1/marketplace_accounts/${paramsInPath.marketplace_account_id}`, {  pathParams: { marketplace_account_id }, ...props });


export interface GetApiV1MarketplaceAccountResourcesRollupsQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export type GetApiV1MarketplaceAccountResourcesRollupsProps = Omit<GetProps<MarketplaceAccountResourceRollup[], unknown, GetApiV1MarketplaceAccountResourcesRollupsQueryParams, void>, "path">;

/**
 * For a given set of resources it returns the resource ids and their transfer states that are distributed to each marketplace
 */
export const GetApiV1MarketplaceAccountResourcesRollups = (props: GetApiV1MarketplaceAccountResourcesRollupsProps) => (
  <Get<MarketplaceAccountResourceRollup[], unknown, GetApiV1MarketplaceAccountResourcesRollupsQueryParams, void>
    path={`/api/v1/marketplace_account_resources/rollups`}
    
    {...props}
  />
);

export type UseGetApiV1MarketplaceAccountResourcesRollupsProps = Omit<UseGetProps<MarketplaceAccountResourceRollup[], unknown, GetApiV1MarketplaceAccountResourcesRollupsQueryParams, void>, "path">;

/**
 * For a given set of resources it returns the resource ids and their transfer states that are distributed to each marketplace
 */
export const useGetApiV1MarketplaceAccountResourcesRollups = (props: UseGetApiV1MarketplaceAccountResourcesRollupsProps) => useGet<MarketplaceAccountResourceRollup[], unknown, GetApiV1MarketplaceAccountResourcesRollupsQueryParams, void>(`/api/v1/marketplace_account_resources/rollups`, props);


export type PostApiV1MarketplaceMetadataOperationsProps = Omit<MutateProps<MarketplaceMetadataOperation, unknown, void, PostApiV1MarketplaceMetadataOperations, void>, "path" | "verb">;

/**
 * Create a marketplace metadata operation
 */
export const PostApiV1MarketplaceMetadataOperations = (props: PostApiV1MarketplaceMetadataOperationsProps) => (
  <Mutate<MarketplaceMetadataOperation, unknown, void, PostApiV1MarketplaceMetadataOperations, void>
    verb="POST"
    path={`/api/v1/marketplace_metadata_operations`}
    
    {...props}
  />
);

export type UsePostApiV1MarketplaceMetadataOperationsProps = Omit<UseMutateProps<MarketplaceMetadataOperation, unknown, void, PostApiV1MarketplaceMetadataOperations, void>, "path" | "verb">;

/**
 * Create a marketplace metadata operation
 */
export const usePostApiV1MarketplaceMetadataOperations = (props: UsePostApiV1MarketplaceMetadataOperationsProps) => useMutate<MarketplaceMetadataOperation, unknown, void, PostApiV1MarketplaceMetadataOperations, void>("POST", `/api/v1/marketplace_metadata_operations`, props);


export interface PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdPathParams {
  marketplace_metadata_operation_id: number
}

export type PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdProps = Omit<MutateProps<MarketplaceMetadataOperation, unknown, void, PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationId, PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdPathParams>, "path" | "verb"> & PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdPathParams;

/**
 * Update marketplace metadata operation
 */
export const PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationId = ({marketplace_metadata_operation_id, ...props}: PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdProps) => (
  <Mutate<MarketplaceMetadataOperation, unknown, void, PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationId, PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdPathParams>
    verb="PATCH"
    path={`/api/v1/marketplace_metadata_operations/${marketplace_metadata_operation_id}`}
    
    {...props}
  />
);

export type UsePatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdProps = Omit<UseMutateProps<MarketplaceMetadataOperation, unknown, void, PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationId, PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdPathParams>, "path" | "verb"> & PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdPathParams;

/**
 * Update marketplace metadata operation
 */
export const usePatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationId = ({marketplace_metadata_operation_id, ...props}: UsePatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdProps) => useMutate<MarketplaceMetadataOperation, unknown, void, PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationId, PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdPathParams>("PATCH", (paramsInPath: PatchApiV1MarketplaceMetadataOperationsMarketplaceMetadataOperationIdPathParams) => `/api/v1/marketplace_metadata_operations/${paramsInPath.marketplace_metadata_operation_id}`, {  pathParams: { marketplace_metadata_operation_id }, ...props });


export type PostApiV1NotesProps = Omit<MutateProps<Note, APIError, void, PostApiV1Notes, void>, "path" | "verb">;

/**
 * Create a note
 */
export const PostApiV1Notes = (props: PostApiV1NotesProps) => (
  <Mutate<Note, APIError, void, PostApiV1Notes, void>
    verb="POST"
    path={`/api/v1/notes`}
    
    {...props}
  />
);

export type UsePostApiV1NotesProps = Omit<UseMutateProps<Note, APIError, void, PostApiV1Notes, void>, "path" | "verb">;

/**
 * Create a note
 */
export const usePostApiV1Notes = (props: UsePostApiV1NotesProps) => useMutate<Note, APIError, void, PostApiV1Notes, void>("POST", `/api/v1/notes`, props);


export interface GetApiV1NotesQueryParams {
  contributor_id: string;
}

export type GetApiV1NotesProps = Omit<GetProps<Note[], unknown, GetApiV1NotesQueryParams, void>, "path">;

/**
 * Get notes for specific contributor
 */
export const GetApiV1Notes = (props: GetApiV1NotesProps) => (
  <Get<Note[], unknown, GetApiV1NotesQueryParams, void>
    path={`/api/v1/notes`}
    
    {...props}
  />
);

export type UseGetApiV1NotesProps = Omit<UseGetProps<Note[], unknown, GetApiV1NotesQueryParams, void>, "path">;

/**
 * Get notes for specific contributor
 */
export const useGetApiV1Notes = (props: UseGetApiV1NotesProps) => useGet<Note[], unknown, GetApiV1NotesQueryParams, void>(`/api/v1/notes`, props);


export interface PatchApiV1NotesIdPathParams {
  id: number
}

export type PatchApiV1NotesIdProps = Omit<MutateProps<Note, unknown, void, PatchApiV1NotesId, PatchApiV1NotesIdPathParams>, "path" | "verb"> & PatchApiV1NotesIdPathParams;

/**
 * Update a note
 */
export const PatchApiV1NotesId = ({id, ...props}: PatchApiV1NotesIdProps) => (
  <Mutate<Note, unknown, void, PatchApiV1NotesId, PatchApiV1NotesIdPathParams>
    verb="PATCH"
    path={`/api/v1/notes/${id}`}
    
    {...props}
  />
);

export type UsePatchApiV1NotesIdProps = Omit<UseMutateProps<Note, unknown, void, PatchApiV1NotesId, PatchApiV1NotesIdPathParams>, "path" | "verb"> & PatchApiV1NotesIdPathParams;

/**
 * Update a note
 */
export const usePatchApiV1NotesId = ({id, ...props}: UsePatchApiV1NotesIdProps) => useMutate<Note, unknown, void, PatchApiV1NotesId, PatchApiV1NotesIdPathParams>("PATCH", (paramsInPath: PatchApiV1NotesIdPathParams) => `/api/v1/notes/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1OpportunitiesByContentTypeContentTypeQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export interface GetApiV1OpportunitiesByContentTypeContentTypePathParams {
  content_type: string
}

export type GetApiV1OpportunitiesByContentTypeContentTypeProps = Omit<GetProps<Opportunity[], unknown, GetApiV1OpportunitiesByContentTypeContentTypeQueryParams, GetApiV1OpportunitiesByContentTypeContentTypePathParams>, "path"> & GetApiV1OpportunitiesByContentTypeContentTypePathParams;

/**
 * opportunities by content_type
 */
export const GetApiV1OpportunitiesByContentTypeContentType = ({content_type, ...props}: GetApiV1OpportunitiesByContentTypeContentTypeProps) => (
  <Get<Opportunity[], unknown, GetApiV1OpportunitiesByContentTypeContentTypeQueryParams, GetApiV1OpportunitiesByContentTypeContentTypePathParams>
    path={`/api/v1/opportunities/by_content_type/${content_type}`}
    
    {...props}
  />
);

export type UseGetApiV1OpportunitiesByContentTypeContentTypeProps = Omit<UseGetProps<Opportunity[], unknown, GetApiV1OpportunitiesByContentTypeContentTypeQueryParams, GetApiV1OpportunitiesByContentTypeContentTypePathParams>, "path"> & GetApiV1OpportunitiesByContentTypeContentTypePathParams;

/**
 * opportunities by content_type
 */
export const useGetApiV1OpportunitiesByContentTypeContentType = ({content_type, ...props}: UseGetApiV1OpportunitiesByContentTypeContentTypeProps) => useGet<Opportunity[], unknown, GetApiV1OpportunitiesByContentTypeContentTypeQueryParams, GetApiV1OpportunitiesByContentTypeContentTypePathParams>((paramsInPath: GetApiV1OpportunitiesByContentTypeContentTypePathParams) => `/api/v1/opportunities/by_content_type/${paramsInPath.content_type}`, {  pathParams: { content_type }, ...props });


export interface PostApiV1PayoneerUserIdExistingAccountLinkPathParams {
  user_id: string
}

export type PostApiV1PayoneerUserIdExistingAccountLinkProps = Omit<MutateProps<PayoneerRegistrationLink, unknown, void, void, PostApiV1PayoneerUserIdExistingAccountLinkPathParams>, "path" | "verb"> & PostApiV1PayoneerUserIdExistingAccountLinkPathParams;

/**
 * Returns a link for users to link an existing Payoneer account to the CM
 */
export const PostApiV1PayoneerUserIdExistingAccountLink = ({user_id, ...props}: PostApiV1PayoneerUserIdExistingAccountLinkProps) => (
  <Mutate<PayoneerRegistrationLink, unknown, void, void, PostApiV1PayoneerUserIdExistingAccountLinkPathParams>
    verb="POST"
    path={`/api/v1/payoneer/${user_id}/existing_account_link`}
    
    {...props}
  />
);

export type UsePostApiV1PayoneerUserIdExistingAccountLinkProps = Omit<UseMutateProps<PayoneerRegistrationLink, unknown, void, void, PostApiV1PayoneerUserIdExistingAccountLinkPathParams>, "path" | "verb"> & PostApiV1PayoneerUserIdExistingAccountLinkPathParams;

/**
 * Returns a link for users to link an existing Payoneer account to the CM
 */
export const usePostApiV1PayoneerUserIdExistingAccountLink = ({user_id, ...props}: UsePostApiV1PayoneerUserIdExistingAccountLinkProps) => useMutate<PayoneerRegistrationLink, unknown, void, void, PostApiV1PayoneerUserIdExistingAccountLinkPathParams>("POST", (paramsInPath: PostApiV1PayoneerUserIdExistingAccountLinkPathParams) => `/api/v1/payoneer/${paramsInPath.user_id}/existing_account_link`, {  pathParams: { user_id }, ...props });


export interface PostApiV1PayoneerUserIdNewAccountLinkPathParams {
  user_id: string
}

export type PostApiV1PayoneerUserIdNewAccountLinkProps = Omit<MutateProps<PayoneerRegistrationLink, unknown, void, void, PostApiV1PayoneerUserIdNewAccountLinkPathParams>, "path" | "verb"> & PostApiV1PayoneerUserIdNewAccountLinkPathParams;

/**
 * Returns a link for users to register for a new Payoneer account and link it to the CM
 */
export const PostApiV1PayoneerUserIdNewAccountLink = ({user_id, ...props}: PostApiV1PayoneerUserIdNewAccountLinkProps) => (
  <Mutate<PayoneerRegistrationLink, unknown, void, void, PostApiV1PayoneerUserIdNewAccountLinkPathParams>
    verb="POST"
    path={`/api/v1/payoneer/${user_id}/new_account_link`}
    
    {...props}
  />
);

export type UsePostApiV1PayoneerUserIdNewAccountLinkProps = Omit<UseMutateProps<PayoneerRegistrationLink, unknown, void, void, PostApiV1PayoneerUserIdNewAccountLinkPathParams>, "path" | "verb"> & PostApiV1PayoneerUserIdNewAccountLinkPathParams;

/**
 * Returns a link for users to register for a new Payoneer account and link it to the CM
 */
export const usePostApiV1PayoneerUserIdNewAccountLink = ({user_id, ...props}: UsePostApiV1PayoneerUserIdNewAccountLinkProps) => useMutate<PayoneerRegistrationLink, unknown, void, void, PostApiV1PayoneerUserIdNewAccountLinkPathParams>("POST", (paramsInPath: PostApiV1PayoneerUserIdNewAccountLinkPathParams) => `/api/v1/payoneer/${paramsInPath.user_id}/new_account_link`, {  pathParams: { user_id }, ...props });


export type PostApiV1ReleasesProps = Omit<MutateProps<Release, APIError, void, void, void>, "path" | "verb">;

/**
 * Create a release
 */
export const PostApiV1Releases = (props: PostApiV1ReleasesProps) => (
  <Mutate<Release, APIError, void, void, void>
    verb="POST"
    path={`/api/v1/releases`}
    
    {...props}
  />
);

export type UsePostApiV1ReleasesProps = Omit<UseMutateProps<Release, APIError, void, void, void>, "path" | "verb">;

/**
 * Create a release
 */
export const usePostApiV1Releases = (props: UsePostApiV1ReleasesProps) => useMutate<Release, APIError, void, void, void>("POST", `/api/v1/releases`, props);


export interface GetApiV1ReleasesQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Array of release ids
   */
  ids?: string[];
  /**
   * Sort releases by this field
   */
  sort_field?: "name" | "created_at";
  /**
   * Sort releases in this direction
   */
  sort_direction?: "asc" | "desc";
  /**
   * Search releases by name
   */
  query?: string;
}

export type GetApiV1ReleasesProps = Omit<GetProps<Release[], unknown, GetApiV1ReleasesQueryParams, void>, "path">;

/**
 * List releases
 */
export const GetApiV1Releases = (props: GetApiV1ReleasesProps) => (
  <Get<Release[], unknown, GetApiV1ReleasesQueryParams, void>
    path={`/api/v1/releases`}
    
    {...props}
  />
);

export type UseGetApiV1ReleasesProps = Omit<UseGetProps<Release[], unknown, GetApiV1ReleasesQueryParams, void>, "path">;

/**
 * List releases
 */
export const useGetApiV1Releases = (props: UseGetApiV1ReleasesProps) => useGet<Release[], unknown, GetApiV1ReleasesQueryParams, void>(`/api/v1/releases`, props);


export interface PatchApiV1ReleasesIdPathParams {
  /**
   * Release ID
   */
  id: string
}

export type PatchApiV1ReleasesIdProps = Omit<MutateProps<Release, APIError, void, void, PatchApiV1ReleasesIdPathParams>, "path" | "verb"> & PatchApiV1ReleasesIdPathParams;

/**
 * Update a release
 */
export const PatchApiV1ReleasesId = ({id, ...props}: PatchApiV1ReleasesIdProps) => (
  <Mutate<Release, APIError, void, void, PatchApiV1ReleasesIdPathParams>
    verb="PATCH"
    path={`/api/v1/releases/${id}`}
    
    {...props}
  />
);

export type UsePatchApiV1ReleasesIdProps = Omit<UseMutateProps<Release, APIError, void, void, PatchApiV1ReleasesIdPathParams>, "path" | "verb"> & PatchApiV1ReleasesIdPathParams;

/**
 * Update a release
 */
export const usePatchApiV1ReleasesId = ({id, ...props}: UsePatchApiV1ReleasesIdProps) => useMutate<Release, APIError, void, void, PatchApiV1ReleasesIdPathParams>("PATCH", (paramsInPath: PatchApiV1ReleasesIdPathParams) => `/api/v1/releases/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1ReleasesIdPathParams {
  /**
   * Release ID
   */
  id: string
}

export type GetApiV1ReleasesIdProps = Omit<GetProps<Release, APIError, void, GetApiV1ReleasesIdPathParams>, "path"> & GetApiV1ReleasesIdPathParams;

/**
 * Get a release
 */
export const GetApiV1ReleasesId = ({id, ...props}: GetApiV1ReleasesIdProps) => (
  <Get<Release, APIError, void, GetApiV1ReleasesIdPathParams>
    path={`/api/v1/releases/${id}`}
    
    {...props}
  />
);

export type UseGetApiV1ReleasesIdProps = Omit<UseGetProps<Release, APIError, void, GetApiV1ReleasesIdPathParams>, "path"> & GetApiV1ReleasesIdPathParams;

/**
 * Get a release
 */
export const useGetApiV1ReleasesId = ({id, ...props}: UseGetApiV1ReleasesIdProps) => useGet<Release, APIError, void, GetApiV1ReleasesIdPathParams>((paramsInPath: GetApiV1ReleasesIdPathParams) => `/api/v1/releases/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type PostApiV1ResourceChangeRequestsProps = Omit<MutateProps<ResourceChangeRequest, unknown, void, void, void>, "path" | "verb">;

/**
 * Create a resource change request
 */
export const PostApiV1ResourceChangeRequests = (props: PostApiV1ResourceChangeRequestsProps) => (
  <Mutate<ResourceChangeRequest, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/resource_change_requests`}
    
    {...props}
  />
);

export type UsePostApiV1ResourceChangeRequestsProps = Omit<UseMutateProps<ResourceChangeRequest, unknown, void, void, void>, "path" | "verb">;

/**
 * Create a resource change request
 */
export const usePostApiV1ResourceChangeRequests = (props: UsePostApiV1ResourceChangeRequestsProps) => useMutate<ResourceChangeRequest, unknown, void, void, void>("POST", `/api/v1/resource_change_requests`, props);


export interface GetApiV1ResourceChangeRequestsResourceIdPathParams {
  resource_id: string
}

export type GetApiV1ResourceChangeRequestsResourceIdProps = Omit<GetProps<ResourceChangeRequest, unknown, void, GetApiV1ResourceChangeRequestsResourceIdPathParams>, "path"> & GetApiV1ResourceChangeRequestsResourceIdPathParams;

/**
 * Get a resource change request
 */
export const GetApiV1ResourceChangeRequestsResourceId = ({resource_id, ...props}: GetApiV1ResourceChangeRequestsResourceIdProps) => (
  <Get<ResourceChangeRequest, unknown, void, GetApiV1ResourceChangeRequestsResourceIdPathParams>
    path={`/api/v1/resource_change_requests/${resource_id}`}
    
    {...props}
  />
);

export type UseGetApiV1ResourceChangeRequestsResourceIdProps = Omit<UseGetProps<ResourceChangeRequest, unknown, void, GetApiV1ResourceChangeRequestsResourceIdPathParams>, "path"> & GetApiV1ResourceChangeRequestsResourceIdPathParams;

/**
 * Get a resource change request
 */
export const useGetApiV1ResourceChangeRequestsResourceId = ({resource_id, ...props}: UseGetApiV1ResourceChangeRequestsResourceIdProps) => useGet<ResourceChangeRequest, unknown, void, GetApiV1ResourceChangeRequestsResourceIdPathParams>((paramsInPath: GetApiV1ResourceChangeRequestsResourceIdPathParams) => `/api/v1/resource_change_requests/${paramsInPath.resource_id}`, {  pathParams: { resource_id }, ...props });


export type DeleteApiV1ResourceChangeRequestsIdProps = Omit<MutateProps<ResourceChangeRequest, unknown, void, number, void>, "path" | "verb">;

/**
 * Delete a resource change request
 */
export const DeleteApiV1ResourceChangeRequestsId = (props: DeleteApiV1ResourceChangeRequestsIdProps) => (
  <Mutate<ResourceChangeRequest, unknown, void, number, void>
    verb="DELETE"
    path={`/api/v1/resource_change_requests`}
    
    {...props}
  />
);

export type UseDeleteApiV1ResourceChangeRequestsIdProps = Omit<UseMutateProps<ResourceChangeRequest, unknown, void, number, void>, "path" | "verb">;

/**
 * Delete a resource change request
 */
export const useDeleteApiV1ResourceChangeRequestsId = (props: UseDeleteApiV1ResourceChangeRequestsIdProps) => useMutate<ResourceChangeRequest, unknown, void, number, void>("DELETE", `/api/v1/resource_change_requests`, {   ...props });


export interface GetApiV1ResourceMetadataSearchQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  query: string;
}

export type GetApiV1ResourceMetadataSearchProps = Omit<GetProps<ResourcesSearchResults, unknown, GetApiV1ResourceMetadataSearchQueryParams, void>, "path">;

/**
 * Search the current user's started resources by title and keywords
 */
export const GetApiV1ResourceMetadataSearch = (props: GetApiV1ResourceMetadataSearchProps) => (
  <Get<ResourcesSearchResults, unknown, GetApiV1ResourceMetadataSearchQueryParams, void>
    path={`/api/v1/resource_metadata/search`}
    
    {...props}
  />
);

export type UseGetApiV1ResourceMetadataSearchProps = Omit<UseGetProps<ResourcesSearchResults, unknown, GetApiV1ResourceMetadataSearchQueryParams, void>, "path">;

/**
 * Search the current user's started resources by title and keywords
 */
export const useGetApiV1ResourceMetadataSearch = (props: UseGetApiV1ResourceMetadataSearchProps) => useGet<ResourcesSearchResults, unknown, GetApiV1ResourceMetadataSearchQueryParams, void>(`/api/v1/resource_metadata/search`, props);


export type PostApiV1ResourceMetadataRemoveInvalidMetadataProps = Omit<MutateProps<BulkOperation, unknown, void, void, void>, "path" | "verb">;

/**
 * Starts jobs to remove invalid metadata from the current user's started resources
 */
export const PostApiV1ResourceMetadataRemoveInvalidMetadata = (props: PostApiV1ResourceMetadataRemoveInvalidMetadataProps) => (
  <Mutate<BulkOperation, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/resource_metadata/remove_invalid_metadata`}
    
    {...props}
  />
);

export type UsePostApiV1ResourceMetadataRemoveInvalidMetadataProps = Omit<UseMutateProps<BulkOperation, unknown, void, void, void>, "path" | "verb">;

/**
 * Starts jobs to remove invalid metadata from the current user's started resources
 */
export const usePostApiV1ResourceMetadataRemoveInvalidMetadata = (props: UsePostApiV1ResourceMetadataRemoveInvalidMetadataProps) => useMutate<BulkOperation, unknown, void, void, void>("POST", `/api/v1/resource_metadata/remove_invalid_metadata`, props);


export type GetApiV1ResourceMetadataInvalidMetadataCountProps = Omit<GetProps<ResponsesSingleItemCount, unknown, void, void>, "path">;

/**
 * Get the count of invalid started resources for a contributor
 */
export const GetApiV1ResourceMetadataInvalidMetadataCount = (props: GetApiV1ResourceMetadataInvalidMetadataCountProps) => (
  <Get<ResponsesSingleItemCount, unknown, void, void>
    path={`/api/v1/resource_metadata/invalid_metadata_count`}
    
    {...props}
  />
);

export type UseGetApiV1ResourceMetadataInvalidMetadataCountProps = Omit<UseGetProps<ResponsesSingleItemCount, unknown, void, void>, "path">;

/**
 * Get the count of invalid started resources for a contributor
 */
export const useGetApiV1ResourceMetadataInvalidMetadataCount = (props: UseGetApiV1ResourceMetadataInvalidMetadataCountProps) => useGet<ResponsesSingleItemCount, unknown, void, void>(`/api/v1/resource_metadata/invalid_metadata_count`, props);


export type GetApiV1ResourceMetadataCsvProps = Omit<GetProps<void, unknown, void, void>, "path">;

/**
 * Get metadata for many resources with a csv
 */
export const GetApiV1ResourceMetadataCsv = (props: GetApiV1ResourceMetadataCsvProps) => (
  <Get<void, unknown, void, void>
    path={`/api/v1/resource_metadata/csv`}
    
    {...props}
  />
);

export type UseGetApiV1ResourceMetadataCsvProps = Omit<UseGetProps<void, unknown, void, void>, "path">;

/**
 * Get metadata for many resources with a csv
 */
export const useGetApiV1ResourceMetadataCsv = (props: UseGetApiV1ResourceMetadataCsvProps) => useGet<void, unknown, void, void>(`/api/v1/resource_metadata/csv`, props);


export type PostApiV1ResourceMetadataProps = Omit<MutateProps<void, APIError, void, PostApiV1ResourceMetadata, void>, "path" | "verb">;

/**
 * Update metadata for many resources with a csv
 */
export const PostApiV1ResourceMetadata = (props: PostApiV1ResourceMetadataProps) => (
  <Mutate<void, APIError, void, PostApiV1ResourceMetadata, void>
    verb="POST"
    path={`/api/v1/resource_metadata`}
    
    {...props}
  />
);

export type UsePostApiV1ResourceMetadataProps = Omit<UseMutateProps<void, APIError, void, PostApiV1ResourceMetadata, void>, "path" | "verb">;

/**
 * Update metadata for many resources with a csv
 */
export const usePostApiV1ResourceMetadata = (props: UsePostApiV1ResourceMetadataProps) => useMutate<void, APIError, void, PostApiV1ResourceMetadata, void>("POST", `/api/v1/resource_metadata`, props);


export type GetApiV1ResourceMetadataProcessingCsvFilesProps = Omit<GetProps<ResponsesJobStatus, unknown, void, void>, "path">;

/**
 * indicate whether there are csv files with resource metadata still processing for this user
 */
export const GetApiV1ResourceMetadataProcessingCsvFiles = (props: GetApiV1ResourceMetadataProcessingCsvFilesProps) => (
  <Get<ResponsesJobStatus, unknown, void, void>
    path={`/api/v1/resource_metadata/processing_csv_files`}
    
    {...props}
  />
);

export type UseGetApiV1ResourceMetadataProcessingCsvFilesProps = Omit<UseGetProps<ResponsesJobStatus, unknown, void, void>, "path">;

/**
 * indicate whether there are csv files with resource metadata still processing for this user
 */
export const useGetApiV1ResourceMetadataProcessingCsvFiles = (props: UseGetApiV1ResourceMetadataProcessingCsvFilesProps) => useGet<ResponsesJobStatus, unknown, void, void>(`/api/v1/resource_metadata/processing_csv_files`, props);


export interface GetApiV1ResourceMetadataTemplatesQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export type GetApiV1ResourceMetadataTemplatesProps = Omit<GetProps<ResourceMetadataTemplate[], unknown, GetApiV1ResourceMetadataTemplatesQueryParams, void>, "path">;

/**
 * Get list of resource metadata templates associated to the current user
 */
export const GetApiV1ResourceMetadataTemplates = (props: GetApiV1ResourceMetadataTemplatesProps) => (
  <Get<ResourceMetadataTemplate[], unknown, GetApiV1ResourceMetadataTemplatesQueryParams, void>
    path={`/api/v1/resource_metadata_templates`}
    
    {...props}
  />
);

export type UseGetApiV1ResourceMetadataTemplatesProps = Omit<UseGetProps<ResourceMetadataTemplate[], unknown, GetApiV1ResourceMetadataTemplatesQueryParams, void>, "path">;

/**
 * Get list of resource metadata templates associated to the current user
 */
export const useGetApiV1ResourceMetadataTemplates = (props: UseGetApiV1ResourceMetadataTemplatesProps) => useGet<ResourceMetadataTemplate[], unknown, GetApiV1ResourceMetadataTemplatesQueryParams, void>(`/api/v1/resource_metadata_templates`, props);


export type PostApiV1ResourceMetadataTemplatesProps = Omit<MutateProps<ResourceMetadataTemplate, unknown, void, PostApiV1ResourceMetadataTemplates, void>, "path" | "verb">;

/**
 * Create a new resource metadata template
 */
export const PostApiV1ResourceMetadataTemplates = (props: PostApiV1ResourceMetadataTemplatesProps) => (
  <Mutate<ResourceMetadataTemplate, unknown, void, PostApiV1ResourceMetadataTemplates, void>
    verb="POST"
    path={`/api/v1/resource_metadata_templates`}
    
    {...props}
  />
);

export type UsePostApiV1ResourceMetadataTemplatesProps = Omit<UseMutateProps<ResourceMetadataTemplate, unknown, void, PostApiV1ResourceMetadataTemplates, void>, "path" | "verb">;

/**
 * Create a new resource metadata template
 */
export const usePostApiV1ResourceMetadataTemplates = (props: UsePostApiV1ResourceMetadataTemplatesProps) => useMutate<ResourceMetadataTemplate, unknown, void, PostApiV1ResourceMetadataTemplates, void>("POST", `/api/v1/resource_metadata_templates`, props);


export type DeleteApiV1ResourceMetadataTemplatesIdProps = Omit<MutateProps<ResourceMetadataTemplate, unknown, void, string, void>, "path" | "verb">;

/**
 * Delete a resource metadata template
 */
export const DeleteApiV1ResourceMetadataTemplatesId = (props: DeleteApiV1ResourceMetadataTemplatesIdProps) => (
  <Mutate<ResourceMetadataTemplate, unknown, void, string, void>
    verb="DELETE"
    path={`/api/v1/resource_metadata_templates`}
    
    {...props}
  />
);

export type UseDeleteApiV1ResourceMetadataTemplatesIdProps = Omit<UseMutateProps<ResourceMetadataTemplate, unknown, void, string, void>, "path" | "verb">;

/**
 * Delete a resource metadata template
 */
export const useDeleteApiV1ResourceMetadataTemplatesId = (props: UseDeleteApiV1ResourceMetadataTemplatesIdProps) => useMutate<ResourceMetadataTemplate, unknown, void, string, void>("DELETE", `/api/v1/resource_metadata_templates`, {   ...props });


export interface PatchApiV1ResourceMetadataTemplatesIdPathParams {
  /**
   * Resource metadata template id
   */
  id: string
}

export type PatchApiV1ResourceMetadataTemplatesIdProps = Omit<MutateProps<ResourceMetadataTemplate, unknown, void, PatchApiV1ResourceMetadataTemplatesId, PatchApiV1ResourceMetadataTemplatesIdPathParams>, "path" | "verb"> & PatchApiV1ResourceMetadataTemplatesIdPathParams;

/**
 * Update a resource metadata template
 */
export const PatchApiV1ResourceMetadataTemplatesId = ({id, ...props}: PatchApiV1ResourceMetadataTemplatesIdProps) => (
  <Mutate<ResourceMetadataTemplate, unknown, void, PatchApiV1ResourceMetadataTemplatesId, PatchApiV1ResourceMetadataTemplatesIdPathParams>
    verb="PATCH"
    path={`/api/v1/resource_metadata_templates/${id}`}
    
    {...props}
  />
);

export type UsePatchApiV1ResourceMetadataTemplatesIdProps = Omit<UseMutateProps<ResourceMetadataTemplate, unknown, void, PatchApiV1ResourceMetadataTemplatesId, PatchApiV1ResourceMetadataTemplatesIdPathParams>, "path" | "verb"> & PatchApiV1ResourceMetadataTemplatesIdPathParams;

/**
 * Update a resource metadata template
 */
export const usePatchApiV1ResourceMetadataTemplatesId = ({id, ...props}: UsePatchApiV1ResourceMetadataTemplatesIdProps) => useMutate<ResourceMetadataTemplate, unknown, void, PatchApiV1ResourceMetadataTemplatesId, PatchApiV1ResourceMetadataTemplatesIdPathParams>("PATCH", (paramsInPath: PatchApiV1ResourceMetadataTemplatesIdPathParams) => `/api/v1/resource_metadata_templates/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1ResourceMetadataTemplatesIdPathParams {
  /**
   * Resource metadata template id
   */
  id: string
}

export type GetApiV1ResourceMetadataTemplatesIdProps = Omit<GetProps<ResourceMetadataTemplate, unknown, void, GetApiV1ResourceMetadataTemplatesIdPathParams>, "path"> & GetApiV1ResourceMetadataTemplatesIdPathParams;

/**
 * Get a resource metadata template
 */
export const GetApiV1ResourceMetadataTemplatesId = ({id, ...props}: GetApiV1ResourceMetadataTemplatesIdProps) => (
  <Get<ResourceMetadataTemplate, unknown, void, GetApiV1ResourceMetadataTemplatesIdPathParams>
    path={`/api/v1/resource_metadata_templates/${id}`}
    
    {...props}
  />
);

export type UseGetApiV1ResourceMetadataTemplatesIdProps = Omit<UseGetProps<ResourceMetadataTemplate, unknown, void, GetApiV1ResourceMetadataTemplatesIdPathParams>, "path"> & GetApiV1ResourceMetadataTemplatesIdPathParams;

/**
 * Get a resource metadata template
 */
export const useGetApiV1ResourceMetadataTemplatesId = ({id, ...props}: UseGetApiV1ResourceMetadataTemplatesIdProps) => useGet<ResourceMetadataTemplate, unknown, void, GetApiV1ResourceMetadataTemplatesIdPathParams>((paramsInPath: GetApiV1ResourceMetadataTemplatesIdPathParams) => `/api/v1/resource_metadata_templates/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetApiV1ResourceMetricsAverageEarningsPerFileProps = Omit<GetProps<ResponsesSingleItemCount, unknown, void, void>, "path">;

/**
 * Average earnings per file for a contributor in cents
 */
export const GetApiV1ResourceMetricsAverageEarningsPerFile = (props: GetApiV1ResourceMetricsAverageEarningsPerFileProps) => (
  <Get<ResponsesSingleItemCount, unknown, void, void>
    path={`/api/v1/resource_metrics/average_earnings_per_file`}
    
    {...props}
  />
);

export type UseGetApiV1ResourceMetricsAverageEarningsPerFileProps = Omit<UseGetProps<ResponsesSingleItemCount, unknown, void, void>, "path">;

/**
 * Average earnings per file for a contributor in cents
 */
export const useGetApiV1ResourceMetricsAverageEarningsPerFile = (props: UseGetApiV1ResourceMetricsAverageEarningsPerFileProps) => useGet<ResponsesSingleItemCount, unknown, void, void>(`/api/v1/resource_metrics/average_earnings_per_file`, props);


export interface GetApiV1ResourceMetricsQueryParams {
  sort_field?: "all_time_earnings" | "created_at";
  sort_dir?: "asc" | "desc";
  /**
   * Filter by category name
   */
  category?: string;
  license_type?: "free" | "pro" | "editorial";
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export type GetApiV1ResourceMetricsProps = Omit<GetProps<ResourceMetric[], unknown, GetApiV1ResourceMetricsQueryParams, void>, "path">;

/**
 * List of resources with earnings data
 */
export const GetApiV1ResourceMetrics = (props: GetApiV1ResourceMetricsProps) => (
  <Get<ResourceMetric[], unknown, GetApiV1ResourceMetricsQueryParams, void>
    path={`/api/v1/resource_metrics`}
    
    {...props}
  />
);

export type UseGetApiV1ResourceMetricsProps = Omit<UseGetProps<ResourceMetric[], unknown, GetApiV1ResourceMetricsQueryParams, void>, "path">;

/**
 * List of resources with earnings data
 */
export const useGetApiV1ResourceMetrics = (props: UseGetApiV1ResourceMetricsProps) => useGet<ResourceMetric[], unknown, GetApiV1ResourceMetricsQueryParams, void>(`/api/v1/resource_metrics`, props);


export type PostApiV1SessionResendTwoFactorProps = Omit<MutateProps<Session, unknown, void, void, void>, "path" | "verb">;

/**
 * Resend A New 2FA
 */
export const PostApiV1SessionResendTwoFactor = (props: PostApiV1SessionResendTwoFactorProps) => (
  <Mutate<Session, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/session/resend_two_factor`}
    
    {...props}
  />
);

export type UsePostApiV1SessionResendTwoFactorProps = Omit<UseMutateProps<Session, unknown, void, void, void>, "path" | "verb">;

/**
 * Resend A New 2FA
 */
export const usePostApiV1SessionResendTwoFactor = (props: UsePostApiV1SessionResendTwoFactorProps) => useMutate<Session, unknown, void, void, void>("POST", `/api/v1/session/resend_two_factor`, props);


export type PostApiV1SessionTwoFactorProps = Omit<MutateProps<Session, unknown, void, PostApiV1SessionTwoFactor, void>, "path" | "verb">;

/**
 * Login with 2FA
 */
export const PostApiV1SessionTwoFactor = (props: PostApiV1SessionTwoFactorProps) => (
  <Mutate<Session, unknown, void, PostApiV1SessionTwoFactor, void>
    verb="POST"
    path={`/api/v1/session/two_factor`}
    
    {...props}
  />
);

export type UsePostApiV1SessionTwoFactorProps = Omit<UseMutateProps<Session, unknown, void, PostApiV1SessionTwoFactor, void>, "path" | "verb">;

/**
 * Login with 2FA
 */
export const usePostApiV1SessionTwoFactor = (props: UsePostApiV1SessionTwoFactorProps) => useMutate<Session, unknown, void, PostApiV1SessionTwoFactor, void>("POST", `/api/v1/session/two_factor`, props);


export type PostApiV1SessionSwitchUserProps = Omit<MutateProps<Session, unknown, void, PostApiV1SessionSwitchUser, void>, "path" | "verb">;

/**
 * Login as another user
 */
export const PostApiV1SessionSwitchUser = (props: PostApiV1SessionSwitchUserProps) => (
  <Mutate<Session, unknown, void, PostApiV1SessionSwitchUser, void>
    verb="POST"
    path={`/api/v1/session/switch_user`}
    
    {...props}
  />
);

export type UsePostApiV1SessionSwitchUserProps = Omit<UseMutateProps<Session, unknown, void, PostApiV1SessionSwitchUser, void>, "path" | "verb">;

/**
 * Login as another user
 */
export const usePostApiV1SessionSwitchUser = (props: UsePostApiV1SessionSwitchUserProps) => useMutate<Session, unknown, void, PostApiV1SessionSwitchUser, void>("POST", `/api/v1/session/switch_user`, props);


export type PostApiV1SessionProps = Omit<MutateProps<Session, unknown, void, PostApiV1Session, void>, "path" | "verb">;

/**
 * Create a new user session
 */
export const PostApiV1Session = (props: PostApiV1SessionProps) => (
  <Mutate<Session, unknown, void, PostApiV1Session, void>
    verb="POST"
    path={`/api/v1/session`}
    
    {...props}
  />
);

export type UsePostApiV1SessionProps = Omit<UseMutateProps<Session, unknown, void, PostApiV1Session, void>, "path" | "verb">;

/**
 * Create a new user session
 */
export const usePostApiV1Session = (props: UsePostApiV1SessionProps) => useMutate<Session, unknown, void, PostApiV1Session, void>("POST", `/api/v1/session`, props);


export type GetApiV1SessionProps = Omit<GetProps<Session, unknown, void, void>, "path">;

/**
 * current session data
 */
export const GetApiV1Session = (props: GetApiV1SessionProps) => (
  <Get<Session, unknown, void, void>
    path={`/api/v1/session`}
    
    {...props}
  />
);

export type UseGetApiV1SessionProps = Omit<UseGetProps<Session, unknown, void, void>, "path">;

/**
 * current session data
 */
export const useGetApiV1Session = (props: UseGetApiV1SessionProps) => useGet<Session, unknown, void, void>(`/api/v1/session`, props);


export type GetApiV1SessionFilestackCredentialsProps = Omit<GetProps<void, unknown, void, void>, "path">;

export const GetApiV1SessionFilestackCredentials = (props: GetApiV1SessionFilestackCredentialsProps) => (
  <Get<void, unknown, void, void>
    path={`/api/v1/session/filestack_credentials`}
    
    {...props}
  />
);

export type UseGetApiV1SessionFilestackCredentialsProps = Omit<UseGetProps<void, unknown, void, void>, "path">;

export const useGetApiV1SessionFilestackCredentials = (props: UseGetApiV1SessionFilestackCredentialsProps) => useGet<void, unknown, void, void>(`/api/v1/session/filestack_credentials`, props);


export type PutApiV1TaxFormSubmissionsProps = Omit<MutateProps<TaxFormSubmission, unknown, void, PutApiV1TaxFormSubmissions, void>, "path" | "verb">;

/**
 * Updates or creates a new tax form submission for the current user
 */
export const PutApiV1TaxFormSubmissions = (props: PutApiV1TaxFormSubmissionsProps) => (
  <Mutate<TaxFormSubmission, unknown, void, PutApiV1TaxFormSubmissions, void>
    verb="PUT"
    path={`/api/v1/tax_form_submissions`}
    
    {...props}
  />
);

export type UsePutApiV1TaxFormSubmissionsProps = Omit<UseMutateProps<TaxFormSubmission, unknown, void, PutApiV1TaxFormSubmissions, void>, "path" | "verb">;

/**
 * Updates or creates a new tax form submission for the current user
 */
export const usePutApiV1TaxFormSubmissions = (props: UsePutApiV1TaxFormSubmissionsProps) => useMutate<TaxFormSubmission, unknown, void, PutApiV1TaxFormSubmissions, void>("PUT", `/api/v1/tax_form_submissions`, props);


export type GetApiV1TaxFormSubmissionsProps = Omit<GetProps<TaxFormSubmission, unknown, void, void>, "path">;

/**
 * Gets the latest tax form submission for the current user
 */
export const GetApiV1TaxFormSubmissions = (props: GetApiV1TaxFormSubmissionsProps) => (
  <Get<TaxFormSubmission, unknown, void, void>
    path={`/api/v1/tax_form_submissions`}
    
    {...props}
  />
);

export type UseGetApiV1TaxFormSubmissionsProps = Omit<UseGetProps<TaxFormSubmission, unknown, void, void>, "path">;

/**
 * Gets the latest tax form submission for the current user
 */
export const useGetApiV1TaxFormSubmissions = (props: UseGetApiV1TaxFormSubmissionsProps) => useGet<TaxFormSubmission, unknown, void, void>(`/api/v1/tax_form_submissions`, props);


export type PostApiV1TermsSignProps = Omit<MutateProps<void, APIError, void, PostApiV1TermsSign, void>, "path" | "verb">;

/**
 * Sign Terms specified by id
 */
export const PostApiV1TermsSign = (props: PostApiV1TermsSignProps) => (
  <Mutate<void, APIError, void, PostApiV1TermsSign, void>
    verb="POST"
    path={`/api/v1/terms/sign`}
    
    {...props}
  />
);

export type UsePostApiV1TermsSignProps = Omit<UseMutateProps<void, APIError, void, PostApiV1TermsSign, void>, "path" | "verb">;

/**
 * Sign Terms specified by id
 */
export const usePostApiV1TermsSign = (props: UsePostApiV1TermsSignProps) => useMutate<void, APIError, void, PostApiV1TermsSign, void>("POST", `/api/v1/terms/sign`, props);


export type GetApiV1TermsMyCurrentProps = Omit<GetProps<Terms, unknown, void, void>, "path">;

/**
 * Current Terms for this User
 */
export const GetApiV1TermsMyCurrent = (props: GetApiV1TermsMyCurrentProps) => (
  <Get<Terms, unknown, void, void>
    path={`/api/v1/terms/my_current`}
    
    {...props}
  />
);

export type UseGetApiV1TermsMyCurrentProps = Omit<UseGetProps<Terms, unknown, void, void>, "path">;

/**
 * Current Terms for this User
 */
export const useGetApiV1TermsMyCurrent = (props: UseGetApiV1TermsMyCurrentProps) => useGet<Terms, unknown, void, void>(`/api/v1/terms/my_current`, props);


export interface GetApiV1TopContributorsQueryParams {
  /**
   * Filter contributors by content type
   */
  content_type: "vector" | "photo" | "video" | "all";
  /**
   * Filter contributors by program type
   */
  program_type: "pro" | "free" | "all";
  /**
   * Filter contributors by time period
   */
  period: "week" | "month" | "all";
  license_type?: "all" | "editorial" | "pro" | "free";
}

export type GetApiV1TopContributorsProps = Omit<GetProps<TopContributor[], unknown, GetApiV1TopContributorsQueryParams, void>, "path">;

/**
 * List top contributors by content type, program type
 */
export const GetApiV1TopContributors = (props: GetApiV1TopContributorsProps) => (
  <Get<TopContributor[], unknown, GetApiV1TopContributorsQueryParams, void>
    path={`/api/v1/top_contributors`}
    
    {...props}
  />
);

export type UseGetApiV1TopContributorsProps = Omit<UseGetProps<TopContributor[], unknown, GetApiV1TopContributorsQueryParams, void>, "path">;

/**
 * List top contributors by content type, program type
 */
export const useGetApiV1TopContributors = (props: UseGetApiV1TopContributorsProps) => useGet<TopContributor[], unknown, GetApiV1TopContributorsQueryParams, void>(`/api/v1/top_contributors`, props);


export interface GetApiV1TrendingTermsWeeklyQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Filter terms by content type
   */
  content_type: "vector" | "photo" | "video" | "brush";
  /**
   * Filter terms by trending direction
   */
  trend_direction: "winners" | "losers";
}

export type GetApiV1TrendingTermsWeeklyProps = Omit<GetProps<void, unknown, GetApiV1TrendingTermsWeeklyQueryParams, void>, "path">;

export const GetApiV1TrendingTermsWeekly = (props: GetApiV1TrendingTermsWeeklyProps) => (
  <Get<void, unknown, GetApiV1TrendingTermsWeeklyQueryParams, void>
    path={`/api/v1/trending_terms/weekly`}
    
    {...props}
  />
);

export type UseGetApiV1TrendingTermsWeeklyProps = Omit<UseGetProps<void, unknown, GetApiV1TrendingTermsWeeklyQueryParams, void>, "path">;

export const useGetApiV1TrendingTermsWeekly = (props: UseGetApiV1TrendingTermsWeeklyProps) => useGet<void, unknown, GetApiV1TrendingTermsWeeklyQueryParams, void>(`/api/v1/trending_terms/weekly`, props);


export interface GetApiV1TrendingTermsMonthlyQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Filter terms by content type
   */
  content_type: "vector" | "photo" | "video" | "brush";
  /**
   * Filter terms by trending direction
   */
  trend_direction: "winners" | "losers";
}

export type GetApiV1TrendingTermsMonthlyProps = Omit<GetProps<TrendingTerm[], unknown, GetApiV1TrendingTermsMonthlyQueryParams, void>, "path">;

/**
 * Lists monthly trending resources by content type and trending direction
 */
export const GetApiV1TrendingTermsMonthly = (props: GetApiV1TrendingTermsMonthlyProps) => (
  <Get<TrendingTerm[], unknown, GetApiV1TrendingTermsMonthlyQueryParams, void>
    path={`/api/v1/trending_terms/monthly`}
    
    {...props}
  />
);

export type UseGetApiV1TrendingTermsMonthlyProps = Omit<UseGetProps<TrendingTerm[], unknown, GetApiV1TrendingTermsMonthlyQueryParams, void>, "path">;

/**
 * Lists monthly trending resources by content type and trending direction
 */
export const useGetApiV1TrendingTermsMonthly = (props: UseGetApiV1TrendingTermsMonthlyProps) => useGet<TrendingTerm[], unknown, GetApiV1TrendingTermsMonthlyQueryParams, void>(`/api/v1/trending_terms/monthly`, props);


export interface GetApiV1TrendingResourcesQueryParams {
  content_type: "vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic" | "all";
  program_type: "pro" | "free" | "all";
  period: "week" | "month";
  metric: "views";
  trending_type: "popular" | "newest";
  license_type?: "all" | "editorial" | "pro" | "free";
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export type GetApiV1TrendingResourcesProps = Omit<GetProps<Resource[], unknown, GetApiV1TrendingResourcesQueryParams, void>, "path">;

/**
 * list trending resources
 */
export const GetApiV1TrendingResources = (props: GetApiV1TrendingResourcesProps) => (
  <Get<Resource[], unknown, GetApiV1TrendingResourcesQueryParams, void>
    path={`/api/v1/trending_resources`}
    
    {...props}
  />
);

export type UseGetApiV1TrendingResourcesProps = Omit<UseGetProps<Resource[], unknown, GetApiV1TrendingResourcesQueryParams, void>, "path">;

/**
 * list trending resources
 */
export const useGetApiV1TrendingResources = (props: UseGetApiV1TrendingResourcesProps) => useGet<Resource[], unknown, GetApiV1TrendingResourcesQueryParams, void>(`/api/v1/trending_resources`, props);


export type GetApiV1UiMessagesProps = Omit<GetProps<UiMessage[], unknown, void, void>, "path">;

/**
 * Get UI messages
 */
export const GetApiV1UiMessages = (props: GetApiV1UiMessagesProps) => (
  <Get<UiMessage[], unknown, void, void>
    path={`/api/v1/ui_messages`}
    
    {...props}
  />
);

export type UseGetApiV1UiMessagesProps = Omit<UseGetProps<UiMessage[], unknown, void, void>, "path">;

/**
 * Get UI messages
 */
export const useGetApiV1UiMessages = (props: UseGetApiV1UiMessagesProps) => useGet<UiMessage[], unknown, void, void>(`/api/v1/ui_messages`, props);


export interface GetApiV1UiMessagesKeyPathParams {
  /**
   * UiMessage key
   */
  key: string
}

export type GetApiV1UiMessagesKeyProps = Omit<GetProps<UiMessage, unknown, void, GetApiV1UiMessagesKeyPathParams>, "path"> & GetApiV1UiMessagesKeyPathParams;

/**
 * Get a UI message
 */
export const GetApiV1UiMessagesKey = ({key, ...props}: GetApiV1UiMessagesKeyProps) => (
  <Get<UiMessage, unknown, void, GetApiV1UiMessagesKeyPathParams>
    path={`/api/v1/ui_messages/${key}`}
    
    {...props}
  />
);

export type UseGetApiV1UiMessagesKeyProps = Omit<UseGetProps<UiMessage, unknown, void, GetApiV1UiMessagesKeyPathParams>, "path"> & GetApiV1UiMessagesKeyPathParams;

/**
 * Get a UI message
 */
export const useGetApiV1UiMessagesKey = ({key, ...props}: UseGetApiV1UiMessagesKeyProps) => useGet<UiMessage, unknown, void, GetApiV1UiMessagesKeyPathParams>((paramsInPath: GetApiV1UiMessagesKeyPathParams) => `/api/v1/ui_messages/${paramsInPath.key}`, {  pathParams: { key }, ...props });


export type GetApiV1UploadSettingsProps = Omit<GetProps<UploadSetting[], unknown, void, void>, "path">;

/**
 * The list of upload_settings for the current_user
 */
export const GetApiV1UploadSettings = (props: GetApiV1UploadSettingsProps) => (
  <Get<UploadSetting[], unknown, void, void>
    path={`/api/v1/upload_settings`}
    
    {...props}
  />
);

export type UseGetApiV1UploadSettingsProps = Omit<UseGetProps<UploadSetting[], unknown, void, void>, "path">;

/**
 * The list of upload_settings for the current_user
 */
export const useGetApiV1UploadSettings = (props: UseGetApiV1UploadSettingsProps) => useGet<UploadSetting[], unknown, void, void>(`/api/v1/upload_settings`, props);


export type PutApiV1UploadSettingsProps = Omit<MutateProps<UploadSetting, unknown, void, PutApiV1UploadSettings, void>, "path" | "verb">;

/**
 * Upsert an upload setting
 */
export const PutApiV1UploadSettings = (props: PutApiV1UploadSettingsProps) => (
  <Mutate<UploadSetting, unknown, void, PutApiV1UploadSettings, void>
    verb="PUT"
    path={`/api/v1/upload_settings`}
    
    {...props}
  />
);

export type UsePutApiV1UploadSettingsProps = Omit<UseMutateProps<UploadSetting, unknown, void, PutApiV1UploadSettings, void>, "path" | "verb">;

/**
 * Upsert an upload setting
 */
export const usePutApiV1UploadSettings = (props: UsePutApiV1UploadSettingsProps) => useMutate<UploadSetting, unknown, void, PutApiV1UploadSettings, void>("PUT", `/api/v1/upload_settings`, props);


export type PatchApiV1UsersPasswordUnlockProps = Omit<MutateProps<void, unknown, void, PatchApiV1UsersPasswordUnlock, void>, "path" | "verb">;

/**
 * Updates the password and unlocks the account of the user found with the provided token
 */
export const PatchApiV1UsersPasswordUnlock = (props: PatchApiV1UsersPasswordUnlockProps) => (
  <Mutate<void, unknown, void, PatchApiV1UsersPasswordUnlock, void>
    verb="PATCH"
    path={`/api/v1/users/password/unlock`}
    
    {...props}
  />
);

export type UsePatchApiV1UsersPasswordUnlockProps = Omit<UseMutateProps<void, unknown, void, PatchApiV1UsersPasswordUnlock, void>, "path" | "verb">;

/**
 * Updates the password and unlocks the account of the user found with the provided token
 */
export const usePatchApiV1UsersPasswordUnlock = (props: UsePatchApiV1UsersPasswordUnlockProps) => useMutate<void, unknown, void, PatchApiV1UsersPasswordUnlock, void>("PATCH", `/api/v1/users/password/unlock`, props);


export type PatchApiV1UsersPasswordUpdateProps = Omit<MutateProps<void, unknown, void, PatchApiV1UsersPasswordUpdate, void>, "path" | "verb">;

/**
 * Updates the password of the user found with the provided token
 */
export const PatchApiV1UsersPasswordUpdate = (props: PatchApiV1UsersPasswordUpdateProps) => (
  <Mutate<void, unknown, void, PatchApiV1UsersPasswordUpdate, void>
    verb="PATCH"
    path={`/api/v1/users/password/update`}
    
    {...props}
  />
);

export type UsePatchApiV1UsersPasswordUpdateProps = Omit<UseMutateProps<void, unknown, void, PatchApiV1UsersPasswordUpdate, void>, "path" | "verb">;

/**
 * Updates the password of the user found with the provided token
 */
export const usePatchApiV1UsersPasswordUpdate = (props: UsePatchApiV1UsersPasswordUpdateProps) => useMutate<void, unknown, void, PatchApiV1UsersPasswordUpdate, void>("PATCH", `/api/v1/users/password/update`, props);


export type PostApiV1UsersPasswordProps = Omit<MutateProps<void, unknown, void, PostApiV1UsersPassword, void>, "path" | "verb">;

/**
 * Sends reset password instructions. The resulting email will have a link with a reset token.
 */
export const PostApiV1UsersPassword = (props: PostApiV1UsersPasswordProps) => (
  <Mutate<void, unknown, void, PostApiV1UsersPassword, void>
    verb="POST"
    path={`/api/v1/users/password`}
    
    {...props}
  />
);

export type UsePostApiV1UsersPasswordProps = Omit<UseMutateProps<void, unknown, void, PostApiV1UsersPassword, void>, "path" | "verb">;

/**
 * Sends reset password instructions. The resulting email will have a link with a reset token.
 */
export const usePostApiV1UsersPassword = (props: UsePostApiV1UsersPasswordProps) => useMutate<void, unknown, void, PostApiV1UsersPassword, void>("POST", `/api/v1/users/password`, props);


export interface GetApiV1UsersIdSubmissionQuotasPathParams {
  /**
   * User import guid
   */
  id: string
}

export type GetApiV1UsersIdSubmissionQuotasProps = Omit<GetProps<SubmissionQuota[], unknown, void, GetApiV1UsersIdSubmissionQuotasPathParams>, "path"> & GetApiV1UsersIdSubmissionQuotasPathParams;

/**
 * Retrieve submission quotas for a user
 */
export const GetApiV1UsersIdSubmissionQuotas = ({id, ...props}: GetApiV1UsersIdSubmissionQuotasProps) => (
  <Get<SubmissionQuota[], unknown, void, GetApiV1UsersIdSubmissionQuotasPathParams>
    path={`/api/v1/users/${id}/submission_quotas`}
    
    {...props}
  />
);

export type UseGetApiV1UsersIdSubmissionQuotasProps = Omit<UseGetProps<SubmissionQuota[], unknown, void, GetApiV1UsersIdSubmissionQuotasPathParams>, "path"> & GetApiV1UsersIdSubmissionQuotasPathParams;

/**
 * Retrieve submission quotas for a user
 */
export const useGetApiV1UsersIdSubmissionQuotas = ({id, ...props}: UseGetApiV1UsersIdSubmissionQuotasProps) => useGet<SubmissionQuota[], unknown, void, GetApiV1UsersIdSubmissionQuotasPathParams>((paramsInPath: GetApiV1UsersIdSubmissionQuotasPathParams) => `/api/v1/users/${paramsInPath.id}/submission_quotas`, {  pathParams: { id }, ...props });


export interface PatchApiV1UsersIdPathParams {
  id: number
}

export type PatchApiV1UsersIdProps = Omit<MutateProps<User, APIError, void, void, PatchApiV1UsersIdPathParams>, "path" | "verb"> & PatchApiV1UsersIdPathParams;

/**
 * Update user
 */
export const PatchApiV1UsersId = ({id, ...props}: PatchApiV1UsersIdProps) => (
  <Mutate<User, APIError, void, void, PatchApiV1UsersIdPathParams>
    verb="PATCH"
    path={`/api/v1/users/${id}`}
    
    {...props}
  />
);

export type UsePatchApiV1UsersIdProps = Omit<UseMutateProps<User, APIError, void, void, PatchApiV1UsersIdPathParams>, "path" | "verb"> & PatchApiV1UsersIdPathParams;

/**
 * Update user
 */
export const usePatchApiV1UsersId = ({id, ...props}: UsePatchApiV1UsersIdProps) => useMutate<User, APIError, void, void, PatchApiV1UsersIdPathParams>("PATCH", (paramsInPath: PatchApiV1UsersIdPathParams) => `/api/v1/users/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1UsersIdQueryParams {
  /**
   * Additional fields to retrieve
   */
  additional_fields?: ("resource_counts" | "average_rating" | "has_notes" | "contributor_score")[];
}

export interface GetApiV1UsersIdPathParams {
  /**
   * User import guid
   */
  id: string
}

export type GetApiV1UsersIdProps = Omit<GetProps<User, unknown, GetApiV1UsersIdQueryParams, GetApiV1UsersIdPathParams>, "path"> & GetApiV1UsersIdPathParams;

/**
 * Retrieve a single user by import_guid
 */
export const GetApiV1UsersId = ({id, ...props}: GetApiV1UsersIdProps) => (
  <Get<User, unknown, GetApiV1UsersIdQueryParams, GetApiV1UsersIdPathParams>
    path={`/api/v1/users/${id}`}
    
    {...props}
  />
);

export type UseGetApiV1UsersIdProps = Omit<UseGetProps<User, unknown, GetApiV1UsersIdQueryParams, GetApiV1UsersIdPathParams>, "path"> & GetApiV1UsersIdPathParams;

/**
 * Retrieve a single user by import_guid
 */
export const useGetApiV1UsersId = ({id, ...props}: UseGetApiV1UsersIdProps) => useGet<User, unknown, GetApiV1UsersIdQueryParams, GetApiV1UsersIdPathParams>((paramsInPath: GetApiV1UsersIdPathParams) => `/api/v1/users/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1UsersIdKeywordAssignmentsPathParams {
  /**
   * User import guid
   */
  id: string
}

export type GetApiV1UsersIdKeywordAssignmentsProps = Omit<GetProps<KeywordAssignment, unknown, void, GetApiV1UsersIdKeywordAssignmentsPathParams>, "path"> & GetApiV1UsersIdKeywordAssignmentsPathParams;

/**
 * Retrieve keyword assignments
 */
export const GetApiV1UsersIdKeywordAssignments = ({id, ...props}: GetApiV1UsersIdKeywordAssignmentsProps) => (
  <Get<KeywordAssignment, unknown, void, GetApiV1UsersIdKeywordAssignmentsPathParams>
    path={`/api/v1/users/${id}/keyword_assignments`}
    
    {...props}
  />
);

export type UseGetApiV1UsersIdKeywordAssignmentsProps = Omit<UseGetProps<KeywordAssignment, unknown, void, GetApiV1UsersIdKeywordAssignmentsPathParams>, "path"> & GetApiV1UsersIdKeywordAssignmentsPathParams;

/**
 * Retrieve keyword assignments
 */
export const useGetApiV1UsersIdKeywordAssignments = ({id, ...props}: UseGetApiV1UsersIdKeywordAssignmentsProps) => useGet<KeywordAssignment, unknown, void, GetApiV1UsersIdKeywordAssignmentsPathParams>((paramsInPath: GetApiV1UsersIdKeywordAssignmentsPathParams) => `/api/v1/users/${paramsInPath.id}/keyword_assignments`, {  pathParams: { id }, ...props });


export interface GetApiV1UsersRecentlySubmittedKeywordsQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export type GetApiV1UsersRecentlySubmittedKeywordsProps = Omit<GetProps<UserRecentKeywords[], unknown, GetApiV1UsersRecentlySubmittedKeywordsQueryParams, void>, "path">;

/**
 * Get the keywords used most recently by the current user in submitted resources
 */
export const GetApiV1UsersRecentlySubmittedKeywords = (props: GetApiV1UsersRecentlySubmittedKeywordsProps) => (
  <Get<UserRecentKeywords[], unknown, GetApiV1UsersRecentlySubmittedKeywordsQueryParams, void>
    path={`/api/v1/users/recently_submitted_keywords`}
    
    {...props}
  />
);

export type UseGetApiV1UsersRecentlySubmittedKeywordsProps = Omit<UseGetProps<UserRecentKeywords[], unknown, GetApiV1UsersRecentlySubmittedKeywordsQueryParams, void>, "path">;

/**
 * Get the keywords used most recently by the current user in submitted resources
 */
export const useGetApiV1UsersRecentlySubmittedKeywords = (props: UseGetApiV1UsersRecentlySubmittedKeywordsProps) => useGet<UserRecentKeywords[], unknown, GetApiV1UsersRecentlySubmittedKeywordsQueryParams, void>(`/api/v1/users/recently_submitted_keywords`, props);


export interface GetApiV1UsersQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Search query that will be matched against email or name
   */
  query?: string;
  /**
   * Filter users by role
   */
  role?: ("super_admin" | "limited_admin" | "finance_admin" | "art_director")[];
}

export type GetApiV1UsersProps = Omit<GetProps<User[], unknown, GetApiV1UsersQueryParams, void>, "path">;

/**
 * Searches for users
 */
export const GetApiV1Users = (props: GetApiV1UsersProps) => (
  <Get<User[], unknown, GetApiV1UsersQueryParams, void>
    path={`/api/v1/users`}
    
    {...props}
  />
);

export type UseGetApiV1UsersProps = Omit<UseGetProps<User[], unknown, GetApiV1UsersQueryParams, void>, "path">;

/**
 * Searches for users
 */
export const useGetApiV1Users = (props: UseGetApiV1UsersProps) => useGet<User[], unknown, GetApiV1UsersQueryParams, void>(`/api/v1/users`, props);


export type GetApiV1UsersPendingCountProps = Omit<GetProps<ResponsesSingleItemCount, unknown, void, void>, "path">;

/**
 * Get pending user count
 */
export const GetApiV1UsersPendingCount = (props: GetApiV1UsersPendingCountProps) => (
  <Get<ResponsesSingleItemCount, unknown, void, void>
    path={`/api/v1/users/pending/count`}
    
    {...props}
  />
);

export type UseGetApiV1UsersPendingCountProps = Omit<UseGetProps<ResponsesSingleItemCount, unknown, void, void>, "path">;

/**
 * Get pending user count
 */
export const useGetApiV1UsersPendingCount = (props: UseGetApiV1UsersPendingCountProps) => useGet<ResponsesSingleItemCount, unknown, void, void>(`/api/v1/users/pending/count`, props);


export type PatchApiV1UserProfileResetPaymentAccountInfoProps = Omit<MutateProps<void, unknown, void, PatchApiV1UserProfileResetPaymentAccountInfo, void>, "path" | "verb">;

/**
 * Reset proposed payment account and token
 */
export const PatchApiV1UserProfileResetPaymentAccountInfo = (props: PatchApiV1UserProfileResetPaymentAccountInfoProps) => (
  <Mutate<void, unknown, void, PatchApiV1UserProfileResetPaymentAccountInfo, void>
    verb="PATCH"
    path={`/api/v1/user_profile/reset_payment_account_info`}
    
    {...props}
  />
);

export type UsePatchApiV1UserProfileResetPaymentAccountInfoProps = Omit<UseMutateProps<void, unknown, void, PatchApiV1UserProfileResetPaymentAccountInfo, void>, "path" | "verb">;

/**
 * Reset proposed payment account and token
 */
export const usePatchApiV1UserProfileResetPaymentAccountInfo = (props: UsePatchApiV1UserProfileResetPaymentAccountInfoProps) => useMutate<void, unknown, void, PatchApiV1UserProfileResetPaymentAccountInfo, void>("PATCH", `/api/v1/user_profile/reset_payment_account_info`, props);


export type PatchApiV1UserProfileUpdatePaymentAccountProps = Omit<MutateProps<void, unknown, void, PatchApiV1UserProfileUpdatePaymentAccount, void>, "path" | "verb">;

/**
 * Updates the payment account email of the user found with the provided token
 */
export const PatchApiV1UserProfileUpdatePaymentAccount = (props: PatchApiV1UserProfileUpdatePaymentAccountProps) => (
  <Mutate<void, unknown, void, PatchApiV1UserProfileUpdatePaymentAccount, void>
    verb="PATCH"
    path={`/api/v1/user_profile/update_payment_account`}
    
    {...props}
  />
);

export type UsePatchApiV1UserProfileUpdatePaymentAccountProps = Omit<UseMutateProps<void, unknown, void, PatchApiV1UserProfileUpdatePaymentAccount, void>, "path" | "verb">;

/**
 * Updates the payment account email of the user found with the provided token
 */
export const usePatchApiV1UserProfileUpdatePaymentAccount = (props: UsePatchApiV1UserProfileUpdatePaymentAccountProps) => useMutate<void, unknown, void, PatchApiV1UserProfileUpdatePaymentAccount, void>("PATCH", `/api/v1/user_profile/update_payment_account`, props);


export type PatchApiV1UserProfileNewPaymentAccountProps = Omit<MutateProps<void, unknown, void, PatchApiV1UserProfileNewPaymentAccount, void>, "path" | "verb">;

/**
 * Sends payment account email change confirmation. The resulting email will have a link with a token.
 */
export const PatchApiV1UserProfileNewPaymentAccount = (props: PatchApiV1UserProfileNewPaymentAccountProps) => (
  <Mutate<void, unknown, void, PatchApiV1UserProfileNewPaymentAccount, void>
    verb="PATCH"
    path={`/api/v1/user_profile/new_payment_account`}
    
    {...props}
  />
);

export type UsePatchApiV1UserProfileNewPaymentAccountProps = Omit<UseMutateProps<void, unknown, void, PatchApiV1UserProfileNewPaymentAccount, void>, "path" | "verb">;

/**
 * Sends payment account email change confirmation. The resulting email will have a link with a token.
 */
export const usePatchApiV1UserProfileNewPaymentAccount = (props: UsePatchApiV1UserProfileNewPaymentAccountProps) => useMutate<void, unknown, void, PatchApiV1UserProfileNewPaymentAccount, void>("PATCH", `/api/v1/user_profile/new_payment_account`, props);


export type PostApiV1UserProfileRegenerateKeysProps = Omit<MutateProps<UserProfile, unknown, void, void, void>, "path" | "verb">;

/**
 * Regenerate the current user's external api keys
 */
export const PostApiV1UserProfileRegenerateKeys = (props: PostApiV1UserProfileRegenerateKeysProps) => (
  <Mutate<UserProfile, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/user_profile/regenerate_keys`}
    
    {...props}
  />
);

export type UsePostApiV1UserProfileRegenerateKeysProps = Omit<UseMutateProps<UserProfile, unknown, void, void, void>, "path" | "verb">;

/**
 * Regenerate the current user's external api keys
 */
export const usePostApiV1UserProfileRegenerateKeys = (props: UsePostApiV1UserProfileRegenerateKeysProps) => useMutate<UserProfile, unknown, void, void, void>("POST", `/api/v1/user_profile/regenerate_keys`, props);


export interface PatchApiV1UserProfileIdPathParams {
  id: string
}

export type PatchApiV1UserProfileIdProps = Omit<MutateProps<UserProfile, unknown, void, PatchApiV1UserProfileId, PatchApiV1UserProfileIdPathParams>, "path" | "verb"> & PatchApiV1UserProfileIdPathParams;

/**
 * update user profile
 */
export const PatchApiV1UserProfileId = ({id, ...props}: PatchApiV1UserProfileIdProps) => (
  <Mutate<UserProfile, unknown, void, PatchApiV1UserProfileId, PatchApiV1UserProfileIdPathParams>
    verb="PATCH"
    path={`/api/v1/user_profile/${id}`}
    
    {...props}
  />
);

export type UsePatchApiV1UserProfileIdProps = Omit<UseMutateProps<UserProfile, unknown, void, PatchApiV1UserProfileId, PatchApiV1UserProfileIdPathParams>, "path" | "verb"> & PatchApiV1UserProfileIdPathParams;

/**
 * update user profile
 */
export const usePatchApiV1UserProfileId = ({id, ...props}: UsePatchApiV1UserProfileIdProps) => useMutate<UserProfile, unknown, void, PatchApiV1UserProfileId, PatchApiV1UserProfileIdPathParams>("PATCH", (paramsInPath: PatchApiV1UserProfileIdPathParams) => `/api/v1/user_profile/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1UserProfileIdPathParams {
  id: string
}

export type GetApiV1UserProfileIdProps = Omit<GetProps<UserProfile, unknown, void, GetApiV1UserProfileIdPathParams>, "path"> & GetApiV1UserProfileIdPathParams;

/**
 * show full profile info for one user
 */
export const GetApiV1UserProfileId = ({id, ...props}: GetApiV1UserProfileIdProps) => (
  <Get<UserProfile, unknown, void, GetApiV1UserProfileIdPathParams>
    path={`/api/v1/user_profile/${id}`}
    
    {...props}
  />
);

export type UseGetApiV1UserProfileIdProps = Omit<UseGetProps<UserProfile, unknown, void, GetApiV1UserProfileIdPathParams>, "path"> & GetApiV1UserProfileIdPathParams;

/**
 * show full profile info for one user
 */
export const useGetApiV1UserProfileId = ({id, ...props}: UseGetApiV1UserProfileIdProps) => useGet<UserProfile, unknown, void, GetApiV1UserProfileIdPathParams>((paramsInPath: GetApiV1UserProfileIdPathParams) => `/api/v1/user_profile/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type PostApiV1WebImportsPresignedUrlProps = Omit<MutateProps<WebImportPresignedUrl, unknown, void, PostApiV1WebImportsPresignedUrl, void>, "path" | "verb">;

/**
 * Get pre-signed url for web upload
 */
export const PostApiV1WebImportsPresignedUrl = (props: PostApiV1WebImportsPresignedUrlProps) => (
  <Mutate<WebImportPresignedUrl, unknown, void, PostApiV1WebImportsPresignedUrl, void>
    verb="POST"
    path={`/api/v1/web_imports/presigned_url`}
    
    {...props}
  />
);

export type UsePostApiV1WebImportsPresignedUrlProps = Omit<UseMutateProps<WebImportPresignedUrl, unknown, void, PostApiV1WebImportsPresignedUrl, void>, "path" | "verb">;

/**
 * Get pre-signed url for web upload
 */
export const usePostApiV1WebImportsPresignedUrl = (props: UsePostApiV1WebImportsPresignedUrlProps) => useMutate<WebImportPresignedUrl, unknown, void, PostApiV1WebImportsPresignedUrl, void>("POST", `/api/v1/web_imports/presigned_url`, props);


export type PostApiV1WebUploadImportsProps = Omit<MutateProps<WebUploadImport, APIError, void, PostApiV1WebUploadImports, void>, "path" | "verb">;

/**
 * Create a new Web Upload Import record and launch the import process
 */
export const PostApiV1WebUploadImports = (props: PostApiV1WebUploadImportsProps) => (
  <Mutate<WebUploadImport, APIError, void, PostApiV1WebUploadImports, void>
    verb="POST"
    path={`/api/v1/web_upload_imports`}
    
    {...props}
  />
);

export type UsePostApiV1WebUploadImportsProps = Omit<UseMutateProps<WebUploadImport, APIError, void, PostApiV1WebUploadImports, void>, "path" | "verb">;

/**
 * Create a new Web Upload Import record and launch the import process
 */
export const usePostApiV1WebUploadImports = (props: UsePostApiV1WebUploadImportsProps) => useMutate<WebUploadImport, APIError, void, PostApiV1WebUploadImports, void>("POST", `/api/v1/web_upload_imports`, props);


export interface GetApiV1WebUploadImportsQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Array of batch ids
   */
  ids?: string[];
  /**
   * Filter import batches by state
   */
  state?: ("pending" | "running" | "complete" | "failed")[];
}

export type GetApiV1WebUploadImportsProps = Omit<GetProps<WebUploadImport[], unknown, GetApiV1WebUploadImportsQueryParams, void>, "path">;

/**
 * List Web Upload Imports
 */
export const GetApiV1WebUploadImports = (props: GetApiV1WebUploadImportsProps) => (
  <Get<WebUploadImport[], unknown, GetApiV1WebUploadImportsQueryParams, void>
    path={`/api/v1/web_upload_imports`}
    
    {...props}
  />
);

export type UseGetApiV1WebUploadImportsProps = Omit<UseGetProps<WebUploadImport[], unknown, GetApiV1WebUploadImportsQueryParams, void>, "path">;

/**
 * List Web Upload Imports
 */
export const useGetApiV1WebUploadImports = (props: UseGetApiV1WebUploadImportsProps) => useGet<WebUploadImport[], unknown, GetApiV1WebUploadImportsQueryParams, void>(`/api/v1/web_upload_imports`, props);


export type DeleteApiV1WebUploadImportsIdProps = Omit<MutateProps<WebUploadImport, APIError, void, string, void>, "path" | "verb">;

/**
 * Cancel a Web Upload Import
 */
export const DeleteApiV1WebUploadImportsId = (props: DeleteApiV1WebUploadImportsIdProps) => (
  <Mutate<WebUploadImport, APIError, void, string, void>
    verb="DELETE"
    path={`/api/v1/web_upload_imports`}
    
    {...props}
  />
);

export type UseDeleteApiV1WebUploadImportsIdProps = Omit<UseMutateProps<WebUploadImport, APIError, void, string, void>, "path" | "verb">;

/**
 * Cancel a Web Upload Import
 */
export const useDeleteApiV1WebUploadImportsId = (props: UseDeleteApiV1WebUploadImportsIdProps) => useMutate<WebUploadImport, APIError, void, string, void>("DELETE", `/api/v1/web_upload_imports`, {   ...props });


export interface GetApiV1WebUploadImportsIdPathParams {
  id: string
}

export type GetApiV1WebUploadImportsIdProps = Omit<GetProps<WebUploadImport, APIError, void, GetApiV1WebUploadImportsIdPathParams>, "path"> & GetApiV1WebUploadImportsIdPathParams;

/**
 * Get a Web Upload Import
 */
export const GetApiV1WebUploadImportsId = ({id, ...props}: GetApiV1WebUploadImportsIdProps) => (
  <Get<WebUploadImport, APIError, void, GetApiV1WebUploadImportsIdPathParams>
    path={`/api/v1/web_upload_imports/${id}`}
    
    {...props}
  />
);

export type UseGetApiV1WebUploadImportsIdProps = Omit<UseGetProps<WebUploadImport, APIError, void, GetApiV1WebUploadImportsIdPathParams>, "path"> & GetApiV1WebUploadImportsIdPathParams;

/**
 * Get a Web Upload Import
 */
export const useGetApiV1WebUploadImportsId = ({id, ...props}: UseGetApiV1WebUploadImportsIdProps) => useGet<WebUploadImport, APIError, void, GetApiV1WebUploadImportsIdPathParams>((paramsInPath: GetApiV1WebUploadImportsIdPathParams) => `/api/v1/web_upload_imports/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1AdminPendingUsersQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Sort results by this field
   */
  sort_field?: "application_completed_at" | "portfolio_links_count" | "email";
  /**
   * Sort results in this direction
   */
  sort_direction?: "asc" | "desc";
}

export type GetApiV1AdminPendingUsersProps = Omit<GetProps<AdminPendingUser[], unknown, GetApiV1AdminPendingUsersQueryParams, void>, "path">;

/**
 * Get list of pending users
 */
export const GetApiV1AdminPendingUsers = (props: GetApiV1AdminPendingUsersProps) => (
  <Get<AdminPendingUser[], unknown, GetApiV1AdminPendingUsersQueryParams, void>
    path={`/api/v1/admin/pending_users`}
    
    {...props}
  />
);

export type UseGetApiV1AdminPendingUsersProps = Omit<UseGetProps<AdminPendingUser[], unknown, GetApiV1AdminPendingUsersQueryParams, void>, "path">;

/**
 * Get list of pending users
 */
export const useGetApiV1AdminPendingUsers = (props: UseGetApiV1AdminPendingUsersProps) => useGet<AdminPendingUser[], unknown, GetApiV1AdminPendingUsersQueryParams, void>(`/api/v1/admin/pending_users`, props);


export interface GetApiV1AdminPendingUsersIdConnectedAccountsPathParams {
  /**
   * Pending User id
   */
  id: number
}

export type GetApiV1AdminPendingUsersIdConnectedAccountsProps = Omit<GetProps<AdminConnectedAccount[], unknown, void, GetApiV1AdminPendingUsersIdConnectedAccountsPathParams>, "path"> & GetApiV1AdminPendingUsersIdConnectedAccountsPathParams;

/**
 * Get data about accounts and applications with the same fingerprints
 */
export const GetApiV1AdminPendingUsersIdConnectedAccounts = ({id, ...props}: GetApiV1AdminPendingUsersIdConnectedAccountsProps) => (
  <Get<AdminConnectedAccount[], unknown, void, GetApiV1AdminPendingUsersIdConnectedAccountsPathParams>
    path={`/api/v1/admin/pending_users/${id}/connected_accounts`}
    
    {...props}
  />
);

export type UseGetApiV1AdminPendingUsersIdConnectedAccountsProps = Omit<UseGetProps<AdminConnectedAccount[], unknown, void, GetApiV1AdminPendingUsersIdConnectedAccountsPathParams>, "path"> & GetApiV1AdminPendingUsersIdConnectedAccountsPathParams;

/**
 * Get data about accounts and applications with the same fingerprints
 */
export const useGetApiV1AdminPendingUsersIdConnectedAccounts = ({id, ...props}: UseGetApiV1AdminPendingUsersIdConnectedAccountsProps) => useGet<AdminConnectedAccount[], unknown, void, GetApiV1AdminPendingUsersIdConnectedAccountsPathParams>((paramsInPath: GetApiV1AdminPendingUsersIdConnectedAccountsPathParams) => `/api/v1/admin/pending_users/${paramsInPath.id}/connected_accounts`, {  pathParams: { id }, ...props });


export interface GetApiV1AdminPendingUsersIdConnectedApplicationsPathParams {
  /**
   * Pending User id
   */
  id: number
}

export type GetApiV1AdminPendingUsersIdConnectedApplicationsProps = Omit<GetProps<AdminPendingUser[], unknown, void, GetApiV1AdminPendingUsersIdConnectedApplicationsPathParams>, "path"> & GetApiV1AdminPendingUsersIdConnectedApplicationsPathParams;

/**
 * Get data about accounts and applications with the same fingerprints
 */
export const GetApiV1AdminPendingUsersIdConnectedApplications = ({id, ...props}: GetApiV1AdminPendingUsersIdConnectedApplicationsProps) => (
  <Get<AdminPendingUser[], unknown, void, GetApiV1AdminPendingUsersIdConnectedApplicationsPathParams>
    path={`/api/v1/admin/pending_users/${id}/connected_applications`}
    
    {...props}
  />
);

export type UseGetApiV1AdminPendingUsersIdConnectedApplicationsProps = Omit<UseGetProps<AdminPendingUser[], unknown, void, GetApiV1AdminPendingUsersIdConnectedApplicationsPathParams>, "path"> & GetApiV1AdminPendingUsersIdConnectedApplicationsPathParams;

/**
 * Get data about accounts and applications with the same fingerprints
 */
export const useGetApiV1AdminPendingUsersIdConnectedApplications = ({id, ...props}: UseGetApiV1AdminPendingUsersIdConnectedApplicationsProps) => useGet<AdminPendingUser[], unknown, void, GetApiV1AdminPendingUsersIdConnectedApplicationsPathParams>((paramsInPath: GetApiV1AdminPendingUsersIdConnectedApplicationsPathParams) => `/api/v1/admin/pending_users/${paramsInPath.id}/connected_applications`, {  pathParams: { id }, ...props });


export interface PatchApiV1AdminPendingUsersIdRejectPathParams {
  /**
   * Pending User id
   */
  id: number
}

export type PatchApiV1AdminPendingUsersIdRejectProps = Omit<MutateProps<AdminPendingUser, APIError, void, PatchApiV1AdminPendingUsersIdReject, PatchApiV1AdminPendingUsersIdRejectPathParams>, "path" | "verb"> & PatchApiV1AdminPendingUsersIdRejectPathParams;

/**
 * reject a pending user
 */
export const PatchApiV1AdminPendingUsersIdReject = ({id, ...props}: PatchApiV1AdminPendingUsersIdRejectProps) => (
  <Mutate<AdminPendingUser, APIError, void, PatchApiV1AdminPendingUsersIdReject, PatchApiV1AdminPendingUsersIdRejectPathParams>
    verb="PATCH"
    path={`/api/v1/admin/pending_users/${id}/reject`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminPendingUsersIdRejectProps = Omit<UseMutateProps<AdminPendingUser, APIError, void, PatchApiV1AdminPendingUsersIdReject, PatchApiV1AdminPendingUsersIdRejectPathParams>, "path" | "verb"> & PatchApiV1AdminPendingUsersIdRejectPathParams;

/**
 * reject a pending user
 */
export const usePatchApiV1AdminPendingUsersIdReject = ({id, ...props}: UsePatchApiV1AdminPendingUsersIdRejectProps) => useMutate<AdminPendingUser, APIError, void, PatchApiV1AdminPendingUsersIdReject, PatchApiV1AdminPendingUsersIdRejectPathParams>("PATCH", (paramsInPath: PatchApiV1AdminPendingUsersIdRejectPathParams) => `/api/v1/admin/pending_users/${paramsInPath.id}/reject`, {  pathParams: { id }, ...props });


export interface PatchApiV1AdminPendingUsersIdApprovePathParams {
  /**
   * Pending User id
   */
  id: number
}

export type PatchApiV1AdminPendingUsersIdApproveProps = Omit<MutateProps<AdminPendingUser, APIError, void, void, PatchApiV1AdminPendingUsersIdApprovePathParams>, "path" | "verb"> & PatchApiV1AdminPendingUsersIdApprovePathParams;

/**
 * Approve a pending user
 */
export const PatchApiV1AdminPendingUsersIdApprove = ({id, ...props}: PatchApiV1AdminPendingUsersIdApproveProps) => (
  <Mutate<AdminPendingUser, APIError, void, void, PatchApiV1AdminPendingUsersIdApprovePathParams>
    verb="PATCH"
    path={`/api/v1/admin/pending_users/${id}/approve`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminPendingUsersIdApproveProps = Omit<UseMutateProps<AdminPendingUser, APIError, void, void, PatchApiV1AdminPendingUsersIdApprovePathParams>, "path" | "verb"> & PatchApiV1AdminPendingUsersIdApprovePathParams;

/**
 * Approve a pending user
 */
export const usePatchApiV1AdminPendingUsersIdApprove = ({id, ...props}: UsePatchApiV1AdminPendingUsersIdApproveProps) => useMutate<AdminPendingUser, APIError, void, void, PatchApiV1AdminPendingUsersIdApprovePathParams>("PATCH", (paramsInPath: PatchApiV1AdminPendingUsersIdApprovePathParams) => `/api/v1/admin/pending_users/${paramsInPath.id}/approve`, {  pathParams: { id }, ...props });


export interface GetApiV1AdminPendingUsersIdPathParams {
  /**
   * Pending User id
   */
  id: number
}

export type GetApiV1AdminPendingUsersIdProps = Omit<GetProps<AdminPendingUser, unknown, void, GetApiV1AdminPendingUsersIdPathParams>, "path"> & GetApiV1AdminPendingUsersIdPathParams;

/**
 * Retrieve individual pending user
 */
export const GetApiV1AdminPendingUsersId = ({id, ...props}: GetApiV1AdminPendingUsersIdProps) => (
  <Get<AdminPendingUser, unknown, void, GetApiV1AdminPendingUsersIdPathParams>
    path={`/api/v1/admin/pending_users/${id}`}
    
    {...props}
  />
);

export type UseGetApiV1AdminPendingUsersIdProps = Omit<UseGetProps<AdminPendingUser, unknown, void, GetApiV1AdminPendingUsersIdPathParams>, "path"> & GetApiV1AdminPendingUsersIdPathParams;

/**
 * Retrieve individual pending user
 */
export const useGetApiV1AdminPendingUsersId = ({id, ...props}: UseGetApiV1AdminPendingUsersIdProps) => useGet<AdminPendingUser, unknown, void, GetApiV1AdminPendingUsersIdPathParams>((paramsInPath: GetApiV1AdminPendingUsersIdPathParams) => `/api/v1/admin/pending_users/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export type GetApiV1AdminRejectionReasonsProps = Omit<GetProps<RejectionReason[], unknown, void, void>, "path">;

/**
 * Get available rejection reasons
 */
export const GetApiV1AdminRejectionReasons = (props: GetApiV1AdminRejectionReasonsProps) => (
  <Get<RejectionReason[], unknown, void, void>
    path={`/api/v1/admin/rejection_reasons`}
    
    {...props}
  />
);

export type UseGetApiV1AdminRejectionReasonsProps = Omit<UseGetProps<RejectionReason[], unknown, void, void>, "path">;

/**
 * Get available rejection reasons
 */
export const useGetApiV1AdminRejectionReasons = (props: UseGetApiV1AdminRejectionReasonsProps) => useGet<RejectionReason[], unknown, void, void>(`/api/v1/admin/rejection_reasons`, props);


export interface PatchApiV1AdminResourcesUpdateFilteredQueryParams {
  /**
   * Filter resources by state
   */
  "search_filters[state]"?: ("started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "active" | "inactive")[];
  /**
   * Filter resources by content type
   */
  "search_filters[content_type]"?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  /**
   * Filter resources by category_id
   */
  "search_filters[category_id]"?: number[];
  /**
   * Filter resources by file type
   */
  "search_filters[file_type]"?: ("png" | "psd" | "eps" | "jpg" | "mp4" | "mov" | "r3d" | "zip")[];
  /**
   * Filter resources by license
   */
  "search_filters[license]"?: ("free" | "pro" | "editorial")[];
  /**
   * Filter resources by program type
   */
  "search_filters[program_type]"?: ("ppd" | "wo")[];
  /**
   * Filter resources by rating
   */
  "search_filters[rating]"?: number[];
  /**
   * Fetch resources that were uploaded after this date (YYYY-MM-DD)
   */
  "search_filters[created_at_start]"?: string;
  /**
   * Fetch resources that were uploaded before this date (YYYY-MM-DD)
   */
  "search_filters[created_at_end]"?: string;
  /**
   * Fetch resources that were approved before this date (YYYY-MM-DD)
   */
  "search_filters[approved_at_start]"?: string;
  /**
   * Fetch resources that were approved before this date (YYYY-MM-DD)
   */
  "search_filters[approved_at_end]"?: string;
  /**
   * Fetch resources that were reviewed before this date (YYYY-MM-DD)
   */
  "search_filters[reviewed_at_start]"?: string;
  /**
   * Fetch resources that were reviewed before this date (YYYY-MM-DD)
   */
  "search_filters[reviewed_at_end]"?: string;
  /**
   * Filter resources by whether they have release models
   */
  "search_filters[release_status]"?: boolean;
  /**
   * Filter resources by program type
   */
  "search_filters[user_id_verification_status]"?: ("verified" | "not-verified" | "in-review")[];
  /**
   * Filter resources by review type
   */
  "search_filters[review_type]"?: string[];
  /**
   * Filter resources by whether they have comments
   */
  "search_filters[file_history]"?: boolean;
  /**
   * Filter resources by whether AI generated
   */
  "search_filters[ai_generated]"?: boolean;
  /**
   * Filter resources by whether there are duplicate source files
   */
  "search_filters[duplicate]"?: boolean;
  /**
   * Sort resources by this field
   */
  "search_filters[sort_field]"?: "created_at" | "title";
  /**
   * Sort resources in this direction
   */
  "search_filters[sort_dir]"?: "asc" | "desc";
  /**
   * Filter resources by whether they have an SVG source file
   */
  "search_filters[has_svg]"?: boolean;
  /**
   * Filter by auto_submitted
   */
  "search_filters[auto_submitted]"?: boolean;
  /**
   * Filter by magic_metadata
   */
  "search_filters[magic_metadata]"?: boolean;
  /**
   * Filter by marketplaces and transfer statuses, e.g [{ 'marketplace_<status>': [<marketplace_id>] }, ...]
   */
  "search_filters[marketplace_statuses]"?: any;
  /**
   * Filter by auto_distribute
   */
  "search_filters[auto_distribute]"?: boolean;
}

export type PatchApiV1AdminResourcesUpdateFilteredProps = Omit<MutateProps<BulkOperation, unknown, PatchApiV1AdminResourcesUpdateFilteredQueryParams, PatchApiV1AdminResourcesUpdateFiltered, void>, "path" | "verb">;

/**
 * Update resources returned with the provided search filters
 */
export const PatchApiV1AdminResourcesUpdateFiltered = (props: PatchApiV1AdminResourcesUpdateFilteredProps) => (
  <Mutate<BulkOperation, unknown, PatchApiV1AdminResourcesUpdateFilteredQueryParams, PatchApiV1AdminResourcesUpdateFiltered, void>
    verb="PATCH"
    path={`/api/v1/admin/resources/update_filtered`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminResourcesUpdateFilteredProps = Omit<UseMutateProps<BulkOperation, unknown, PatchApiV1AdminResourcesUpdateFilteredQueryParams, PatchApiV1AdminResourcesUpdateFiltered, void>, "path" | "verb">;

/**
 * Update resources returned with the provided search filters
 */
export const usePatchApiV1AdminResourcesUpdateFiltered = (props: UsePatchApiV1AdminResourcesUpdateFilteredProps) => useMutate<BulkOperation, unknown, PatchApiV1AdminResourcesUpdateFilteredQueryParams, PatchApiV1AdminResourcesUpdateFiltered, void>("PATCH", `/api/v1/admin/resources/update_filtered`, props);


export type PatchApiV1AdminResourcesUpdateProps = Omit<MutateProps<BulkOperation, unknown, void, PatchApiV1AdminResourcesUpdate, void>, "path" | "verb">;

/**
 * Admin Bulk Update Resources
 */
export const PatchApiV1AdminResourcesUpdate = (props: PatchApiV1AdminResourcesUpdateProps) => (
  <Mutate<BulkOperation, unknown, void, PatchApiV1AdminResourcesUpdate, void>
    verb="PATCH"
    path={`/api/v1/admin/resources/update`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminResourcesUpdateProps = Omit<UseMutateProps<BulkOperation, unknown, void, PatchApiV1AdminResourcesUpdate, void>, "path" | "verb">;

/**
 * Admin Bulk Update Resources
 */
export const usePatchApiV1AdminResourcesUpdate = (props: UsePatchApiV1AdminResourcesUpdateProps) => useMutate<BulkOperation, unknown, void, PatchApiV1AdminResourcesUpdate, void>("PATCH", `/api/v1/admin/resources/update`, props);


export interface PostApiV1AdminResourcesCsvReportQueryParams {
  /**
   * Filter resources by state
   */
  state?: ("started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "active" | "inactive")[];
  /**
   * Filter resources by content type
   */
  content_type?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  /**
   * Filter resources by category_id
   */
  category_id?: number[];
  /**
   * Filter resources by file type
   */
  file_type?: ("png" | "psd" | "eps" | "jpg" | "mp4" | "mov" | "r3d" | "zip")[];
  /**
   * Filter resources by license
   */
  license?: ("free" | "pro" | "editorial")[];
  /**
   * Filter resources by program type
   */
  program_type?: ("ppd" | "wo")[];
  /**
   * Filter resources by rating
   */
  rating?: number[];
  /**
   * Fetch resources that were uploaded after this date (YYYY-MM-DD)
   */
  created_at_start?: string;
  /**
   * Fetch resources that were uploaded before this date (YYYY-MM-DD)
   */
  created_at_end?: string;
  /**
   * Fetch resources that were approved before this date (YYYY-MM-DD)
   */
  approved_at_start?: string;
  /**
   * Fetch resources that were approved before this date (YYYY-MM-DD)
   */
  approved_at_end?: string;
  /**
   * Fetch resources that were reviewed before this date (YYYY-MM-DD)
   */
  reviewed_at_start?: string;
  /**
   * Fetch resources that were reviewed before this date (YYYY-MM-DD)
   */
  reviewed_at_end?: string;
  /**
   * Filter resources by whether they have release models
   */
  release_status?: boolean;
  /**
   * Filter resources by program type
   */
  user_id_verification_status?: ("verified" | "not-verified" | "in-review")[];
  /**
   * Filter resources by review type
   */
  review_type?: string[];
  /**
   * Filter resources by whether they have comments
   */
  file_history?: boolean;
  /**
   * Filter resources by whether AI generated
   */
  ai_generated?: boolean;
  /**
   * Filter resources by whether there are duplicate source files
   */
  duplicate?: boolean;
  /**
   * Sort resources by this field
   */
  sort_field?: "created_at" | "title";
  /**
   * Sort resources in this direction
   */
  sort_dir?: "asc" | "desc";
  /**
   * Filter resources by whether they have an SVG source file
   */
  has_svg?: boolean;
  /**
   * Filter by auto_submitted
   */
  auto_submitted?: boolean;
  /**
   * Filter by magic_metadata
   */
  magic_metadata?: boolean;
  /**
   * Filter by marketplaces and transfer statuses, e.g [{ 'marketplace_<status>': [<marketplace_id>] }, ...]
   */
  marketplace_statuses?: any;
  /**
   * Filter by auto_distribute
   */
  auto_distribute?: boolean;
}

export type PostApiV1AdminResourcesCsvReportProps = Omit<MutateProps<void, unknown, PostApiV1AdminResourcesCsvReportQueryParams, void, void>, "path" | "verb">;

/**
 * Starts the job to generate a CSV of Resource search results
 */
export const PostApiV1AdminResourcesCsvReport = (props: PostApiV1AdminResourcesCsvReportProps) => (
  <Mutate<void, unknown, PostApiV1AdminResourcesCsvReportQueryParams, void, void>
    verb="POST"
    path={`/api/v1/admin/resources/csv_report`}
    
    {...props}
  />
);

export type UsePostApiV1AdminResourcesCsvReportProps = Omit<UseMutateProps<void, unknown, PostApiV1AdminResourcesCsvReportQueryParams, void, void>, "path" | "verb">;

/**
 * Starts the job to generate a CSV of Resource search results
 */
export const usePostApiV1AdminResourcesCsvReport = (props: UsePostApiV1AdminResourcesCsvReportProps) => useMutate<void, unknown, PostApiV1AdminResourcesCsvReportQueryParams, void, void>("POST", `/api/v1/admin/resources/csv_report`, props);


export interface GetApiV1AdminResourcesSearchQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  search_field?: "user_email" | "reviewer_email" | "keywords" | "assignment" | "import_guid" | "source_file_fingerprint";
  query?: string;
  /**
   * Filter resources by state
   */
  state?: ("started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "active" | "inactive")[];
  /**
   * Filter resources by content type
   */
  content_type?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  /**
   * Filter resources by category_id
   */
  category_id?: number[];
  /**
   * Filter resources by file type
   */
  file_type?: ("png" | "psd" | "eps" | "jpg" | "mp4" | "mov" | "r3d" | "zip")[];
  /**
   * Filter resources by license
   */
  license?: ("free" | "pro" | "editorial")[];
  /**
   * Filter resources by program type
   */
  program_type?: ("ppd" | "wo")[];
  /**
   * Filter resources by rating
   */
  rating?: number[];
  /**
   * Fetch resources that were uploaded after this date (YYYY-MM-DD)
   */
  created_at_start?: string;
  /**
   * Fetch resources that were uploaded before this date (YYYY-MM-DD)
   */
  created_at_end?: string;
  /**
   * Fetch resources that were approved before this date (YYYY-MM-DD)
   */
  approved_at_start?: string;
  /**
   * Fetch resources that were approved before this date (YYYY-MM-DD)
   */
  approved_at_end?: string;
  /**
   * Fetch resources that were reviewed before this date (YYYY-MM-DD)
   */
  reviewed_at_start?: string;
  /**
   * Fetch resources that were reviewed before this date (YYYY-MM-DD)
   */
  reviewed_at_end?: string;
  /**
   * Filter resources by whether they have release models
   */
  release_status?: boolean;
  /**
   * Filter resources by program type
   */
  user_id_verification_status?: ("verified" | "not-verified" | "in-review")[];
  /**
   * Filter resources by review type
   */
  review_type?: string[];
  /**
   * Filter resources by whether they have comments
   */
  file_history?: boolean;
  /**
   * Filter resources by whether AI generated
   */
  ai_generated?: boolean;
  /**
   * Filter resources by whether there are duplicate source files
   */
  duplicate?: boolean;
  /**
   * Sort resources by this field
   */
  sort_field?: "created_at" | "title";
  /**
   * Sort resources in this direction
   */
  sort_dir?: "asc" | "desc";
  /**
   * Filter resources by whether they have an SVG source file
   */
  has_svg?: boolean;
  /**
   * Filter by auto_submitted
   */
  auto_submitted?: boolean;
  /**
   * Filter by magic_metadata
   */
  magic_metadata?: boolean;
  /**
   * Filter by marketplaces and transfer statuses, e.g [{ 'marketplace_<status>': [<marketplace_id>] }, ...]
   */
  marketplace_statuses?: any;
  /**
   * Filter by auto_distribute
   */
  auto_distribute?: boolean;
}

export type GetApiV1AdminResourcesSearchProps = Omit<GetProps<ResourcesSearchResults, unknown, GetApiV1AdminResourcesSearchQueryParams, void>, "path">;

/**
 * Resource search results
 */
export const GetApiV1AdminResourcesSearch = (props: GetApiV1AdminResourcesSearchProps) => (
  <Get<ResourcesSearchResults, unknown, GetApiV1AdminResourcesSearchQueryParams, void>
    path={`/api/v1/admin/resources/search`}
    
    {...props}
  />
);

export type UseGetApiV1AdminResourcesSearchProps = Omit<UseGetProps<ResourcesSearchResults, unknown, GetApiV1AdminResourcesSearchQueryParams, void>, "path">;

/**
 * Resource search results
 */
export const useGetApiV1AdminResourcesSearch = (props: UseGetApiV1AdminResourcesSearchProps) => useGet<ResourcesSearchResults, unknown, GetApiV1AdminResourcesSearchQueryParams, void>(`/api/v1/admin/resources/search`, props);


export interface PatchApiV1AdminResourcesUnassignAllFromReviewerQueryParams {
  reviewer_import_guid: string;
}

export type PatchApiV1AdminResourcesUnassignAllFromReviewerProps = Omit<MutateProps<ResponsesSingleItemCount, unknown, PatchApiV1AdminResourcesUnassignAllFromReviewerQueryParams, void, void>, "path" | "verb">;

/**
 * unassign all resources from reviewer
 */
export const PatchApiV1AdminResourcesUnassignAllFromReviewer = (props: PatchApiV1AdminResourcesUnassignAllFromReviewerProps) => (
  <Mutate<ResponsesSingleItemCount, unknown, PatchApiV1AdminResourcesUnassignAllFromReviewerQueryParams, void, void>
    verb="PATCH"
    path={`/api/v1/admin/resources/unassign_all_from_reviewer`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminResourcesUnassignAllFromReviewerProps = Omit<UseMutateProps<ResponsesSingleItemCount, unknown, PatchApiV1AdminResourcesUnassignAllFromReviewerQueryParams, void, void>, "path" | "verb">;

/**
 * unassign all resources from reviewer
 */
export const usePatchApiV1AdminResourcesUnassignAllFromReviewer = (props: UsePatchApiV1AdminResourcesUnassignAllFromReviewerProps) => useMutate<ResponsesSingleItemCount, unknown, PatchApiV1AdminResourcesUnassignAllFromReviewerQueryParams, void, void>("PATCH", `/api/v1/admin/resources/unassign_all_from_reviewer`, props);


export interface PatchApiV1AdminResourcesAssignContributorReviewBatchQueryParams {
  /**
   * Filter resources to assign by state
   */
  state: "submitted" | "flagged";
}

export type PatchApiV1AdminResourcesAssignContributorReviewBatchProps = Omit<MutateProps<void, unknown, PatchApiV1AdminResourcesAssignContributorReviewBatchQueryParams, void, void>, "path" | "verb">;

/**
 * find the next contributor to review and assign a review batch
 */
export const PatchApiV1AdminResourcesAssignContributorReviewBatch = (props: PatchApiV1AdminResourcesAssignContributorReviewBatchProps) => (
  <Mutate<void, unknown, PatchApiV1AdminResourcesAssignContributorReviewBatchQueryParams, void, void>
    verb="PATCH"
    path={`/api/v1/admin/resources/assign_contributor_review_batch`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminResourcesAssignContributorReviewBatchProps = Omit<UseMutateProps<void, unknown, PatchApiV1AdminResourcesAssignContributorReviewBatchQueryParams, void, void>, "path" | "verb">;

/**
 * find the next contributor to review and assign a review batch
 */
export const usePatchApiV1AdminResourcesAssignContributorReviewBatch = (props: UsePatchApiV1AdminResourcesAssignContributorReviewBatchProps) => useMutate<void, unknown, PatchApiV1AdminResourcesAssignContributorReviewBatchQueryParams, void, void>("PATCH", `/api/v1/admin/resources/assign_contributor_review_batch`, props);


export interface PatchApiV1AdminResourcesAssignReviewBatchQueryParams {
  contributor_import_guid: string;
  reviewer_import_guid: string;
  /**
   * Filter resources to assign by state
   */
  state?: "submitted" | "flagged";
}

export type PatchApiV1AdminResourcesAssignReviewBatchProps = Omit<MutateProps<ResponsesSingleItemCount, unknown, PatchApiV1AdminResourcesAssignReviewBatchQueryParams, void, void>, "path" | "verb">;

/**
 * assign a batch of resources from a contributor to a reviewer
 */
export const PatchApiV1AdminResourcesAssignReviewBatch = (props: PatchApiV1AdminResourcesAssignReviewBatchProps) => (
  <Mutate<ResponsesSingleItemCount, unknown, PatchApiV1AdminResourcesAssignReviewBatchQueryParams, void, void>
    verb="PATCH"
    path={`/api/v1/admin/resources/assign_review_batch`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminResourcesAssignReviewBatchProps = Omit<UseMutateProps<ResponsesSingleItemCount, unknown, PatchApiV1AdminResourcesAssignReviewBatchQueryParams, void, void>, "path" | "verb">;

/**
 * assign a batch of resources from a contributor to a reviewer
 */
export const usePatchApiV1AdminResourcesAssignReviewBatch = (props: UsePatchApiV1AdminResourcesAssignReviewBatchProps) => useMutate<ResponsesSingleItemCount, unknown, PatchApiV1AdminResourcesAssignReviewBatchQueryParams, void, void>("PATCH", `/api/v1/admin/resources/assign_review_batch`, props);


export interface PatchApiV1AdminResourcesRejectAllUserIdPathParams {
  /**
   * User GUID to approve
   */
  user_id: string
}

export type PatchApiV1AdminResourcesRejectAllUserIdProps = Omit<MutateProps<BulkOperation, unknown, void, PatchApiV1AdminResourcesRejectAllUserId, PatchApiV1AdminResourcesRejectAllUserIdPathParams>, "path" | "verb"> & PatchApiV1AdminResourcesRejectAllUserIdPathParams;

/**
 * Starts the job to reject all of a contributor's submitted resources
 */
export const PatchApiV1AdminResourcesRejectAllUserId = ({user_id, ...props}: PatchApiV1AdminResourcesRejectAllUserIdProps) => (
  <Mutate<BulkOperation, unknown, void, PatchApiV1AdminResourcesRejectAllUserId, PatchApiV1AdminResourcesRejectAllUserIdPathParams>
    verb="PATCH"
    path={`/api/v1/admin/resources/reject_all/${user_id}`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminResourcesRejectAllUserIdProps = Omit<UseMutateProps<BulkOperation, unknown, void, PatchApiV1AdminResourcesRejectAllUserId, PatchApiV1AdminResourcesRejectAllUserIdPathParams>, "path" | "verb"> & PatchApiV1AdminResourcesRejectAllUserIdPathParams;

/**
 * Starts the job to reject all of a contributor's submitted resources
 */
export const usePatchApiV1AdminResourcesRejectAllUserId = ({user_id, ...props}: UsePatchApiV1AdminResourcesRejectAllUserIdProps) => useMutate<BulkOperation, unknown, void, PatchApiV1AdminResourcesRejectAllUserId, PatchApiV1AdminResourcesRejectAllUserIdPathParams>("PATCH", (paramsInPath: PatchApiV1AdminResourcesRejectAllUserIdPathParams) => `/api/v1/admin/resources/reject_all/${paramsInPath.user_id}`, {  pathParams: { user_id }, ...props });


export interface PatchApiV1AdminResourcesApproveAllUserIdPathParams {
  /**
   * Contributor guid for resources to approve
   */
  user_id: string
}

export type PatchApiV1AdminResourcesApproveAllUserIdProps = Omit<MutateProps<BulkOperation, unknown, void, PatchApiV1AdminResourcesApproveAllUserId, PatchApiV1AdminResourcesApproveAllUserIdPathParams>, "path" | "verb"> & PatchApiV1AdminResourcesApproveAllUserIdPathParams;

/**
 * Starts the job to approve all of a contributor's resources in category at a given rating
 */
export const PatchApiV1AdminResourcesApproveAllUserId = ({user_id, ...props}: PatchApiV1AdminResourcesApproveAllUserIdProps) => (
  <Mutate<BulkOperation, unknown, void, PatchApiV1AdminResourcesApproveAllUserId, PatchApiV1AdminResourcesApproveAllUserIdPathParams>
    verb="PATCH"
    path={`/api/v1/admin/resources/approve_all/${user_id}`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminResourcesApproveAllUserIdProps = Omit<UseMutateProps<BulkOperation, unknown, void, PatchApiV1AdminResourcesApproveAllUserId, PatchApiV1AdminResourcesApproveAllUserIdPathParams>, "path" | "verb"> & PatchApiV1AdminResourcesApproveAllUserIdPathParams;

/**
 * Starts the job to approve all of a contributor's resources in category at a given rating
 */
export const usePatchApiV1AdminResourcesApproveAllUserId = ({user_id, ...props}: UsePatchApiV1AdminResourcesApproveAllUserIdProps) => useMutate<BulkOperation, unknown, void, PatchApiV1AdminResourcesApproveAllUserId, PatchApiV1AdminResourcesApproveAllUserIdPathParams>("PATCH", (paramsInPath: PatchApiV1AdminResourcesApproveAllUserIdPathParams) => `/api/v1/admin/resources/approve_all/${paramsInPath.user_id}`, {  pathParams: { user_id }, ...props });


export interface PostApiV1AdminResourcesApproveAllUserIdRatingPathParams {
  /**
   * User GUID to approve
   */
  user_id: string;
  /**
   * Rating for approved resources
   */
  rating: number
}

export type PostApiV1AdminResourcesApproveAllUserIdRatingProps = Omit<MutateProps<BulkOperation, unknown, void, void, PostApiV1AdminResourcesApproveAllUserIdRatingPathParams>, "path" | "verb"> & PostApiV1AdminResourcesApproveAllUserIdRatingPathParams;

/**
 * Starts the job to approve all of a contributor's submitted resources at a given rating
 */
export const PostApiV1AdminResourcesApproveAllUserIdRating = ({user_id, rating, ...props}: PostApiV1AdminResourcesApproveAllUserIdRatingProps) => (
  <Mutate<BulkOperation, unknown, void, void, PostApiV1AdminResourcesApproveAllUserIdRatingPathParams>
    verb="POST"
    path={`/api/v1/admin/resources/approve_all/${user_id}/${rating}`}
    
    {...props}
  />
);

export type UsePostApiV1AdminResourcesApproveAllUserIdRatingProps = Omit<UseMutateProps<BulkOperation, unknown, void, void, PostApiV1AdminResourcesApproveAllUserIdRatingPathParams>, "path" | "verb"> & PostApiV1AdminResourcesApproveAllUserIdRatingPathParams;

/**
 * Starts the job to approve all of a contributor's submitted resources at a given rating
 */
export const usePostApiV1AdminResourcesApproveAllUserIdRating = ({user_id, rating, ...props}: UsePostApiV1AdminResourcesApproveAllUserIdRatingProps) => useMutate<BulkOperation, unknown, void, void, PostApiV1AdminResourcesApproveAllUserIdRatingPathParams>("POST", (paramsInPath: PostApiV1AdminResourcesApproveAllUserIdRatingPathParams) => `/api/v1/admin/resources/approve_all/${paramsInPath.user_id}/${paramsInPath.rating}`, {  pathParams: { user_id, rating }, ...props });


export type PatchApiV1AdminResourcesRejectProps = Omit<MutateProps<void, unknown, void, void, void>, "path" | "verb">;

/**
 * Rejects one or more resources
 */
export const PatchApiV1AdminResourcesReject = (props: PatchApiV1AdminResourcesRejectProps) => (
  <Mutate<void, unknown, void, void, void>
    verb="PATCH"
    path={`/api/v1/admin/resources/reject`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminResourcesRejectProps = Omit<UseMutateProps<void, unknown, void, void, void>, "path" | "verb">;

/**
 * Rejects one or more resources
 */
export const usePatchApiV1AdminResourcesReject = (props: UsePatchApiV1AdminResourcesRejectProps) => useMutate<void, unknown, void, void, void>("PATCH", `/api/v1/admin/resources/reject`, props);


export type PatchApiV1AdminResourcesFlagProps = Omit<MutateProps<void, unknown, void, void, void>, "path" | "verb">;

/**
 * Flags one or more resources
 */
export const PatchApiV1AdminResourcesFlag = (props: PatchApiV1AdminResourcesFlagProps) => (
  <Mutate<void, unknown, void, void, void>
    verb="PATCH"
    path={`/api/v1/admin/resources/flag`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminResourcesFlagProps = Omit<UseMutateProps<void, unknown, void, void, void>, "path" | "verb">;

/**
 * Flags one or more resources
 */
export const usePatchApiV1AdminResourcesFlag = (props: UsePatchApiV1AdminResourcesFlagProps) => useMutate<void, unknown, void, void, void>("PATCH", `/api/v1/admin/resources/flag`, props);


export interface GetApiV1AdminResourcesResourceIdReviewDetailsPathParams {
  resource_id: string
}

export type GetApiV1AdminResourcesResourceIdReviewDetailsProps = Omit<GetProps<ReviewDetails, unknown, void, GetApiV1AdminResourcesResourceIdReviewDetailsPathParams>, "path"> & GetApiV1AdminResourcesResourceIdReviewDetailsPathParams;

/**
 * Returns details for a given resource
 */
export const GetApiV1AdminResourcesResourceIdReviewDetails = ({resource_id, ...props}: GetApiV1AdminResourcesResourceIdReviewDetailsProps) => (
  <Get<ReviewDetails, unknown, void, GetApiV1AdminResourcesResourceIdReviewDetailsPathParams>
    path={`/api/v1/admin/resources/${resource_id}/review_details`}
    
    {...props}
  />
);

export type UseGetApiV1AdminResourcesResourceIdReviewDetailsProps = Omit<UseGetProps<ReviewDetails, unknown, void, GetApiV1AdminResourcesResourceIdReviewDetailsPathParams>, "path"> & GetApiV1AdminResourcesResourceIdReviewDetailsPathParams;

/**
 * Returns details for a given resource
 */
export const useGetApiV1AdminResourcesResourceIdReviewDetails = ({resource_id, ...props}: UseGetApiV1AdminResourcesResourceIdReviewDetailsProps) => useGet<ReviewDetails, unknown, void, GetApiV1AdminResourcesResourceIdReviewDetailsPathParams>((paramsInPath: GetApiV1AdminResourcesResourceIdReviewDetailsPathParams) => `/api/v1/admin/resources/${paramsInPath.resource_id}/review_details`, {  pathParams: { resource_id }, ...props });


export interface PatchApiV1AdminResourcesImportGuidUpdatePathParams {
  import_guid: string
}

export type PatchApiV1AdminResourcesImportGuidUpdateProps = Omit<MutateProps<Resource, unknown, void, PatchApiV1AdminResourcesImportGuidUpdate, PatchApiV1AdminResourcesImportGuidUpdatePathParams>, "path" | "verb"> & PatchApiV1AdminResourcesImportGuidUpdatePathParams;

/**
 * Updates a resource
 */
export const PatchApiV1AdminResourcesImportGuidUpdate = ({import_guid, ...props}: PatchApiV1AdminResourcesImportGuidUpdateProps) => (
  <Mutate<Resource, unknown, void, PatchApiV1AdminResourcesImportGuidUpdate, PatchApiV1AdminResourcesImportGuidUpdatePathParams>
    verb="PATCH"
    path={`/api/v1/admin/resources/${import_guid}/update`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminResourcesImportGuidUpdateProps = Omit<UseMutateProps<Resource, unknown, void, PatchApiV1AdminResourcesImportGuidUpdate, PatchApiV1AdminResourcesImportGuidUpdatePathParams>, "path" | "verb"> & PatchApiV1AdminResourcesImportGuidUpdatePathParams;

/**
 * Updates a resource
 */
export const usePatchApiV1AdminResourcesImportGuidUpdate = ({import_guid, ...props}: UsePatchApiV1AdminResourcesImportGuidUpdateProps) => useMutate<Resource, unknown, void, PatchApiV1AdminResourcesImportGuidUpdate, PatchApiV1AdminResourcesImportGuidUpdatePathParams>("PATCH", (paramsInPath: PatchApiV1AdminResourcesImportGuidUpdatePathParams) => `/api/v1/admin/resources/${paramsInPath.import_guid}/update`, {  pathParams: { import_guid }, ...props });


export interface GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsPathParams {
  reviewer_id: string
}

export type GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsProps = Omit<GetProps<ReviewCounts, unknown, void, GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsPathParams>, "path"> & GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsPathParams;

/**
 * Daily and hourly review counts
 */
export const GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCounts = ({reviewer_id, ...props}: GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsProps) => (
  <Get<ReviewCounts, unknown, void, GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsPathParams>
    path={`/api/v1/admin/review_metrics/${reviewer_id}/daily_hourly_counts`}
    
    {...props}
  />
);

export type UseGetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsProps = Omit<UseGetProps<ReviewCounts, unknown, void, GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsPathParams>, "path"> & GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsPathParams;

/**
 * Daily and hourly review counts
 */
export const useGetApiV1AdminReviewMetricsReviewerIdDailyHourlyCounts = ({reviewer_id, ...props}: UseGetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsProps) => useGet<ReviewCounts, unknown, void, GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsPathParams>((paramsInPath: GetApiV1AdminReviewMetricsReviewerIdDailyHourlyCountsPathParams) => `/api/v1/admin/review_metrics/${paramsInPath.reviewer_id}/daily_hourly_counts`, {  pathParams: { reviewer_id }, ...props });


export interface GetApiV1AdminReviewMetricsReviewerIdTodayCountPathParams {
  reviewer_id: string
}

export type GetApiV1AdminReviewMetricsReviewerIdTodayCountProps = Omit<GetProps<ResponsesSingleItemCount, unknown, void, GetApiV1AdminReviewMetricsReviewerIdTodayCountPathParams>, "path"> & GetApiV1AdminReviewMetricsReviewerIdTodayCountPathParams;

/**
 * Review counts by day
 */
export const GetApiV1AdminReviewMetricsReviewerIdTodayCount = ({reviewer_id, ...props}: GetApiV1AdminReviewMetricsReviewerIdTodayCountProps) => (
  <Get<ResponsesSingleItemCount, unknown, void, GetApiV1AdminReviewMetricsReviewerIdTodayCountPathParams>
    path={`/api/v1/admin/review_metrics/${reviewer_id}/today_count`}
    
    {...props}
  />
);

export type UseGetApiV1AdminReviewMetricsReviewerIdTodayCountProps = Omit<UseGetProps<ResponsesSingleItemCount, unknown, void, GetApiV1AdminReviewMetricsReviewerIdTodayCountPathParams>, "path"> & GetApiV1AdminReviewMetricsReviewerIdTodayCountPathParams;

/**
 * Review counts by day
 */
export const useGetApiV1AdminReviewMetricsReviewerIdTodayCount = ({reviewer_id, ...props}: UseGetApiV1AdminReviewMetricsReviewerIdTodayCountProps) => useGet<ResponsesSingleItemCount, unknown, void, GetApiV1AdminReviewMetricsReviewerIdTodayCountPathParams>((paramsInPath: GetApiV1AdminReviewMetricsReviewerIdTodayCountPathParams) => `/api/v1/admin/review_metrics/${paramsInPath.reviewer_id}/today_count`, {  pathParams: { reviewer_id }, ...props });


export type PatchApiV1AdminReviewSessionsEndSessionProps = Omit<MutateProps<ReviewSession, unknown, void, void, void>, "path" | "verb">;

/**
 * End latest review session
 */
export const PatchApiV1AdminReviewSessionsEndSession = (props: PatchApiV1AdminReviewSessionsEndSessionProps) => (
  <Mutate<ReviewSession, unknown, void, void, void>
    verb="PATCH"
    path={`/api/v1/admin/review_sessions/end_session`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminReviewSessionsEndSessionProps = Omit<UseMutateProps<ReviewSession, unknown, void, void, void>, "path" | "verb">;

/**
 * End latest review session
 */
export const usePatchApiV1AdminReviewSessionsEndSession = (props: UsePatchApiV1AdminReviewSessionsEndSessionProps) => useMutate<ReviewSession, unknown, void, void, void>("PATCH", `/api/v1/admin/review_sessions/end_session`, props);


export type GetApiV1AdminReviewSessionsLatestProps = Omit<GetProps<ReviewSession, unknown, void, void>, "path">;

/**
 * Get latest ongoing review session
 */
export const GetApiV1AdminReviewSessionsLatest = (props: GetApiV1AdminReviewSessionsLatestProps) => (
  <Get<ReviewSession, unknown, void, void>
    path={`/api/v1/admin/review_sessions/latest`}
    
    {...props}
  />
);

export type UseGetApiV1AdminReviewSessionsLatestProps = Omit<UseGetProps<ReviewSession, unknown, void, void>, "path">;

/**
 * Get latest ongoing review session
 */
export const useGetApiV1AdminReviewSessionsLatest = (props: UseGetApiV1AdminReviewSessionsLatestProps) => useGet<ReviewSession, unknown, void, void>(`/api/v1/admin/review_sessions/latest`, props);


export type GetApiV1AdminReviewSessionsMetricsProps = Omit<GetProps<ReviewSessionMetrics, unknown, void, void>, "path">;

/**
 * Display metrics for latest review session
 */
export const GetApiV1AdminReviewSessionsMetrics = (props: GetApiV1AdminReviewSessionsMetricsProps) => (
  <Get<ReviewSessionMetrics, unknown, void, void>
    path={`/api/v1/admin/review_sessions/metrics`}
    
    {...props}
  />
);

export type UseGetApiV1AdminReviewSessionsMetricsProps = Omit<UseGetProps<ReviewSessionMetrics, unknown, void, void>, "path">;

/**
 * Display metrics for latest review session
 */
export const useGetApiV1AdminReviewSessionsMetrics = (props: UseGetApiV1AdminReviewSessionsMetricsProps) => useGet<ReviewSessionMetrics, unknown, void, void>(`/api/v1/admin/review_sessions/metrics`, props);


export type PostApiV1AdminReviewSessionsProps = Omit<MutateProps<ReviewSession, unknown, void, void, void>, "path" | "verb">;

/**
 * Create a new review session
 */
export const PostApiV1AdminReviewSessions = (props: PostApiV1AdminReviewSessionsProps) => (
  <Mutate<ReviewSession, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/admin/review_sessions`}
    
    {...props}
  />
);

export type UsePostApiV1AdminReviewSessionsProps = Omit<UseMutateProps<ReviewSession, unknown, void, void, void>, "path" | "verb">;

/**
 * Create a new review session
 */
export const usePostApiV1AdminReviewSessions = (props: UsePostApiV1AdminReviewSessionsProps) => useMutate<ReviewSession, unknown, void, void, void>("POST", `/api/v1/admin/review_sessions`, props);


export interface GetApiV1AdminSecureValuesQueryParams {
  /**
   * Encrypted ID for secure value
   */
  svid: string;
  record_type: "TaxFormSubmission";
  record_id: string;
}

export type GetApiV1AdminSecureValuesProps = Omit<GetProps<AdminSecureValue, unknown, GetApiV1AdminSecureValuesQueryParams, void>, "path">;

/**
 * Get an encrypted secure value
 */
export const GetApiV1AdminSecureValues = (props: GetApiV1AdminSecureValuesProps) => (
  <Get<AdminSecureValue, unknown, GetApiV1AdminSecureValuesQueryParams, void>
    path={`/api/v1/admin/secure_values`}
    
    {...props}
  />
);

export type UseGetApiV1AdminSecureValuesProps = Omit<UseGetProps<AdminSecureValue, unknown, GetApiV1AdminSecureValuesQueryParams, void>, "path">;

/**
 * Get an encrypted secure value
 */
export const useGetApiV1AdminSecureValues = (props: UseGetApiV1AdminSecureValuesProps) => useGet<AdminSecureValue, unknown, GetApiV1AdminSecureValuesQueryParams, void>(`/api/v1/admin/secure_values`, props);


export interface GetApiV1AdminUserSummaryQueryParams {
  user_id: string;
}

export type GetApiV1AdminUserSummaryProps = Omit<GetProps<SubmissionSummaryReview, unknown, GetApiV1AdminUserSummaryQueryParams, void>, "path">;

/**
 * Get summary for a specific user
 */
export const GetApiV1AdminUserSummary = (props: GetApiV1AdminUserSummaryProps) => (
  <Get<SubmissionSummaryReview, unknown, GetApiV1AdminUserSummaryQueryParams, void>
    path={`/api/v1/admin/user_summary`}
    
    {...props}
  />
);

export type UseGetApiV1AdminUserSummaryProps = Omit<UseGetProps<SubmissionSummaryReview, unknown, GetApiV1AdminUserSummaryQueryParams, void>, "path">;

/**
 * Get summary for a specific user
 */
export const useGetApiV1AdminUserSummary = (props: UseGetApiV1AdminUserSummaryProps) => useGet<SubmissionSummaryReview, unknown, GetApiV1AdminUserSummaryQueryParams, void>(`/api/v1/admin/user_summary`, props);


export interface GetApiV1AdminUploadedQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  user_id?: string;
  sort_first_by_current_user_is_reviewer?: boolean;
  /**
   * Sort results by this field
   */
  sort_field?: "first_name" | "last_name" | "email" | "average_rating" | "pending" | "active" | "oldest_submission_at" | "newest_submission_at" | "id_verification_status" | "unassigned";
  /**
   * Sort results in this direction
   */
  sort_direction?: "asc" | "desc";
  format?: "json" | "csv";
  resource_state?: "submitted" | "flagged";
}

export type GetApiV1AdminUploadedProps = Omit<GetProps<UserSubmissionSummaryRollup[], unknown, GetApiV1AdminUploadedQueryParams, void>, "path">;

/**
 * Lists summaries of users with submitted or flagged resources
 */
export const GetApiV1AdminUploaded = (props: GetApiV1AdminUploadedProps) => (
  <Get<UserSubmissionSummaryRollup[], unknown, GetApiV1AdminUploadedQueryParams, void>
    path={`/api/v1/admin/uploaded`}
    
    {...props}
  />
);

export type UseGetApiV1AdminUploadedProps = Omit<UseGetProps<UserSubmissionSummaryRollup[], unknown, GetApiV1AdminUploadedQueryParams, void>, "path">;

/**
 * Lists summaries of users with submitted or flagged resources
 */
export const useGetApiV1AdminUploaded = (props: UseGetApiV1AdminUploadedProps) => useGet<UserSubmissionSummaryRollup[], unknown, GetApiV1AdminUploadedQueryParams, void>(`/api/v1/admin/uploaded`, props);


export type PostApiV1AdminUsersProps = Omit<MutateProps<User, APIError, void, PostApiV1AdminUsers, void>, "path" | "verb">;

/**
 * Create a user, like an admin or WO contributor
 */
export const PostApiV1AdminUsers = (props: PostApiV1AdminUsersProps) => (
  <Mutate<User, APIError, void, PostApiV1AdminUsers, void>
    verb="POST"
    path={`/api/v1/admin/users`}
    
    {...props}
  />
);

export type UsePostApiV1AdminUsersProps = Omit<UseMutateProps<User, APIError, void, PostApiV1AdminUsers, void>, "path" | "verb">;

/**
 * Create a user, like an admin or WO contributor
 */
export const usePostApiV1AdminUsers = (props: UsePostApiV1AdminUsersProps) => useMutate<User, APIError, void, PostApiV1AdminUsers, void>("POST", `/api/v1/admin/users`, props);


export interface GetApiV1AdminUsersQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Search query that will be matched against email or name
   */
  query?: string;
  /**
   * Filter users by role
   */
  role?: ("super_admin" | "limited_admin" | "finance_admin" | "art_director")[];
  /**
   * Filter users by id verification status
   */
  id_verification_status?: ("verified" | "not-verified" | "in-review")[];
  /**
   * Filter users by tax questionnaire state
   */
  tax_questionnaire_state?: ("incomplete" | "pending_review" | "complete" | "denied" | "expired")[];
}

export type GetApiV1AdminUsersProps = Omit<GetProps<AdminUser[], unknown, GetApiV1AdminUsersQueryParams, void>, "path">;

/**
 * Get list of users
 */
export const GetApiV1AdminUsers = (props: GetApiV1AdminUsersProps) => (
  <Get<AdminUser[], unknown, GetApiV1AdminUsersQueryParams, void>
    path={`/api/v1/admin/users`}
    
    {...props}
  />
);

export type UseGetApiV1AdminUsersProps = Omit<UseGetProps<AdminUser[], unknown, GetApiV1AdminUsersQueryParams, void>, "path">;

/**
 * Get list of users
 */
export const useGetApiV1AdminUsers = (props: UseGetApiV1AdminUsersProps) => useGet<AdminUser[], unknown, GetApiV1AdminUsersQueryParams, void>(`/api/v1/admin/users`, props);


export interface PatchApiV1AdminUsersUserIdPathParams {
  /**
   * User guid
   */
  user_id: string
}

export type PatchApiV1AdminUsersUserIdProps = Omit<MutateProps<AdminUser, unknown, void, void, PatchApiV1AdminUsersUserIdPathParams>, "path" | "verb"> & PatchApiV1AdminUsersUserIdPathParams;

/**
 * Update a user
 */
export const PatchApiV1AdminUsersUserId = ({user_id, ...props}: PatchApiV1AdminUsersUserIdProps) => (
  <Mutate<AdminUser, unknown, void, void, PatchApiV1AdminUsersUserIdPathParams>
    verb="PATCH"
    path={`/api/v1/admin/users/${user_id}`}
    
    {...props}
  />
);

export type UsePatchApiV1AdminUsersUserIdProps = Omit<UseMutateProps<AdminUser, unknown, void, void, PatchApiV1AdminUsersUserIdPathParams>, "path" | "verb"> & PatchApiV1AdminUsersUserIdPathParams;

/**
 * Update a user
 */
export const usePatchApiV1AdminUsersUserId = ({user_id, ...props}: UsePatchApiV1AdminUsersUserIdProps) => useMutate<AdminUser, unknown, void, void, PatchApiV1AdminUsersUserIdPathParams>("PATCH", (paramsInPath: PatchApiV1AdminUsersUserIdPathParams) => `/api/v1/admin/users/${paramsInPath.user_id}`, {  pathParams: { user_id }, ...props });


export interface GetApiV1AdminUsersUserIdPathParams {
  /**
   * User guid
   */
  user_id: string
}

export type GetApiV1AdminUsersUserIdProps = Omit<GetProps<AdminUser, unknown, void, GetApiV1AdminUsersUserIdPathParams>, "path"> & GetApiV1AdminUsersUserIdPathParams;

/**
 * retrieve individual user
 */
export const GetApiV1AdminUsersUserId = ({user_id, ...props}: GetApiV1AdminUsersUserIdProps) => (
  <Get<AdminUser, unknown, void, GetApiV1AdminUsersUserIdPathParams>
    path={`/api/v1/admin/users/${user_id}`}
    
    {...props}
  />
);

export type UseGetApiV1AdminUsersUserIdProps = Omit<UseGetProps<AdminUser, unknown, void, GetApiV1AdminUsersUserIdPathParams>, "path"> & GetApiV1AdminUsersUserIdPathParams;

/**
 * retrieve individual user
 */
export const useGetApiV1AdminUsersUserId = ({user_id, ...props}: UseGetApiV1AdminUsersUserIdProps) => useGet<AdminUser, unknown, void, GetApiV1AdminUsersUserIdPathParams>((paramsInPath: GetApiV1AdminUsersUserIdPathParams) => `/api/v1/admin/users/${paramsInPath.user_id}`, {  pathParams: { user_id }, ...props });


export interface GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsPathParams {
  /**
   * User guid
   */
  user_id: string
}

export type GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsProps = Omit<GetProps<AdminTaxFormSubmission[], unknown, void, GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsPathParams>, "path"> & GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsPathParams;

/**
 * Get tax form submissions for user's tax questionnaire
 */
export const GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissions = ({user_id, ...props}: GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsProps) => (
  <Get<AdminTaxFormSubmission[], unknown, void, GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsPathParams>
    path={`/api/v1/admin/users/${user_id}/tax_questionnaire/tax_form_submissions`}
    
    {...props}
  />
);

export type UseGetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsProps = Omit<UseGetProps<AdminTaxFormSubmission[], unknown, void, GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsPathParams>, "path"> & GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsPathParams;

/**
 * Get tax form submissions for user's tax questionnaire
 */
export const useGetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissions = ({user_id, ...props}: UseGetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsProps) => useGet<AdminTaxFormSubmission[], unknown, void, GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsPathParams>((paramsInPath: GetApiV1AdminUsersUserIdTaxQuestionnaireTaxFormSubmissionsPathParams) => `/api/v1/admin/users/${paramsInPath.user_id}/tax_questionnaire/tax_form_submissions`, {  pathParams: { user_id }, ...props });


export interface GetApiV1AdminUsersUserIdTaxQuestionnairePathParams {
  /**
   * User guid
   */
  user_id: string
}

export type GetApiV1AdminUsersUserIdTaxQuestionnaireProps = Omit<GetProps<AdminTaxQuestionnaire, unknown, void, GetApiV1AdminUsersUserIdTaxQuestionnairePathParams>, "path"> & GetApiV1AdminUsersUserIdTaxQuestionnairePathParams;

/**
 * Get tax questionnaire for a user
 */
export const GetApiV1AdminUsersUserIdTaxQuestionnaire = ({user_id, ...props}: GetApiV1AdminUsersUserIdTaxQuestionnaireProps) => (
  <Get<AdminTaxQuestionnaire, unknown, void, GetApiV1AdminUsersUserIdTaxQuestionnairePathParams>
    path={`/api/v1/admin/users/${user_id}/tax_questionnaire`}
    
    {...props}
  />
);

export type UseGetApiV1AdminUsersUserIdTaxQuestionnaireProps = Omit<UseGetProps<AdminTaxQuestionnaire, unknown, void, GetApiV1AdminUsersUserIdTaxQuestionnairePathParams>, "path"> & GetApiV1AdminUsersUserIdTaxQuestionnairePathParams;

/**
 * Get tax questionnaire for a user
 */
export const useGetApiV1AdminUsersUserIdTaxQuestionnaire = ({user_id, ...props}: UseGetApiV1AdminUsersUserIdTaxQuestionnaireProps) => useGet<AdminTaxQuestionnaire, unknown, void, GetApiV1AdminUsersUserIdTaxQuestionnairePathParams>((paramsInPath: GetApiV1AdminUsersUserIdTaxQuestionnairePathParams) => `/api/v1/admin/users/${paramsInPath.user_id}/tax_questionnaire`, {  pathParams: { user_id }, ...props });


export interface GetApiV1FinanceInvoicesQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Filter invoices by user import guid
   */
  user_id?: string[];
  /**
   * Filter invoices by user email
   */
  user_email?: string[];
  /**
   * Filter invoices by invoice ids
   */
  id?: string[];
  /**
   * Filter invoices by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter invoices by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter invoices by state
   */
  state?: ("pending" | "in_progress" | "complete" | "approved" | "payment_processing" | "failed" | "canceled")[];
  /**
   * Fetch invoices that were started before this date [start, end]
   */
  started_at?: string[];
  /**
   * Fetch invoices that were completed after this date [start, end]
   */
  completed_at?: string[];
  /**
   * Filter invoices by payment method
   */
  payment_method?: string[];
  /**
   * Filter invoices by payment account id
   */
  payment_account_id?: string[];
  /**
   * Filter invoices by payoneer_payee_id
   */
  payoneer_payee_id?: string[];
  /**
   * Filter invoices by payment transaction id
   */
  transaction_id?: string[];
  /**
   * Filter invoices by code
   */
  code?: string[];
  /**
   * Filter invoices by amount paid
   */
  deposit_cents?: string[];
  /**
   * Filter invoices by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter invoices by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter invoices by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter invoices for locked users
   */
  user_locked?: boolean;
  /**
   * Filter invoices by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter invoices by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter invoices by payout batch id
   */
  payout_batch_id?: string[];
  /**
   * Filter invoices by number of failures
   */
  failure_count?: string[];
  /**
   * Filter invoices by approver import guid
   */
  approver_id?: string[];
  /**
   * Filter invoices by approver email
   */
  approver_email?: string[];
  /**
   * Filter invoices by approved at. syntax is [start, end]
   */
  approved_at?: string[];
  /**
   * Filter invoices by whether they have been voided
   */
  void?: string[];
  /**
   * Sort invoices by this field
   */
  sort_field?: "created_at" | "updated_at" | "state" | "started_at" | "completed_at" | "code" | "transaction_id" | "payment_account_id" | "user_email" | "earning_cents" | "deposit_cents" | "tax_withheld_cents" | "payoneer_payee_id" | "payment_method" | "approved_at" | "failure_count" | "approver_email" | "void";
  /**
   * Sort invoices in this direction
   */
  sort_direction?: "asc" | "desc";
  format?: "json" | "csv";
}

export type GetApiV1FinanceInvoicesProps = Omit<GetProps<FinanceInvoice[], unknown, GetApiV1FinanceInvoicesQueryParams, void>, "path">;

/**
 * List invoices
 */
export const GetApiV1FinanceInvoices = (props: GetApiV1FinanceInvoicesProps) => (
  <Get<FinanceInvoice[], unknown, GetApiV1FinanceInvoicesQueryParams, void>
    path={`/api/v1/finance/invoices`}
    
    {...props}
  />
);

export type UseGetApiV1FinanceInvoicesProps = Omit<UseGetProps<FinanceInvoice[], unknown, GetApiV1FinanceInvoicesQueryParams, void>, "path">;

/**
 * List invoices
 */
export const useGetApiV1FinanceInvoices = (props: UseGetApiV1FinanceInvoicesProps) => useGet<FinanceInvoice[], unknown, GetApiV1FinanceInvoicesQueryParams, void>(`/api/v1/finance/invoices`, props);


export interface DeleteApiV1FinanceInvoicesQueryParams {
  /**
   * Filter invoices by user import guid
   */
  user_id?: string[];
  /**
   * Filter invoices by user email
   */
  user_email?: string[];
  /**
   * Filter invoices by invoice ids
   */
  id?: string[];
  /**
   * Filter invoices by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter invoices by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter invoices by state
   */
  state?: ("pending" | "in_progress" | "complete" | "approved" | "payment_processing" | "failed" | "canceled")[];
  /**
   * Fetch invoices that were started before this date [start, end]
   */
  started_at?: string[];
  /**
   * Fetch invoices that were completed after this date [start, end]
   */
  completed_at?: string[];
  /**
   * Filter invoices by payment method
   */
  payment_method?: string[];
  /**
   * Filter invoices by payment account id
   */
  payment_account_id?: string[];
  /**
   * Filter invoices by payoneer_payee_id
   */
  payoneer_payee_id?: string[];
  /**
   * Filter invoices by payment transaction id
   */
  transaction_id?: string[];
  /**
   * Filter invoices by code
   */
  code?: string[];
  /**
   * Filter invoices by amount paid
   */
  deposit_cents?: string[];
  /**
   * Filter invoices by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter invoices by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter invoices by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter invoices for locked users
   */
  user_locked?: boolean;
  /**
   * Filter invoices by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter invoices by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter invoices by payout batch id
   */
  payout_batch_id?: string[];
  /**
   * Filter invoices by number of failures
   */
  failure_count?: string[];
  /**
   * Filter invoices by approver import guid
   */
  approver_id?: string[];
  /**
   * Filter invoices by approver email
   */
  approver_email?: string[];
  /**
   * Filter invoices by approved at. syntax is [start, end]
   */
  approved_at?: string[];
  /**
   * Filter invoices by whether they have been voided
   */
  void?: string[];
  /**
   * User password. Only needed if deleting a complete/paid invoice
   */
  password?: string;
}

export type DeleteApiV1FinanceInvoicesProps = Omit<MutateProps<FinanceBulkOperation, unknown, DeleteApiV1FinanceInvoicesQueryParams, void, void>, "path" | "verb">;

/**
 * Bulk delete invoices
 */
export const DeleteApiV1FinanceInvoices = (props: DeleteApiV1FinanceInvoicesProps) => (
  <Mutate<FinanceBulkOperation, unknown, DeleteApiV1FinanceInvoicesQueryParams, void, void>
    verb="DELETE"
    path={`/api/v1/finance/invoices`}
    
    {...props}
  />
);

export type UseDeleteApiV1FinanceInvoicesProps = Omit<UseMutateProps<FinanceBulkOperation, unknown, DeleteApiV1FinanceInvoicesQueryParams, void, void>, "path" | "verb">;

/**
 * Bulk delete invoices
 */
export const useDeleteApiV1FinanceInvoices = (props: UseDeleteApiV1FinanceInvoicesProps) => useMutate<FinanceBulkOperation, unknown, DeleteApiV1FinanceInvoicesQueryParams, void, void>("DELETE", `/api/v1/finance/invoices`, {   ...props });


export interface PostApiV1FinanceInvoicesQueryParams {
  /**
   * Filter charges by charge ids
   */
  id?: string[];
  /**
   * Filter charges by source category
   */
  source_category?: string[];
  /**
   * Filter charges by user import guid
   */
  user_id?: string[];
  /**
   * Filter charges by code
   */
  code?: string[];
  /**
   * Filter charges by code
   */
  invoice_code?: string[];
  /**
   * Filter charges by code
   */
  user_email?: string[];
  /**
   * Filter charges by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter charges by month. syntax is [start, end]
   */
  month?: string[];
  /**
   * Filter charges by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter charges by paid at. syntax is [start, end]
   */
  paid_at?: string[];
  /**
   * Filter charges by charge source ids
   */
  source_id?: string[];
  /**
   * Filter charges by source
   */
  source?: ("royalty" | "referral" | "bonus" | "other")[];
  /**
   * Filter charges by state
   */
  state?: ("unpaid" | "pending" | "paid")[];
  /**
   * Filter charges by content type
   */
  content_type?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  /**
   * Filter charges by region
   */
  region?: ("US" | "Intl")[];
  /**
   * Filter charges by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter manually created charges
   */
  manual?: boolean;
  /**
   * Filter charges for locked users
   */
  user_locked?: boolean;
  /**
   * Filter charges by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter charges by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter charges by amount paid
   */
  paid_cents?: string[];
  /**
   * Filter charges by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter charges by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter charges by tax thousands
   */
  tax_thousands?: string[];
  /**
   * Filter charges by tax country id
   */
  tax_country_id?: string[];
  /**
   * Filter charges that have been edited
   */
  edited?: boolean;
}

export type PostApiV1FinanceInvoicesProps = Omit<MutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesQueryParams, void, void>, "path" | "verb">;

/**
 * Create invoices
 */
export const PostApiV1FinanceInvoices = (props: PostApiV1FinanceInvoicesProps) => (
  <Mutate<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesQueryParams, void, void>
    verb="POST"
    path={`/api/v1/finance/invoices`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceInvoicesProps = Omit<UseMutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesQueryParams, void, void>, "path" | "verb">;

/**
 * Create invoices
 */
export const usePostApiV1FinanceInvoices = (props: UsePostApiV1FinanceInvoicesProps) => useMutate<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesQueryParams, void, void>("POST", `/api/v1/finance/invoices`, props);


export interface GetApiV1FinanceChargesQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Filter charges by charge ids
   */
  id?: string[];
  /**
   * Filter charges by source category
   */
  source_category?: string[];
  /**
   * Filter charges by user import guid
   */
  user_id?: string[];
  /**
   * Filter charges by code
   */
  code?: string[];
  /**
   * Filter charges by code
   */
  invoice_code?: string[];
  /**
   * Filter charges by code
   */
  user_email?: string[];
  /**
   * Filter charges by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter charges by month. syntax is [start, end]
   */
  month?: string[];
  /**
   * Filter charges by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter charges by paid at. syntax is [start, end]
   */
  paid_at?: string[];
  /**
   * Filter charges by charge source ids
   */
  source_id?: string[];
  /**
   * Filter charges by source
   */
  source?: ("royalty" | "referral" | "bonus" | "other")[];
  /**
   * Filter charges by state
   */
  state?: ("unpaid" | "pending" | "paid")[];
  /**
   * Filter charges by content type
   */
  content_type?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  /**
   * Filter charges by region
   */
  region?: ("US" | "Intl")[];
  /**
   * Filter charges by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter manually created charges
   */
  manual?: boolean;
  /**
   * Filter charges for locked users
   */
  user_locked?: boolean;
  /**
   * Filter charges by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter charges by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter charges by amount paid
   */
  paid_cents?: string[];
  /**
   * Filter charges by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter charges by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter charges by tax thousands
   */
  tax_thousands?: string[];
  /**
   * Filter charges by tax country id
   */
  tax_country_id?: string[];
  /**
   * Filter charges that have been edited
   */
  edited?: boolean;
  /**
   * Sort charges by this field
   */
  sort_field?: "created_at" | "month" | "updated_at" | "content_type" | "source" | "source_category" | "state" | "region" | "code" | "user_email" | "earning_cents" | "tax_withheld_cents" | "paid_cents" | "manual" | "edited" | "paid_at";
  /**
   * Sort charges in this direction
   */
  sort_direction?: "asc" | "desc";
  format?: "json" | "csv";
}

export type GetApiV1FinanceChargesProps = Omit<GetProps<FinanceCharge[], unknown, GetApiV1FinanceChargesQueryParams, void>, "path">;

/**
 * List charges
 */
export const GetApiV1FinanceCharges = (props: GetApiV1FinanceChargesProps) => (
  <Get<FinanceCharge[], unknown, GetApiV1FinanceChargesQueryParams, void>
    path={`/api/v1/finance/charges`}
    
    {...props}
  />
);

export type UseGetApiV1FinanceChargesProps = Omit<UseGetProps<FinanceCharge[], unknown, GetApiV1FinanceChargesQueryParams, void>, "path">;

/**
 * List charges
 */
export const useGetApiV1FinanceCharges = (props: UseGetApiV1FinanceChargesProps) => useGet<FinanceCharge[], unknown, GetApiV1FinanceChargesQueryParams, void>(`/api/v1/finance/charges`, props);


export type PostApiV1FinanceChargesProps = Omit<MutateProps<FinanceCharge, unknown, void, PostApiV1FinanceCharges, void>, "path" | "verb">;

/**
 * create a new charge
 */
export const PostApiV1FinanceCharges = (props: PostApiV1FinanceChargesProps) => (
  <Mutate<FinanceCharge, unknown, void, PostApiV1FinanceCharges, void>
    verb="POST"
    path={`/api/v1/finance/charges`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceChargesProps = Omit<UseMutateProps<FinanceCharge, unknown, void, PostApiV1FinanceCharges, void>, "path" | "verb">;

/**
 * create a new charge
 */
export const usePostApiV1FinanceCharges = (props: UsePostApiV1FinanceChargesProps) => useMutate<FinanceCharge, unknown, void, PostApiV1FinanceCharges, void>("POST", `/api/v1/finance/charges`, props);


export interface GetApiV1FinanceAccountSummariesIdPathParams {
  id: string
}

export type GetApiV1FinanceAccountSummariesIdProps = Omit<GetProps<FinanceAccountSummary, unknown, void, GetApiV1FinanceAccountSummariesIdPathParams>, "path"> & GetApiV1FinanceAccountSummariesIdPathParams;

/**
 * Account summary details
 */
export const GetApiV1FinanceAccountSummariesId = ({id, ...props}: GetApiV1FinanceAccountSummariesIdProps) => (
  <Get<FinanceAccountSummary, unknown, void, GetApiV1FinanceAccountSummariesIdPathParams>
    path={`/api/v1/finance/account_summaries/${id}`}
    
    {...props}
  />
);

export type UseGetApiV1FinanceAccountSummariesIdProps = Omit<UseGetProps<FinanceAccountSummary, unknown, void, GetApiV1FinanceAccountSummariesIdPathParams>, "path"> & GetApiV1FinanceAccountSummariesIdPathParams;

/**
 * Account summary details
 */
export const useGetApiV1FinanceAccountSummariesId = ({id, ...props}: UseGetApiV1FinanceAccountSummariesIdProps) => useGet<FinanceAccountSummary, unknown, void, GetApiV1FinanceAccountSummariesIdPathParams>((paramsInPath: GetApiV1FinanceAccountSummariesIdPathParams) => `/api/v1/finance/account_summaries/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1FinanceAccountSummariesQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Filter account summaries by email
   */
  email?: string[];
  /**
   * Filter account summaries by program
   */
  program?: ("ppd" | "wholly owned")[];
  /**
   * Filter account summaries by account status
   */
  account_status?: string[];
  /**
   * Filter account summaries by user locked reason
   */
  locked_reason?: string[];
  /**
   * Filter account summaries by image tax thousands
   */
  image_tax_thousands?: string[];
  /**
   * Filter account summaries by image tax thousands
   */
  video_tax_thousands?: string[];
  /**
   * Filter account summaries by country_tax_id
   */
  tax_country_id?: string[];
  /**
   * Filter account summaries by amount paid
   */
  unpaid_cents?: string[];
  /**
   * Filter account summaries by amount pending
   */
  pending_cents?: string[];
  /**
   * Filter account summaries by amount paid
   */
  paid_cents?: string[];
  /**
   * Filter account summaries by total amount
   */
  total_cents?: string[];
  /**
   * Filter account summaries by last payment date. Syntax is [start, end]
   */
  last_payment_at?: string[];
  /**
   * Filter account summaries by generated at. Syntax is [start, end]
   */
  generated_at?: string[];
  /**
   * Filter charges by whether they have notes
   */
  payment_eligible?: boolean;
  /**
   * Filter account summaries by whether payout is blocked by admin
   */
  payout_blocked_by_admin?: boolean;
  /**
   * Sort account summaries by this field
   */
  sort_field?: "email" | "program" | "account_status" | "tax_country_id" | "payment_eligible" | "payout_blocked_by_admin" | "generated_at" | "last_payment_at" | "image_tax_thousands" | "video_tax_thousands" | "unpaid_cents" | "pending_cents" | "paid_cents" | "total_cents" | "payment_method" | "payment_account" | "created_at" | "payoneer_payee_id" | "payment_account_state" | "payment_threshold_cents";
  /**
   * Sort account summaries in this direction
   */
  sort_direction?: "asc" | "desc";
  format?: "json" | "csv";
  /**
   * Filter account summaries by payment method
   */
  payment_method?: string[];
  /**
   * Filter account summaries by payment account id
   */
  payment_account?: string[];
  /**
   * Filter account summaries by payee id
   */
  payoneer_payee_id?: string[];
  /**
   * Filter account summaries by payment account state
   */
  payment_account_state?: string[];
  /**
   * Filter by payment account and main email
   */
  payment_account_or_email?: string[];
  /**
   * Filter account summaries by payment threshold
   */
  payment_threshold_cents?: string[];
}

export type GetApiV1FinanceAccountSummariesProps = Omit<GetProps<FinanceAccountSummary[], unknown, GetApiV1FinanceAccountSummariesQueryParams, void>, "path">;

/**
 * List account summaries
 */
export const GetApiV1FinanceAccountSummaries = (props: GetApiV1FinanceAccountSummariesProps) => (
  <Get<FinanceAccountSummary[], unknown, GetApiV1FinanceAccountSummariesQueryParams, void>
    path={`/api/v1/finance/account_summaries`}
    
    {...props}
  />
);

export type UseGetApiV1FinanceAccountSummariesProps = Omit<UseGetProps<FinanceAccountSummary[], unknown, GetApiV1FinanceAccountSummariesQueryParams, void>, "path">;

/**
 * List account summaries
 */
export const useGetApiV1FinanceAccountSummaries = (props: UseGetApiV1FinanceAccountSummariesProps) => useGet<FinanceAccountSummary[], unknown, GetApiV1FinanceAccountSummariesQueryParams, void>(`/api/v1/finance/account_summaries`, props);


export interface GetApiV1FinancePayoutsQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
  /**
   * Filter payouts by ids
   */
  batch_id?: string[];
  /**
   * Filter payouts by payment method
   */
  payment_method?: string[];
  /**
   * Filter payouts by state
   */
  state?: ("processing" | "success" | "failed")[];
  /**
   * Filter payouts by amount earned
   */
  amount_cents?: string[];
  /**
   * Filter payouts by amount earned
   */
  fees_cents?: string[];
  /**
   * Filter payouts by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter payouts by number of invoices
   */
  total_invoice_count?: string[];
  /**
   * Filter payouts by number of paid invoices
   */
  paid_invoice_count?: string[];
  /**
   * Filter payouts by number of unpaid invoices
   */
  unpaid_invoice_count?: string[];
  /**
   * Filter payouts by number of paid invoices
   */
  error_count?: string[];
  /**
   * Sort charges by this field
   */
  sort_field?: "created_at" | "payment_method" | "state" | "batch_id" | "amount_cents" | "fees_cents" | "total_invoice_count" | "paid_invoice_count" | "unpaid_invoice_count" | "error_count";
  /**
   * Sort payouts in this direction
   */
  sort_direction?: "asc" | "desc";
  format?: "json" | "csv";
}

export type GetApiV1FinancePayoutsProps = Omit<GetProps<FinancePayout[], unknown, GetApiV1FinancePayoutsQueryParams, void>, "path">;

/**
 * Lists payouts
 */
export const GetApiV1FinancePayouts = (props: GetApiV1FinancePayoutsProps) => (
  <Get<FinancePayout[], unknown, GetApiV1FinancePayoutsQueryParams, void>
    path={`/api/v1/finance/payouts`}
    
    {...props}
  />
);

export type UseGetApiV1FinancePayoutsProps = Omit<UseGetProps<FinancePayout[], unknown, GetApiV1FinancePayoutsQueryParams, void>, "path">;

/**
 * Lists payouts
 */
export const useGetApiV1FinancePayouts = (props: UseGetApiV1FinancePayoutsProps) => useGet<FinancePayout[], unknown, GetApiV1FinancePayoutsQueryParams, void>(`/api/v1/finance/payouts`, props);


export interface PatchApiV1FinanceNotableNotableIdNotesIdPathParams {
  notable: string;
  notable_id: string;
  /**
   * Finance Note ID
   */
  id: string
}

export type PatchApiV1FinanceNotableNotableIdNotesIdProps = Omit<MutateProps<FinanceNote, unknown, void, PatchApiV1FinanceNotableNotableIdNotesId, PatchApiV1FinanceNotableNotableIdNotesIdPathParams>, "path" | "verb"> & PatchApiV1FinanceNotableNotableIdNotesIdPathParams;

/**
 * Update a note associated to a charge or invoice record
 */
export const PatchApiV1FinanceNotableNotableIdNotesId = ({notable, notable_id, id, ...props}: PatchApiV1FinanceNotableNotableIdNotesIdProps) => (
  <Mutate<FinanceNote, unknown, void, PatchApiV1FinanceNotableNotableIdNotesId, PatchApiV1FinanceNotableNotableIdNotesIdPathParams>
    verb="PATCH"
    path={`/api/v1/finance/${notable}/${notable_id}/notes/${id}`}
    
    {...props}
  />
);

export type UsePatchApiV1FinanceNotableNotableIdNotesIdProps = Omit<UseMutateProps<FinanceNote, unknown, void, PatchApiV1FinanceNotableNotableIdNotesId, PatchApiV1FinanceNotableNotableIdNotesIdPathParams>, "path" | "verb"> & PatchApiV1FinanceNotableNotableIdNotesIdPathParams;

/**
 * Update a note associated to a charge or invoice record
 */
export const usePatchApiV1FinanceNotableNotableIdNotesId = ({notable, notable_id, id, ...props}: UsePatchApiV1FinanceNotableNotableIdNotesIdProps) => useMutate<FinanceNote, unknown, void, PatchApiV1FinanceNotableNotableIdNotesId, PatchApiV1FinanceNotableNotableIdNotesIdPathParams>("PATCH", (paramsInPath: PatchApiV1FinanceNotableNotableIdNotesIdPathParams) => `/api/v1/finance/${paramsInPath.notable}/${paramsInPath.notable_id}/notes/${paramsInPath.id}`, {  pathParams: { notable, notable_id, id }, ...props });


export interface GetApiV1FinanceNotableNotableIdNotesPathParams {
  notable: string;
  notable_id: string
}

export type GetApiV1FinanceNotableNotableIdNotesProps = Omit<GetProps<FinanceNote[], unknown, void, GetApiV1FinanceNotableNotableIdNotesPathParams>, "path"> & GetApiV1FinanceNotableNotableIdNotesPathParams;

/**
 * Lists the notes associated to a charge or invoice record
 */
export const GetApiV1FinanceNotableNotableIdNotes = ({notable, notable_id, ...props}: GetApiV1FinanceNotableNotableIdNotesProps) => (
  <Get<FinanceNote[], unknown, void, GetApiV1FinanceNotableNotableIdNotesPathParams>
    path={`/api/v1/finance/${notable}/${notable_id}/notes`}
    
    {...props}
  />
);

export type UseGetApiV1FinanceNotableNotableIdNotesProps = Omit<UseGetProps<FinanceNote[], unknown, void, GetApiV1FinanceNotableNotableIdNotesPathParams>, "path"> & GetApiV1FinanceNotableNotableIdNotesPathParams;

/**
 * Lists the notes associated to a charge or invoice record
 */
export const useGetApiV1FinanceNotableNotableIdNotes = ({notable, notable_id, ...props}: UseGetApiV1FinanceNotableNotableIdNotesProps) => useGet<FinanceNote[], unknown, void, GetApiV1FinanceNotableNotableIdNotesPathParams>((paramsInPath: GetApiV1FinanceNotableNotableIdNotesPathParams) => `/api/v1/finance/${paramsInPath.notable}/${paramsInPath.notable_id}/notes`, {  pathParams: { notable, notable_id }, ...props });


export interface PostApiV1FinanceNotableNotableIdNotesPathParams {
  notable: string;
  notable_id: string
}

export type PostApiV1FinanceNotableNotableIdNotesProps = Omit<MutateProps<FinanceNote, unknown, void, PostApiV1FinanceNotableNotableIdNotes, PostApiV1FinanceNotableNotableIdNotesPathParams>, "path" | "verb"> & PostApiV1FinanceNotableNotableIdNotesPathParams;

/**
 * Create a new note. Format: charge/:charge_id/notes or invoice/:invoice_id/notes
 */
export const PostApiV1FinanceNotableNotableIdNotes = ({notable, notable_id, ...props}: PostApiV1FinanceNotableNotableIdNotesProps) => (
  <Mutate<FinanceNote, unknown, void, PostApiV1FinanceNotableNotableIdNotes, PostApiV1FinanceNotableNotableIdNotesPathParams>
    verb="POST"
    path={`/api/v1/finance/${notable}/${notable_id}/notes`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceNotableNotableIdNotesProps = Omit<UseMutateProps<FinanceNote, unknown, void, PostApiV1FinanceNotableNotableIdNotes, PostApiV1FinanceNotableNotableIdNotesPathParams>, "path" | "verb"> & PostApiV1FinanceNotableNotableIdNotesPathParams;

/**
 * Create a new note. Format: charge/:charge_id/notes or invoice/:invoice_id/notes
 */
export const usePostApiV1FinanceNotableNotableIdNotes = ({notable, notable_id, ...props}: UsePostApiV1FinanceNotableNotableIdNotesProps) => useMutate<FinanceNote, unknown, void, PostApiV1FinanceNotableNotableIdNotes, PostApiV1FinanceNotableNotableIdNotesPathParams>("POST", (paramsInPath: PostApiV1FinanceNotableNotableIdNotesPathParams) => `/api/v1/finance/${paramsInPath.notable}/${paramsInPath.notable_id}/notes`, {  pathParams: { notable, notable_id }, ...props });


export type PostApiV1FinanceInvoicesConfirmPaymentsProps = Omit<MutateProps<void, unknown, void, void, void>, "path" | "verb">;

/**
 * Confirm invoice payments
 */
export const PostApiV1FinanceInvoicesConfirmPayments = (props: PostApiV1FinanceInvoicesConfirmPaymentsProps) => (
  <Mutate<void, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/finance/invoices/confirm_payments`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceInvoicesConfirmPaymentsProps = Omit<UseMutateProps<void, unknown, void, void, void>, "path" | "verb">;

/**
 * Confirm invoice payments
 */
export const usePostApiV1FinanceInvoicesConfirmPayments = (props: UsePostApiV1FinanceInvoicesConfirmPaymentsProps) => useMutate<void, unknown, void, void, void>("POST", `/api/v1/finance/invoices/confirm_payments`, props);


export type PostApiV1FinanceInvoicesInvoicePaymentsProps = Omit<MutateProps<void, APIError, void, PostApiV1FinanceInvoicesInvoicePayments, void>, "path" | "verb">;

/**
 * Check invoice payments via csv upload prior to calling /confirm_payments
 */
export const PostApiV1FinanceInvoicesInvoicePayments = (props: PostApiV1FinanceInvoicesInvoicePaymentsProps) => (
  <Mutate<void, APIError, void, PostApiV1FinanceInvoicesInvoicePayments, void>
    verb="POST"
    path={`/api/v1/finance/invoices/invoice_payments`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceInvoicesInvoicePaymentsProps = Omit<UseMutateProps<void, APIError, void, PostApiV1FinanceInvoicesInvoicePayments, void>, "path" | "verb">;

/**
 * Check invoice payments via csv upload prior to calling /confirm_payments
 */
export const usePostApiV1FinanceInvoicesInvoicePayments = (props: UsePostApiV1FinanceInvoicesInvoicePaymentsProps) => useMutate<void, APIError, void, PostApiV1FinanceInvoicesInvoicePayments, void>("POST", `/api/v1/finance/invoices/invoice_payments`, props);


export interface PostApiV1FinanceInvoicesCountsForApprovalQueryParams {
  /**
   * Filter invoices by user import guid
   */
  user_id?: string[];
  /**
   * Filter invoices by user email
   */
  user_email?: string[];
  /**
   * Filter invoices by invoice ids
   */
  id?: string[];
  /**
   * Filter invoices by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter invoices by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter invoices by state
   */
  state?: ("pending" | "in_progress" | "complete" | "approved" | "payment_processing" | "failed" | "canceled")[];
  /**
   * Fetch invoices that were started before this date [start, end]
   */
  started_at?: string[];
  /**
   * Fetch invoices that were completed after this date [start, end]
   */
  completed_at?: string[];
  /**
   * Filter invoices by payment method
   */
  payment_method?: string[];
  /**
   * Filter invoices by payment account id
   */
  payment_account_id?: string[];
  /**
   * Filter invoices by payoneer_payee_id
   */
  payoneer_payee_id?: string[];
  /**
   * Filter invoices by payment transaction id
   */
  transaction_id?: string[];
  /**
   * Filter invoices by code
   */
  code?: string[];
  /**
   * Filter invoices by amount paid
   */
  deposit_cents?: string[];
  /**
   * Filter invoices by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter invoices by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter invoices by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter invoices for locked users
   */
  user_locked?: boolean;
  /**
   * Filter invoices by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter invoices by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter invoices by payout batch id
   */
  payout_batch_id?: string[];
  /**
   * Filter invoices by number of failures
   */
  failure_count?: string[];
  /**
   * Filter invoices by approver import guid
   */
  approver_id?: string[];
  /**
   * Filter invoices by approver email
   */
  approver_email?: string[];
  /**
   * Filter invoices by approved at. syntax is [start, end]
   */
  approved_at?: string[];
  /**
   * Filter invoices by whether they have been voided
   */
  void?: string[];
}

export type PostApiV1FinanceInvoicesCountsForApprovalProps = Omit<MutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesCountsForApprovalQueryParams, void, void>, "path" | "verb">;

/**
 * Start a bulk operation to get invoice approval counts
 */
export const PostApiV1FinanceInvoicesCountsForApproval = (props: PostApiV1FinanceInvoicesCountsForApprovalProps) => (
  <Mutate<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesCountsForApprovalQueryParams, void, void>
    verb="POST"
    path={`/api/v1/finance/invoices/counts_for_approval`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceInvoicesCountsForApprovalProps = Omit<UseMutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesCountsForApprovalQueryParams, void, void>, "path" | "verb">;

/**
 * Start a bulk operation to get invoice approval counts
 */
export const usePostApiV1FinanceInvoicesCountsForApproval = (props: UsePostApiV1FinanceInvoicesCountsForApprovalProps) => useMutate<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesCountsForApprovalQueryParams, void, void>("POST", `/api/v1/finance/invoices/counts_for_approval`, props);


export interface PostApiV1FinanceInvoicesApproveQueryParams {
  /**
   * Filter invoices by user import guid
   */
  user_id?: string[];
  /**
   * Filter invoices by user email
   */
  user_email?: string[];
  /**
   * Filter invoices by invoice ids
   */
  id?: string[];
  /**
   * Filter invoices by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter invoices by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter invoices by state
   */
  state?: ("pending" | "in_progress" | "complete" | "approved" | "payment_processing" | "failed" | "canceled")[];
  /**
   * Fetch invoices that were started before this date [start, end]
   */
  started_at?: string[];
  /**
   * Fetch invoices that were completed after this date [start, end]
   */
  completed_at?: string[];
  /**
   * Filter invoices by payment method
   */
  payment_method?: string[];
  /**
   * Filter invoices by payment account id
   */
  payment_account_id?: string[];
  /**
   * Filter invoices by payoneer_payee_id
   */
  payoneer_payee_id?: string[];
  /**
   * Filter invoices by payment transaction id
   */
  transaction_id?: string[];
  /**
   * Filter invoices by code
   */
  code?: string[];
  /**
   * Filter invoices by amount paid
   */
  deposit_cents?: string[];
  /**
   * Filter invoices by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter invoices by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter invoices by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter invoices for locked users
   */
  user_locked?: boolean;
  /**
   * Filter invoices by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter invoices by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter invoices by payout batch id
   */
  payout_batch_id?: string[];
  /**
   * Filter invoices by number of failures
   */
  failure_count?: string[];
  /**
   * Filter invoices by approver import guid
   */
  approver_id?: string[];
  /**
   * Filter invoices by approver email
   */
  approver_email?: string[];
  /**
   * Filter invoices by approved at. syntax is [start, end]
   */
  approved_at?: string[];
  /**
   * Filter invoices by whether they have been voided
   */
  void?: string[];
}

export type PostApiV1FinanceInvoicesApproveProps = Omit<MutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesApproveQueryParams, void, void>, "path" | "verb">;

/**
 * Start a bulk operation to approve invoices
 */
export const PostApiV1FinanceInvoicesApprove = (props: PostApiV1FinanceInvoicesApproveProps) => (
  <Mutate<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesApproveQueryParams, void, void>
    verb="POST"
    path={`/api/v1/finance/invoices/approve`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceInvoicesApproveProps = Omit<UseMutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesApproveQueryParams, void, void>, "path" | "verb">;

/**
 * Start a bulk operation to approve invoices
 */
export const usePostApiV1FinanceInvoicesApprove = (props: UsePostApiV1FinanceInvoicesApproveProps) => useMutate<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesApproveQueryParams, void, void>("POST", `/api/v1/finance/invoices/approve`, props);


export interface PostApiV1FinanceInvoicesCountsForDeletionQueryParams {
  /**
   * Filter invoices by user import guid
   */
  user_id?: string[];
  /**
   * Filter invoices by user email
   */
  user_email?: string[];
  /**
   * Filter invoices by invoice ids
   */
  id?: string[];
  /**
   * Filter invoices by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter invoices by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter invoices by state
   */
  state?: ("pending" | "in_progress" | "complete" | "approved" | "payment_processing" | "failed" | "canceled")[];
  /**
   * Fetch invoices that were started before this date [start, end]
   */
  started_at?: string[];
  /**
   * Fetch invoices that were completed after this date [start, end]
   */
  completed_at?: string[];
  /**
   * Filter invoices by payment method
   */
  payment_method?: string[];
  /**
   * Filter invoices by payment account id
   */
  payment_account_id?: string[];
  /**
   * Filter invoices by payoneer_payee_id
   */
  payoneer_payee_id?: string[];
  /**
   * Filter invoices by payment transaction id
   */
  transaction_id?: string[];
  /**
   * Filter invoices by code
   */
  code?: string[];
  /**
   * Filter invoices by amount paid
   */
  deposit_cents?: string[];
  /**
   * Filter invoices by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter invoices by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter invoices by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter invoices for locked users
   */
  user_locked?: boolean;
  /**
   * Filter invoices by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter invoices by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter invoices by payout batch id
   */
  payout_batch_id?: string[];
  /**
   * Filter invoices by number of failures
   */
  failure_count?: string[];
  /**
   * Filter invoices by approver import guid
   */
  approver_id?: string[];
  /**
   * Filter invoices by approver email
   */
  approver_email?: string[];
  /**
   * Filter invoices by approved at. syntax is [start, end]
   */
  approved_at?: string[];
  /**
   * Filter invoices by whether they have been voided
   */
  void?: string[];
}

export type PostApiV1FinanceInvoicesCountsForDeletionProps = Omit<MutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesCountsForDeletionQueryParams, void, void>, "path" | "verb">;

/**
 * Calculate the number of invoices to be voided
 */
export const PostApiV1FinanceInvoicesCountsForDeletion = (props: PostApiV1FinanceInvoicesCountsForDeletionProps) => (
  <Mutate<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesCountsForDeletionQueryParams, void, void>
    verb="POST"
    path={`/api/v1/finance/invoices/counts_for_deletion`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceInvoicesCountsForDeletionProps = Omit<UseMutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesCountsForDeletionQueryParams, void, void>, "path" | "verb">;

/**
 * Calculate the number of invoices to be voided
 */
export const usePostApiV1FinanceInvoicesCountsForDeletion = (props: UsePostApiV1FinanceInvoicesCountsForDeletionProps) => useMutate<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesCountsForDeletionQueryParams, void, void>("POST", `/api/v1/finance/invoices/counts_for_deletion`, props);


export interface PostApiV1FinanceInvoicesInvoiceCreationCountsQueryParams {
  /**
   * Filter charges by charge ids
   */
  id?: string[];
  /**
   * Filter charges by source category
   */
  source_category?: string[];
  /**
   * Filter charges by user import guid
   */
  user_id?: string[];
  /**
   * Filter charges by code
   */
  code?: string[];
  /**
   * Filter charges by code
   */
  invoice_code?: string[];
  /**
   * Filter charges by code
   */
  user_email?: string[];
  /**
   * Filter charges by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter charges by month. syntax is [start, end]
   */
  month?: string[];
  /**
   * Filter charges by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter charges by paid at. syntax is [start, end]
   */
  paid_at?: string[];
  /**
   * Filter charges by charge source ids
   */
  source_id?: string[];
  /**
   * Filter charges by source
   */
  source?: ("royalty" | "referral" | "bonus" | "other")[];
  /**
   * Filter charges by state
   */
  state?: ("unpaid" | "pending" | "paid")[];
  /**
   * Filter charges by content type
   */
  content_type?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  /**
   * Filter charges by region
   */
  region?: ("US" | "Intl")[];
  /**
   * Filter charges by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter manually created charges
   */
  manual?: boolean;
  /**
   * Filter charges for locked users
   */
  user_locked?: boolean;
  /**
   * Filter charges by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter charges by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter charges by amount paid
   */
  paid_cents?: string[];
  /**
   * Filter charges by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter charges by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter charges by tax thousands
   */
  tax_thousands?: string[];
  /**
   * Filter charges by tax country id
   */
  tax_country_id?: string[];
  /**
   * Filter charges that have been edited
   */
  edited?: boolean;
}

export type PostApiV1FinanceInvoicesInvoiceCreationCountsProps = Omit<MutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesInvoiceCreationCountsQueryParams, void, void>, "path" | "verb">;

/**
 * Start a bulk operation to calculate the number of invoices to be created and count of total charges
 */
export const PostApiV1FinanceInvoicesInvoiceCreationCounts = (props: PostApiV1FinanceInvoicesInvoiceCreationCountsProps) => (
  <Mutate<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesInvoiceCreationCountsQueryParams, void, void>
    verb="POST"
    path={`/api/v1/finance/invoices/invoice_creation_counts`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceInvoicesInvoiceCreationCountsProps = Omit<UseMutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesInvoiceCreationCountsQueryParams, void, void>, "path" | "verb">;

/**
 * Start a bulk operation to calculate the number of invoices to be created and count of total charges
 */
export const usePostApiV1FinanceInvoicesInvoiceCreationCounts = (props: UsePostApiV1FinanceInvoicesInvoiceCreationCountsProps) => useMutate<FinanceBulkOperation, unknown, PostApiV1FinanceInvoicesInvoiceCreationCountsQueryParams, void, void>("POST", `/api/v1/finance/invoices/invoice_creation_counts`, props);


export interface GetApiV1FinanceInvoicesTotalsQueryParams {
  /**
   * Filter invoices by user import guid
   */
  user_id?: string[];
  /**
   * Filter invoices by user email
   */
  user_email?: string[];
  /**
   * Filter invoices by invoice ids
   */
  id?: string[];
  /**
   * Filter invoices by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter invoices by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter invoices by state
   */
  state?: ("pending" | "in_progress" | "complete" | "approved" | "payment_processing" | "failed" | "canceled")[];
  /**
   * Fetch invoices that were started before this date [start, end]
   */
  started_at?: string[];
  /**
   * Fetch invoices that were completed after this date [start, end]
   */
  completed_at?: string[];
  /**
   * Filter invoices by payment method
   */
  payment_method?: string[];
  /**
   * Filter invoices by payment account id
   */
  payment_account_id?: string[];
  /**
   * Filter invoices by payoneer_payee_id
   */
  payoneer_payee_id?: string[];
  /**
   * Filter invoices by payment transaction id
   */
  transaction_id?: string[];
  /**
   * Filter invoices by code
   */
  code?: string[];
  /**
   * Filter invoices by amount paid
   */
  deposit_cents?: string[];
  /**
   * Filter invoices by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter invoices by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter invoices by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter invoices for locked users
   */
  user_locked?: boolean;
  /**
   * Filter invoices by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter invoices by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter invoices by payout batch id
   */
  payout_batch_id?: string[];
  /**
   * Filter invoices by number of failures
   */
  failure_count?: string[];
  /**
   * Filter invoices by approver import guid
   */
  approver_id?: string[];
  /**
   * Filter invoices by approver email
   */
  approver_email?: string[];
  /**
   * Filter invoices by approved at. syntax is [start, end]
   */
  approved_at?: string[];
  /**
   * Filter invoices by whether they have been voided
   */
  void?: string[];
}

export type GetApiV1FinanceInvoicesTotalsProps = Omit<GetProps<FinanceInvoiceStateTotals, unknown, GetApiV1FinanceInvoicesTotalsQueryParams, void>, "path">;

/**
 * get totals for invoice list
 */
export const GetApiV1FinanceInvoicesTotals = (props: GetApiV1FinanceInvoicesTotalsProps) => (
  <Get<FinanceInvoiceStateTotals, unknown, GetApiV1FinanceInvoicesTotalsQueryParams, void>
    path={`/api/v1/finance/invoices/totals`}
    
    {...props}
  />
);

export type UseGetApiV1FinanceInvoicesTotalsProps = Omit<UseGetProps<FinanceInvoiceStateTotals, unknown, GetApiV1FinanceInvoicesTotalsQueryParams, void>, "path">;

/**
 * get totals for invoice list
 */
export const useGetApiV1FinanceInvoicesTotals = (props: UseGetApiV1FinanceInvoicesTotalsProps) => useGet<FinanceInvoiceStateTotals, unknown, GetApiV1FinanceInvoicesTotalsQueryParams, void>(`/api/v1/finance/invoices/totals`, props);


export interface PostApiV1FinanceChargesZeroOutQueryParams {
  /**
   * Filter charges by charge ids
   */
  id?: string[];
  /**
   * Filter charges by source category
   */
  source_category?: string[];
  /**
   * Filter charges by user import guid
   */
  user_id?: string[];
  /**
   * Filter charges by code
   */
  code?: string[];
  /**
   * Filter charges by code
   */
  invoice_code?: string[];
  /**
   * Filter charges by code
   */
  user_email?: string[];
  /**
   * Filter charges by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter charges by month. syntax is [start, end]
   */
  month?: string[];
  /**
   * Filter charges by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter charges by paid at. syntax is [start, end]
   */
  paid_at?: string[];
  /**
   * Filter charges by charge source ids
   */
  source_id?: string[];
  /**
   * Filter charges by source
   */
  source?: ("royalty" | "referral" | "bonus" | "other")[];
  /**
   * Filter charges by state
   */
  state?: ("unpaid" | "pending" | "paid")[];
  /**
   * Filter charges by content type
   */
  content_type?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  /**
   * Filter charges by region
   */
  region?: ("US" | "Intl")[];
  /**
   * Filter charges by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter manually created charges
   */
  manual?: boolean;
  /**
   * Filter charges for locked users
   */
  user_locked?: boolean;
  /**
   * Filter charges by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter charges by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter charges by amount paid
   */
  paid_cents?: string[];
  /**
   * Filter charges by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter charges by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter charges by tax thousands
   */
  tax_thousands?: string[];
  /**
   * Filter charges by tax country id
   */
  tax_country_id?: string[];
  /**
   * Filter charges that have been edited
   */
  edited?: boolean;
}

export type PostApiV1FinanceChargesZeroOutProps = Omit<MutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceChargesZeroOutQueryParams, void, void>, "path" | "verb">;

/**
 * Start a bulk operation to zero out charges
 */
export const PostApiV1FinanceChargesZeroOut = (props: PostApiV1FinanceChargesZeroOutProps) => (
  <Mutate<FinanceBulkOperation, unknown, PostApiV1FinanceChargesZeroOutQueryParams, void, void>
    verb="POST"
    path={`/api/v1/finance/charges/zero_out`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceChargesZeroOutProps = Omit<UseMutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceChargesZeroOutQueryParams, void, void>, "path" | "verb">;

/**
 * Start a bulk operation to zero out charges
 */
export const usePostApiV1FinanceChargesZeroOut = (props: UsePostApiV1FinanceChargesZeroOutProps) => useMutate<FinanceBulkOperation, unknown, PostApiV1FinanceChargesZeroOutQueryParams, void, void>("POST", `/api/v1/finance/charges/zero_out`, props);


export interface PostApiV1FinanceChargesZeroOutCountsQueryParams {
  /**
   * Filter charges by charge ids
   */
  id?: string[];
  /**
   * Filter charges by source category
   */
  source_category?: string[];
  /**
   * Filter charges by user import guid
   */
  user_id?: string[];
  /**
   * Filter charges by code
   */
  code?: string[];
  /**
   * Filter charges by code
   */
  invoice_code?: string[];
  /**
   * Filter charges by code
   */
  user_email?: string[];
  /**
   * Filter charges by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter charges by month. syntax is [start, end]
   */
  month?: string[];
  /**
   * Filter charges by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter charges by paid at. syntax is [start, end]
   */
  paid_at?: string[];
  /**
   * Filter charges by charge source ids
   */
  source_id?: string[];
  /**
   * Filter charges by source
   */
  source?: ("royalty" | "referral" | "bonus" | "other")[];
  /**
   * Filter charges by state
   */
  state?: ("unpaid" | "pending" | "paid")[];
  /**
   * Filter charges by content type
   */
  content_type?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  /**
   * Filter charges by region
   */
  region?: ("US" | "Intl")[];
  /**
   * Filter charges by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter manually created charges
   */
  manual?: boolean;
  /**
   * Filter charges for locked users
   */
  user_locked?: boolean;
  /**
   * Filter charges by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter charges by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter charges by amount paid
   */
  paid_cents?: string[];
  /**
   * Filter charges by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter charges by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter charges by tax thousands
   */
  tax_thousands?: string[];
  /**
   * Filter charges by tax country id
   */
  tax_country_id?: string[];
  /**
   * Filter charges that have been edited
   */
  edited?: boolean;
}

export type PostApiV1FinanceChargesZeroOutCountsProps = Omit<MutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceChargesZeroOutCountsQueryParams, void, void>, "path" | "verb">;

/**
 * Start a bulk operation to get zero out counts
 */
export const PostApiV1FinanceChargesZeroOutCounts = (props: PostApiV1FinanceChargesZeroOutCountsProps) => (
  <Mutate<FinanceBulkOperation, unknown, PostApiV1FinanceChargesZeroOutCountsQueryParams, void, void>
    verb="POST"
    path={`/api/v1/finance/charges/zero_out_counts`}
    
    {...props}
  />
);

export type UsePostApiV1FinanceChargesZeroOutCountsProps = Omit<UseMutateProps<FinanceBulkOperation, unknown, PostApiV1FinanceChargesZeroOutCountsQueryParams, void, void>, "path" | "verb">;

/**
 * Start a bulk operation to get zero out counts
 */
export const usePostApiV1FinanceChargesZeroOutCounts = (props: UsePostApiV1FinanceChargesZeroOutCountsProps) => useMutate<FinanceBulkOperation, unknown, PostApiV1FinanceChargesZeroOutCountsQueryParams, void, void>("POST", `/api/v1/finance/charges/zero_out_counts`, props);


export interface DeleteApiV1FinanceChargesIdDeletePathParams {
  id: string
}

export type DeleteApiV1FinanceChargesIdDeleteProps = Omit<MutateProps<void, unknown, void, void, DeleteApiV1FinanceChargesIdDeletePathParams>, "path" | "verb"> & DeleteApiV1FinanceChargesIdDeletePathParams;

/**
 * Delete individual charges
 */
export const DeleteApiV1FinanceChargesIdDelete = ({id, ...props}: DeleteApiV1FinanceChargesIdDeleteProps) => (
  <Mutate<void, unknown, void, void, DeleteApiV1FinanceChargesIdDeletePathParams>
    verb="DELETE"
    path={`/api/v1/finance/charges/${id}/delete`}
    
    {...props}
  />
);

export type UseDeleteApiV1FinanceChargesIdDeleteProps = Omit<UseMutateProps<void, unknown, void, void, DeleteApiV1FinanceChargesIdDeletePathParams>, "path" | "verb"> & DeleteApiV1FinanceChargesIdDeletePathParams;

/**
 * Delete individual charges
 */
export const useDeleteApiV1FinanceChargesIdDelete = ({id, ...props}: UseDeleteApiV1FinanceChargesIdDeleteProps) => useMutate<void, unknown, void, void, DeleteApiV1FinanceChargesIdDeletePathParams>("DELETE", (paramsInPath: DeleteApiV1FinanceChargesIdDeletePathParams) => `/api/v1/finance/charges/${paramsInPath.id}/delete`, {  pathParams: { id }, ...props });


export interface PatchApiV1FinanceChargesIdPathParams {
  id: string
}

export type PatchApiV1FinanceChargesIdProps = Omit<MutateProps<FinanceCharge, unknown, void, PatchApiV1FinanceChargesId, PatchApiV1FinanceChargesIdPathParams>, "path" | "verb"> & PatchApiV1FinanceChargesIdPathParams;

/**
 * update a charge
 */
export const PatchApiV1FinanceChargesId = ({id, ...props}: PatchApiV1FinanceChargesIdProps) => (
  <Mutate<FinanceCharge, unknown, void, PatchApiV1FinanceChargesId, PatchApiV1FinanceChargesIdPathParams>
    verb="PATCH"
    path={`/api/v1/finance/charges/${id}`}
    
    {...props}
  />
);

export type UsePatchApiV1FinanceChargesIdProps = Omit<UseMutateProps<FinanceCharge, unknown, void, PatchApiV1FinanceChargesId, PatchApiV1FinanceChargesIdPathParams>, "path" | "verb"> & PatchApiV1FinanceChargesIdPathParams;

/**
 * update a charge
 */
export const usePatchApiV1FinanceChargesId = ({id, ...props}: UsePatchApiV1FinanceChargesIdProps) => useMutate<FinanceCharge, unknown, void, PatchApiV1FinanceChargesId, PatchApiV1FinanceChargesIdPathParams>("PATCH", (paramsInPath: PatchApiV1FinanceChargesIdPathParams) => `/api/v1/finance/charges/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1FinanceChargesTotalsQueryParams {
  /**
   * Filter charges by charge ids
   */
  id?: string[];
  /**
   * Filter charges by source category
   */
  source_category?: string[];
  /**
   * Filter charges by user import guid
   */
  user_id?: string[];
  /**
   * Filter charges by code
   */
  code?: string[];
  /**
   * Filter charges by code
   */
  invoice_code?: string[];
  /**
   * Filter charges by code
   */
  user_email?: string[];
  /**
   * Filter charges by created at. syntax is [start, end]
   */
  created_at?: string[];
  /**
   * Filter charges by month. syntax is [start, end]
   */
  month?: string[];
  /**
   * Filter charges by updated at. syntax is [start, end]
   */
  updated_at?: string[];
  /**
   * Filter charges by paid at. syntax is [start, end]
   */
  paid_at?: string[];
  /**
   * Filter charges by charge source ids
   */
  source_id?: string[];
  /**
   * Filter charges by source
   */
  source?: ("royalty" | "referral" | "bonus" | "other")[];
  /**
   * Filter charges by state
   */
  state?: ("unpaid" | "pending" | "paid")[];
  /**
   * Filter charges by content type
   */
  content_type?: ("vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic")[];
  /**
   * Filter charges by region
   */
  region?: ("US" | "Intl")[];
  /**
   * Filter charges by whether they have notes
   */
  notes?: boolean;
  /**
   * Filter manually created charges
   */
  manual?: boolean;
  /**
   * Filter charges for locked users
   */
  user_locked?: boolean;
  /**
   * Filter charges by user state
   */
  user_state?: ("approved" | "locked" | "closed" | "rejected" | "unapproved")[];
  /**
   * Filter charges by user locked reason
   */
  user_locked_reason?: ("content_authenticity_request" | "banned_bad_actor" | "terminated_employee" | "duplicate_account" | "other")[];
  /**
   * Filter charges by amount paid
   */
  paid_cents?: string[];
  /**
   * Filter charges by amount earned
   */
  earning_cents?: string[];
  /**
   * Filter charges by amount withheld
   */
  tax_withheld_cents?: string[];
  /**
   * Filter charges by tax thousands
   */
  tax_thousands?: string[];
  /**
   * Filter charges by tax country id
   */
  tax_country_id?: string[];
  /**
   * Filter charges that have been edited
   */
  edited?: boolean;
}

export type GetApiV1FinanceChargesTotalsProps = Omit<GetProps<FinanceChargeStateTotals, unknown, GetApiV1FinanceChargesTotalsQueryParams, void>, "path">;

/**
 * get totals for charge list
 */
export const GetApiV1FinanceChargesTotals = (props: GetApiV1FinanceChargesTotalsProps) => (
  <Get<FinanceChargeStateTotals, unknown, GetApiV1FinanceChargesTotalsQueryParams, void>
    path={`/api/v1/finance/charges/totals`}
    
    {...props}
  />
);

export type UseGetApiV1FinanceChargesTotalsProps = Omit<UseGetProps<FinanceChargeStateTotals, unknown, GetApiV1FinanceChargesTotalsQueryParams, void>, "path">;

/**
 * get totals for charge list
 */
export const useGetApiV1FinanceChargesTotals = (props: UseGetApiV1FinanceChargesTotalsProps) => useGet<FinanceChargeStateTotals, unknown, GetApiV1FinanceChargesTotalsQueryParams, void>(`/api/v1/finance/charges/totals`, props);


export interface GetApiV1FinanceBulkOperationsIdPathParams {
  id: string
}

export type GetApiV1FinanceBulkOperationsIdProps = Omit<GetProps<FinanceBulkOperation, unknown, void, GetApiV1FinanceBulkOperationsIdPathParams>, "path"> & GetApiV1FinanceBulkOperationsIdPathParams;

/**
 * get a bulk operation instance
 */
export const GetApiV1FinanceBulkOperationsId = ({id, ...props}: GetApiV1FinanceBulkOperationsIdProps) => (
  <Get<FinanceBulkOperation, unknown, void, GetApiV1FinanceBulkOperationsIdPathParams>
    path={`/api/v1/finance/bulk_operations/${id}`}
    
    {...props}
  />
);

export type UseGetApiV1FinanceBulkOperationsIdProps = Omit<UseGetProps<FinanceBulkOperation, unknown, void, GetApiV1FinanceBulkOperationsIdPathParams>, "path"> & GetApiV1FinanceBulkOperationsIdPathParams;

/**
 * get a bulk operation instance
 */
export const useGetApiV1FinanceBulkOperationsId = ({id, ...props}: UseGetApiV1FinanceBulkOperationsIdProps) => useGet<FinanceBulkOperation, unknown, void, GetApiV1FinanceBulkOperationsIdPathParams>((paramsInPath: GetApiV1FinanceBulkOperationsIdPathParams) => `/api/v1/finance/bulk_operations/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface GetApiV1FinanceAccountSummariesIdEarningsQueryParams {
  period?: "ytd";
  /**
   * Filter earnings summaries by a date range. Syntax is [start, end]
   */
  date_range?: string[];
  format?: "json" | "csv";
}

export interface GetApiV1FinanceAccountSummariesIdEarningsPathParams {
  id: string
}

export type GetApiV1FinanceAccountSummariesIdEarningsProps = Omit<GetProps<FinanceAccountEarningsSummary, unknown, GetApiV1FinanceAccountSummariesIdEarningsQueryParams, GetApiV1FinanceAccountSummariesIdEarningsPathParams>, "path"> & GetApiV1FinanceAccountSummariesIdEarningsPathParams;

/**
 * Account earnings summary
 */
export const GetApiV1FinanceAccountSummariesIdEarnings = ({id, ...props}: GetApiV1FinanceAccountSummariesIdEarningsProps) => (
  <Get<FinanceAccountEarningsSummary, unknown, GetApiV1FinanceAccountSummariesIdEarningsQueryParams, GetApiV1FinanceAccountSummariesIdEarningsPathParams>
    path={`/api/v1/finance/account_summaries/${id}/earnings`}
    
    {...props}
  />
);

export type UseGetApiV1FinanceAccountSummariesIdEarningsProps = Omit<UseGetProps<FinanceAccountEarningsSummary, unknown, GetApiV1FinanceAccountSummariesIdEarningsQueryParams, GetApiV1FinanceAccountSummariesIdEarningsPathParams>, "path"> & GetApiV1FinanceAccountSummariesIdEarningsPathParams;

/**
 * Account earnings summary
 */
export const useGetApiV1FinanceAccountSummariesIdEarnings = ({id, ...props}: UseGetApiV1FinanceAccountSummariesIdEarningsProps) => useGet<FinanceAccountEarningsSummary, unknown, GetApiV1FinanceAccountSummariesIdEarningsQueryParams, GetApiV1FinanceAccountSummariesIdEarningsPathParams>((paramsInPath: GetApiV1FinanceAccountSummariesIdEarningsPathParams) => `/api/v1/finance/account_summaries/${paramsInPath.id}/earnings`, {  pathParams: { id }, ...props });


export type PostApiV1TestFeatureFlagsToggleFeatureFlagProps = Omit<MutateProps<TestResponse, unknown, void, void, void>, "path" | "verb">;

/**
 * Toggle flipper feature flag
 */
export const PostApiV1TestFeatureFlagsToggleFeatureFlag = (props: PostApiV1TestFeatureFlagsToggleFeatureFlagProps) => (
  <Mutate<TestResponse, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/test/feature_flags/toggle_feature_flag`}
    
    {...props}
  />
);

export type UsePostApiV1TestFeatureFlagsToggleFeatureFlagProps = Omit<UseMutateProps<TestResponse, unknown, void, void, void>, "path" | "verb">;

/**
 * Toggle flipper feature flag
 */
export const usePostApiV1TestFeatureFlagsToggleFeatureFlag = (props: UsePostApiV1TestFeatureFlagsToggleFeatureFlagProps) => useMutate<TestResponse, unknown, void, void, void>("POST", `/api/v1/test/feature_flags/toggle_feature_flag`, props);


export interface PostApiV1TestUsersCreateResourcesQueryParams {
  /**
   * State resource will be created in
   */
  state: "started" | "submitting" | "submitted" | "needs_corrections" | "flagged" | "rejected" | "approving" | "approved" | "active" | "inactive";
  /**
   * Content type for created resources
   */
  content_type: "vector" | "photo" | "video" | "video_template" | "brush" | "background" | "pattern" | "psd" | "texture" | "icons" | "bundle" | "png" | "motion_graphic";
  /**
   * License for created resource
   */
  license?: "free" | "pro" | "editorial";
}

export type PostApiV1TestUsersCreateResourcesProps = Omit<MutateProps<TestResponse, unknown, PostApiV1TestUsersCreateResourcesQueryParams, void, void>, "path" | "verb">;

/**
 * Create some resources for a user
 */
export const PostApiV1TestUsersCreateResources = (props: PostApiV1TestUsersCreateResourcesProps) => (
  <Mutate<TestResponse, unknown, PostApiV1TestUsersCreateResourcesQueryParams, void, void>
    verb="POST"
    path={`/api/v1/test/users/create_resources`}
    
    {...props}
  />
);

export type UsePostApiV1TestUsersCreateResourcesProps = Omit<UseMutateProps<TestResponse, unknown, PostApiV1TestUsersCreateResourcesQueryParams, void, void>, "path" | "verb">;

/**
 * Create some resources for a user
 */
export const usePostApiV1TestUsersCreateResources = (props: UsePostApiV1TestUsersCreateResourcesProps) => useMutate<TestResponse, unknown, PostApiV1TestUsersCreateResourcesQueryParams, void, void>("POST", `/api/v1/test/users/create_resources`, props);


export type PostApiV1TestUsersReviewerPermissionsProps = Omit<MutateProps<TestResponse, unknown, void, void, void>, "path" | "verb">;

/**
 * Update reviewer permissions
 */
export const PostApiV1TestUsersReviewerPermissions = (props: PostApiV1TestUsersReviewerPermissionsProps) => (
  <Mutate<TestResponse, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/test/users/reviewer_permissions`}
    
    {...props}
  />
);

export type UsePostApiV1TestUsersReviewerPermissionsProps = Omit<UseMutateProps<TestResponse, unknown, void, void, void>, "path" | "verb">;

/**
 * Update reviewer permissions
 */
export const usePostApiV1TestUsersReviewerPermissions = (props: UsePostApiV1TestUsersReviewerPermissionsProps) => useMutate<TestResponse, unknown, void, void, void>("POST", `/api/v1/test/users/reviewer_permissions`, props);


export type PostApiV1TestUsersEnsureUserProps = Omit<MutateProps<TestSessionResponse, unknown, void, void, void>, "path" | "verb">;

/**
 * Ensure a single user exists
 */
export const PostApiV1TestUsersEnsureUser = (props: PostApiV1TestUsersEnsureUserProps) => (
  <Mutate<TestSessionResponse, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/test/users/ensure_user`}
    
    {...props}
  />
);

export type UsePostApiV1TestUsersEnsureUserProps = Omit<UseMutateProps<TestSessionResponse, unknown, void, void, void>, "path" | "verb">;

/**
 * Ensure a single user exists
 */
export const usePostApiV1TestUsersEnsureUser = (props: UsePostApiV1TestUsersEnsureUserProps) => useMutate<TestSessionResponse, unknown, void, void, void>("POST", `/api/v1/test/users/ensure_user`, props);


export type PostApiV1TestUsersReindexResourcesProps = Omit<MutateProps<TestResponse, unknown, void, PostApiV1TestUsersReindexResourcesRequestBody, void>, "path" | "verb">;

/**
 * Reindex a user's resources
 */
export const PostApiV1TestUsersReindexResources = (props: PostApiV1TestUsersReindexResourcesProps) => (
  <Mutate<TestResponse, unknown, void, PostApiV1TestUsersReindexResourcesRequestBody, void>
    verb="POST"
    path={`/api/v1/test/users/reindex_resources`}
    
    {...props}
  />
);

export type UsePostApiV1TestUsersReindexResourcesProps = Omit<UseMutateProps<TestResponse, unknown, void, PostApiV1TestUsersReindexResourcesRequestBody, void>, "path" | "verb">;

/**
 * Reindex a user's resources
 */
export const usePostApiV1TestUsersReindexResources = (props: UsePostApiV1TestUsersReindexResourcesProps) => useMutate<TestResponse, unknown, void, PostApiV1TestUsersReindexResourcesRequestBody, void>("POST", `/api/v1/test/users/reindex_resources`, props);


export type PostApiV1TestUsersResetResourcesProps = Omit<MutateProps<TestResponse, unknown, void, PostApiV1TestUsersReindexResourcesRequestBody, void>, "path" | "verb">;

/**
 * Deletes all of a users' resources
 */
export const PostApiV1TestUsersResetResources = (props: PostApiV1TestUsersResetResourcesProps) => (
  <Mutate<TestResponse, unknown, void, PostApiV1TestUsersReindexResourcesRequestBody, void>
    verb="POST"
    path={`/api/v1/test/users/reset_resources`}
    
    {...props}
  />
);

export type UsePostApiV1TestUsersResetResourcesProps = Omit<UseMutateProps<TestResponse, unknown, void, PostApiV1TestUsersReindexResourcesRequestBody, void>, "path" | "verb">;

/**
 * Deletes all of a users' resources
 */
export const usePostApiV1TestUsersResetResources = (props: UsePostApiV1TestUsersResetResourcesProps) => useMutate<TestResponse, unknown, void, PostApiV1TestUsersReindexResourcesRequestBody, void>("POST", `/api/v1/test/users/reset_resources`, props);


export type PostApiV1TestUsersResetUsersProps = Omit<MutateProps<TestResponse, unknown, void, void, void>, "path" | "verb">;

/**
 * Wipes all users
 */
export const PostApiV1TestUsersResetUsers = (props: PostApiV1TestUsersResetUsersProps) => (
  <Mutate<TestResponse, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/test/users/reset_users`}
    
    {...props}
  />
);

export type UsePostApiV1TestUsersResetUsersProps = Omit<UseMutateProps<TestResponse, unknown, void, void, void>, "path" | "verb">;

/**
 * Wipes all users
 */
export const usePostApiV1TestUsersResetUsers = (props: UsePostApiV1TestUsersResetUsersProps) => useMutate<TestResponse, unknown, void, void, void>("POST", `/api/v1/test/users/reset_users`, props);


export type PostApiV1TestUsersEnsureUsersProps = Omit<MutateProps<TestResponse, unknown, void, void, void>, "path" | "verb">;

/**
 * Creates users if needed
 */
export const PostApiV1TestUsersEnsureUsers = (props: PostApiV1TestUsersEnsureUsersProps) => (
  <Mutate<TestResponse, unknown, void, void, void>
    verb="POST"
    path={`/api/v1/test/users/ensure_users`}
    
    {...props}
  />
);

export type UsePostApiV1TestUsersEnsureUsersProps = Omit<UseMutateProps<TestResponse, unknown, void, void, void>, "path" | "verb">;

/**
 * Creates users if needed
 */
export const usePostApiV1TestUsersEnsureUsers = (props: UsePostApiV1TestUsersEnsureUsersProps) => useMutate<TestResponse, unknown, void, void, void>("POST", `/api/v1/test/users/ensure_users`, props);


export type GetApiV1AuthExampleProps = Omit<GetProps<void, unknown, void, void>, "path">;

/**
 * An example endpoint with the API key headers
 */
export const GetApiV1AuthExample = (props: GetApiV1AuthExampleProps) => (
  <Get<void, unknown, void, void>
    path={`/api/v1/auth_example`}
    
    {...props}
  />
);

export type UseGetApiV1AuthExampleProps = Omit<UseGetProps<void, unknown, void, void>, "path">;

/**
 * An example endpoint with the API key headers
 */
export const useGetApiV1AuthExample = (props: UseGetApiV1AuthExampleProps) => useGet<void, unknown, void, void>(`/api/v1/auth_example`, props);


export type GetApiV1CategoriesProps = Omit<GetProps<Category[], unknown, void, void>, "path">;

/**
 * Get the list of all categories
 */
export const GetApiV1Categories = (props: GetApiV1CategoriesProps) => (
  <Get<Category[], unknown, void, void>
    path={`/api/v1/categories`}
    
    {...props}
  />
);

export type UseGetApiV1CategoriesProps = Omit<UseGetProps<Category[], unknown, void, void>, "path">;

/**
 * Get the list of all categories
 */
export const useGetApiV1Categories = (props: UseGetApiV1CategoriesProps) => useGet<Category[], unknown, void, void>(`/api/v1/categories`, props);


export interface GetApiV1ChallengesQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export type GetApiV1ChallengesProps = Omit<GetProps<Challenge[], unknown, GetApiV1ChallengesQueryParams, void>, "path">;

/**
 * Get list of all challenges
 */
export const GetApiV1Challenges = (props: GetApiV1ChallengesProps) => (
  <Get<Challenge[], unknown, GetApiV1ChallengesQueryParams, void>
    path={`/api/v1/challenges`}
    
    {...props}
  />
);

export type UseGetApiV1ChallengesProps = Omit<UseGetProps<Challenge[], unknown, GetApiV1ChallengesQueryParams, void>, "path">;

/**
 * Get list of all challenges
 */
export const useGetApiV1Challenges = (props: UseGetApiV1ChallengesProps) => useGet<Challenge[], unknown, GetApiV1ChallengesQueryParams, void>(`/api/v1/challenges`, props);


export type GetApiV1ContributorNotificationsProps = Omit<GetProps<ContributorNotification[], unknown, void, void>, "path">;

/**
 * Notifications active for this user
 */
export const GetApiV1ContributorNotifications = (props: GetApiV1ContributorNotificationsProps) => (
  <Get<ContributorNotification[], unknown, void, void>
    path={`/api/v1/contributor_notifications`}
    
    {...props}
  />
);

export type UseGetApiV1ContributorNotificationsProps = Omit<UseGetProps<ContributorNotification[], unknown, void, void>, "path">;

/**
 * Notifications active for this user
 */
export const useGetApiV1ContributorNotifications = (props: UseGetApiV1ContributorNotificationsProps) => useGet<ContributorNotification[], unknown, void, void>(`/api/v1/contributor_notifications`, props);


export interface GetApiV1ContributorStatsQueryParams {
  content_type: string;
  period: string;
  program_type?: string;
  license_type?: string;
}

export type GetApiV1ContributorStatsProps = Omit<GetProps<ContributorStats, unknown, GetApiV1ContributorStatsQueryParams, void>, "path">;

/**
 * shows count of approved resources, total downloads, total views for the current user over a recent period
 */
export const GetApiV1ContributorStats = (props: GetApiV1ContributorStatsProps) => (
  <Get<ContributorStats, unknown, GetApiV1ContributorStatsQueryParams, void>
    path={`/api/v1/contributor_stats`}
    
    {...props}
  />
);

export type UseGetApiV1ContributorStatsProps = Omit<UseGetProps<ContributorStats, unknown, GetApiV1ContributorStatsQueryParams, void>, "path">;

/**
 * shows count of approved resources, total downloads, total views for the current user over a recent period
 */
export const useGetApiV1ContributorStats = (props: UseGetApiV1ContributorStatsProps) => useGet<ContributorStats, unknown, GetApiV1ContributorStatsQueryParams, void>(`/api/v1/contributor_stats`, props);


export interface GetApiV1ProhibitedTermsQueryParams {
  /**
   * Page of results to fetch.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  per_page?: number;
}

export type GetApiV1ProhibitedTermsProps = Omit<GetProps<ProhibitedTerm[], unknown, GetApiV1ProhibitedTermsQueryParams, void>, "path">;

/**
 * Get list of all prohibited terms
 */
export const GetApiV1ProhibitedTerms = (props: GetApiV1ProhibitedTermsProps) => (
  <Get<ProhibitedTerm[], unknown, GetApiV1ProhibitedTermsQueryParams, void>
    path={`/api/v1/prohibited_terms`}
    
    {...props}
  />
);

export type UseGetApiV1ProhibitedTermsProps = Omit<UseGetProps<ProhibitedTerm[], unknown, GetApiV1ProhibitedTermsQueryParams, void>, "path">;

/**
 * Get list of all prohibited terms
 */
export const useGetApiV1ProhibitedTerms = (props: UseGetApiV1ProhibitedTermsProps) => useGet<ProhibitedTerm[], unknown, GetApiV1ProhibitedTermsQueryParams, void>(`/api/v1/prohibited_terms`, props);


export type PostApiV1ReplaceAllProps = Omit<MutateProps<BulkOperation, unknown, void, PostApiV1ReplaceAll, void>, "path" | "verb">;

/**
 * Bulk search and replace
 */
export const PostApiV1ReplaceAll = (props: PostApiV1ReplaceAllProps) => (
  <Mutate<BulkOperation, unknown, void, PostApiV1ReplaceAll, void>
    verb="POST"
    path={`/api/v1/replace_all`}
    
    {...props}
  />
);

export type UsePostApiV1ReplaceAllProps = Omit<UseMutateProps<BulkOperation, unknown, void, PostApiV1ReplaceAll, void>, "path" | "verb">;

/**
 * Bulk search and replace
 */
export const usePostApiV1ReplaceAll = (props: UsePostApiV1ReplaceAllProps) => useMutate<BulkOperation, unknown, void, PostApiV1ReplaceAll, void>("POST", `/api/v1/replace_all`, props);


export type GetApiV1SpecialtiesProps = Omit<GetProps<Specialty[], unknown, void, void>, "path">;

/**
 * Get a list of all recognized specialties
 */
export const GetApiV1Specialties = (props: GetApiV1SpecialtiesProps) => (
  <Get<Specialty[], unknown, void, void>
    path={`/api/v1/specialties`}
    
    {...props}
  />
);

export type UseGetApiV1SpecialtiesProps = Omit<UseGetProps<Specialty[], unknown, void, void>, "path">;

/**
 * Get a list of all recognized specialties
 */
export const useGetApiV1Specialties = (props: UseGetApiV1SpecialtiesProps) => useGet<Specialty[], unknown, void, void>(`/api/v1/specialties`, props);


export type PutApiV1TaxQuestionnaireProps = Omit<MutateProps<TaxQuestionnaire, APIError, void, PutApiV1TaxQuestionnaire, void>, "path" | "verb">;

/**
 * Upserts a tax questionnaire for the authenticated user
 */
export const PutApiV1TaxQuestionnaire = (props: PutApiV1TaxQuestionnaireProps) => (
  <Mutate<TaxQuestionnaire, APIError, void, PutApiV1TaxQuestionnaire, void>
    verb="PUT"
    path={`/api/v1/tax_questionnaire`}
    
    {...props}
  />
);

export type UsePutApiV1TaxQuestionnaireProps = Omit<UseMutateProps<TaxQuestionnaire, APIError, void, PutApiV1TaxQuestionnaire, void>, "path" | "verb">;

/**
 * Upserts a tax questionnaire for the authenticated user
 */
export const usePutApiV1TaxQuestionnaire = (props: UsePutApiV1TaxQuestionnaireProps) => useMutate<TaxQuestionnaire, APIError, void, PutApiV1TaxQuestionnaire, void>("PUT", `/api/v1/tax_questionnaire`, props);


export type GetApiV1TaxQuestionnaireProps = Omit<GetProps<TaxQuestionnaire, unknown, void, void>, "path">;

/**
 * Returns the tax questionnaire for the authenticated user
 */
export const GetApiV1TaxQuestionnaire = (props: GetApiV1TaxQuestionnaireProps) => (
  <Get<TaxQuestionnaire, unknown, void, void>
    path={`/api/v1/tax_questionnaire`}
    
    {...props}
  />
);

export type UseGetApiV1TaxQuestionnaireProps = Omit<UseGetProps<TaxQuestionnaire, unknown, void, void>, "path">;

/**
 * Returns the tax questionnaire for the authenticated user
 */
export const useGetApiV1TaxQuestionnaire = (props: UseGetApiV1TaxQuestionnaireProps) => useGet<TaxQuestionnaire, unknown, void, void>(`/api/v1/tax_questionnaire`, props);


export type GetApiV1ReviewerUsersProps = Omit<GetProps<User[], unknown, void, void>, "path">;

/**
 * show basic info for a list of users, meant to be used for reviewers
 */
export const GetApiV1ReviewerUsers = (props: GetApiV1ReviewerUsersProps) => (
  <Get<User[], unknown, void, void>
    path={`/api/v1/reviewer_users`}
    
    {...props}
  />
);

export type UseGetApiV1ReviewerUsersProps = Omit<UseGetProps<User[], unknown, void, void>, "path">;

/**
 * show basic info for a list of users, meant to be used for reviewers
 */
export const useGetApiV1ReviewerUsers = (props: UseGetApiV1ReviewerUsersProps) => useGet<User[], unknown, void, void>(`/api/v1/reviewer_users`, props);

